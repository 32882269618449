//@ts-nocheck
import React, { useCallback } from "react";
import {OutlineButton} from "./Button.web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export type openDialogProps = {
  title : string, message : string, actionCallback? : Function
}
export type alertDialogProps = {
  title : string, message : string, open? : boolean,onConfirm:Function
}

export const AlertDialog = (props : alertDialogProps) => {
  const {open,onConfirm,title,message} = props;
  return <Dialog
        open={open}
        onClose={onConfirm}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText  id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlineButton onClick={onConfirm}  variant="outlined" autoFocus>
            Ok
          </OutlineButton>
        </DialogActions>
      </Dialog>
}

export const AlertDialogContext = React.createContext({});

export const AlertDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen]   = React.useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = React.useState<any>({});

  const openDialog = ({ title, message, actionCallback }:openDialogProps) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = React.useCallback(() => {
    setDialogOpen(false);
    setDialogConfig({});
  },[]);
  const onConfirm = React.useCallback(() => {
    resetDialog();
    dialogConfig.actionCallback(true);
  },[resetDialog,dialogConfig]);
  // const onDismiss = () => {
  //   resetDialog();
  //   dialogConfig.actionCallback(false);
  // };
  return (
    <AlertDialogContext.Provider value={ openDialog }>
      <AlertDialog
        open={dialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        onConfirm={onConfirm}
      />
      {children}
    </AlertDialogContext.Provider>
  );
};



