//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  IconButton,
  Button,
  TextField,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  withStyles,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import UserProfileBasicController from "./UserProfileBasicControllerWeb";
import { arrowPointingToRight, imgCheckCircle } from "./assets";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import StorageProvider from "../../../framework/src/StorageProvider";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import moment from "moment";
import {StyledButton as OutlineButton} from "./Inforrmation.web";
import { imgEducation, imgArrow, imgClose } from "./assets";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// import Button from "../../../components/src/CustomButton.web";

const certificationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("This field is required"),
  organization_name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("This field is required"),
  date: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  documentName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("This field is required"),
});

class SkillsAndCertification extends UserProfileBasicController {
  constructor(props: any) {
    super(props);
  }
  certificate = null;
  async componentDidMount() {
    const authToken = await StorageProvider.get("auth_token");
    let accountDetail = await StorageProvider.get("userId");
    if (authToken && accountDetail) {
      accountDetail = JSON.parse(accountDetail);
      this.setState(
        {
          authToken: authToken,
          accountDetail: accountDetail,
        },
        () => {
          this.getSkillsAndCertificate();
          this.getSkills();
        }
      );
    }
  }
  handleDelete = (data, event) => {
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        if(data.id){
          this.deleteSkills(data); 
        } else {
        const a =   this.state.SelectedCertificateList.filter((certificate)=>{return certificate.name !== data.name})
          this.setState({
            SelectedCertificateList : a,
            moreMenu: null,
          },()=>{
            console.log(this.state.SelectedCertificateList);
          })
        }
        
      }
    };
    this.props.openDialog(
      "Delete",
      "Are you sure, you want to delete?",
      onClose
    );
  };
  moreMenuhandleClick = (event: any) => {
    this.setState({
      ...this.state,
      moreMenu: event.currentTarget,
    });
  };
  moreMenuhandleClose = (event: any) => {
    this.setState({
      ...this.state,
      moreMenu: null,
    });
  };
  moreMenuhandleEdit = (event: any, value: any) => {
      this.certificate = value  
      this.setState({
        ...this.state,
        updateModal: true,
        addSkillModalopen: true,
        moreMenu: null,
      });
  };
  handleAddSkillModal = () => {
    this.setState({
      updateModal: false,
      addSkillModalopen: true,
    });
  };
  handleAddSkill = (values) => {
    const { name, date, organization_name, documentName } = values;
    const {document} = this.state;
    const SelectedCertificateList = [...this.state.SelectedCertificateList];
    SelectedCertificateList.push({
      name,
      organization_name,
      date,
      documentName,
      document
    });
    this.setState((prevState) => ({
      ...this.state,
      SelectedCertificateList,
      updateModal: false,
      addSkillModalopen: !prevState.addSkillModalopen,
    }));
  };

  handelCancelSkillModal = () => {
      this.certificate = null;
    this.setState({ addSkillModalopen: false });
  };
  handleCloseSkillModal = () => {
    this.certificate = null;
    this.setState({ addSkillModalopen: false });
  };
  addToSelectedSkills = (value: string) => {
    if(value && value.trim().length > 0){
        const selectedSkills = [...this.state.selectedSkills];
            selectedSkills.push(value);
            this.setState(
            {
                ...this.state,
                selectedSkills,
            },
            () => {
                this.setState({ skillsData: "" });
            }
            );
    }  
  };
  handleDocument = (e: any) => {
    const file = e.target.files[0];
    this.imgBase64(file, (result: any) => {
      this.setState({
        ...this.state,
        document: result,
      });
    });
  };
  imgBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
  };
  updateCertificate = (values,certificate) => {
    if(certificate.id){
      this.updateSkills(values,certificate);
    } else {
      const der = [...this.state.SelectedCertificateList];
      const index =  der.findIndex((de)=>{return de.name === certificate.name})
      der[index] = values;
      this.setState({
        SelectedCertificateList : der
      })
      this.certificate = null;
      this.setState({ addSkillModalopen: false });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      updateModal,
    } = this.state;
    return (
      <>
        <div className="add-profile-tab-content">
          <div className="tab-title">
            <Typography className={classes.tabHeading}>Skills &amp; Certifications</Typography>
            <Typography className={classes.tabSubHeading}>Professional Skills</Typography>
          </div>
          <div className="tab-main-content">
            <TextField
              variant="outlined"
              fullWidth
              id="input-with-icon-textfield"
              value={this.state.skillsData}
              onChange={(e) => {
                this.setState({ ...this.state, skillsData: e.target.value });
              }}
              label="Enter your skills"
              error={this.state.selectedSkills.length! > 50}
              helperText={
                this.state.selectedSkills.length > 50
                  ? "skills should not be more than 50"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e: any) => {
                        this.addToSelectedSkills(this.state.skillsData);
                      }}
                      edge="end"
                    >
                      <img src={imgCheckCircle} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              labelWidth={70}
            />
            {/* </FormControl> */}
            <div className={classes.helpertextheading}>Add upto 50 skills</div>
            {
            this.state.selectedSkills.length > 0 && <ul className={`${classes.chip} chips-group`}>
             {
                this.state.selectedSkills.map((data, index) => {
                  return (
                    <li key={index}>
                      <Chip
                        label={data}
                        onDelete={() => {
                          this.setState({
                            selectedSkills: this.state.selectedSkills.filter(
                              (entry) => entry !== data
                            ),
                            moreMenu: null,  
                          });
                        }}
                        className="primary-chips removable"
                      />
                    </li>
                  );
                })}
            </ul>
           }
            {this.state.SelectedCertificateList &&
            this.state.SelectedCertificateList.length > 0 ? (
              <>
                {this.state.SelectedCertificateList.map((data, index) => {
                  return (
                    <>
                    {index == 0 && <div className={classes.certificateHeading}>Certification</div>}
                    <ul className="certification-wrapper">
                      <li key={index}>
                        <div className="certification-card">
                          <p className="certification-card-header">
                            <span className={classes.dateText}>
                              {data.date &&
                                moment(data.date).format("DD MMM YYYY")}
                            </span>
                           <IconButton
                              aria-controls="more-menu"
                              aria-haspopup="true"
                              onClick={this.moreMenuhandleClick}
                              aria-label="more"
                              className="btn-iconic"
                              size="small"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                              <Menu
                                id="more-menu"
                                anchorEl={this.state.moreMenu}
                                keepMounted
                                open={Boolean(this.state.moreMenu)}
                                onClose={this.moreMenuhandleClose}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    this.moreMenuhandleEdit(e, data);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    this.handleDelete(data);
                                  }}
                                >
                                  <span style={{ color: "#da2b2b" }}>
                                    {" "}
                                    Delete
                                  </span>
                                </MenuItem>
                              </Menu>
                          </p>
                          <div className={classes.certificateText}>
                            {data.name}
                          </div>
                          <span className={classes.organization_name}>
                            {data.organization_name}
                          </span>
                        </div>
                      </li>
                    </ul>
                    </>
                  );
                })}
                <div style={{width : '113px'}}>
                  <OutlineButton style={{width : '100%'}}  onClick={this.handleAddSkillModal} ><span style={{textTransform : 'none'}}>+ Add more</span></OutlineButton> 
              </div> 
              </>
            ) : <Grid container>
            <Grid item xs={12} container justify="flex-start" style={{marginTop : '12px'}}>
                <Grid item xs={12}>
                <div style={{widht : '114px'}}>
                  <OutlineButton style={{width : '114px'}} onClick={this.handleAddSkillModal}>
                      <span style={{textTransform : 'none'}}>+ Add More</span>
                  </OutlineButton>
                </div>
                </Grid>
            </Grid>
        </Grid>
           }
            <Button
              className="btn btn-iconic-text d-block btn-lg"
              style={{marginTop : '16px'}}
              variant="contained"
              color="primary"
              onClick={()=>{
                this.saveSkills();
                this.state.skill_sets?.id ? this.updateSkill() : this.saveSkill(); 
              }}
            >
              <span>Next</span>
              <img className="ms-xs-2 ms-1" src={arrowPointingToRight} alt="" />
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.addSkillModalopen}
          onClose={this.handleCloseSkillModal}
          className="primary-modal"
          aria-labelledby="Add-Certification-Modal"
          maxWidth="sm"
        >
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item xs={10}>
                <Typography className={classes.dialogTitle}>
                  {!updateModal ? "Add Certification" : "Update Certification"}
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                <img
                  src={imgClose}
                  className={classes.imgClose}
                  onClick={this.handleCloseSkillModal}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogData}>
            <Formik
              initialValues={{
                name: this.certificate?.name || "",
                organization_name: this.certificate?.organization_name || "",
                date: (this.certificate?.date ? (this.certificate?.date) : "") || "",
                documentName: this.certificate?.documentName || "",
              }}
              validationSchema={certificationSchema}
              onSubmit={(values) => {
                this.certificate ? this.updateCertificate(values,this.certificate) :
                this.handleAddSkill(values);
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <Form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.inputText} 
                          label="Course"
                          variant="outlined"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.name && errors.name ? errors.name : ""
                          }
                          error={errors.name && touched.name ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.inputText}
                          label="Organization"
                          variant="outlined"
                          name="organization_name"
                          value={values.organization_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.organization_name &&
                            errors.organization_name
                              ? errors.organization_name
                              : ""
                          }
                          error={
                            errors.organization_name &&
                            touched.organization_name
                              ? true
                              : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Date"
                          type="date"
                          maxDate
                          className={classes.inputText}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          name="date"
                          value={values.date}
                          onChange={(event) => {
                              setFieldValue("date",(event.target.value));  
                          }}
                          onBlur={handleBlur}
                          helperText={
                            touched.date && errors.date ? errors.date : ""
                          }
                          error={errors.date && touched.date ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          className="form-control have-suffix"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Upload Certificate
                          </InputLabel>
                          <OutlinedInput
                             className={classes.inputText}
                            labelWidth={130}
                            name="documentName"
                            value={values.documentName}
                            onBlur={handleBlur}
                            endAdornment={
                              <InputAdornment position="end">
                                <label
                                  htmlFor="chooseProfilePic"
                                  className="upload-outline-btn"
                                >
                                  <input
                                    id="chooseProfilePic"
                                    type="file"
                                    onChange={(e: any) =>{
                                        this.handleDocument(e)
                                        setFieldValue("documentName",e?.target?.files[0]?.name)
                                    }}                              
                                    
                                  />
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className="btn-sm"
                                  >
                                    Upload
                                  </Button>
                                </label>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <OutlineButton
                          fullWidth
                          style={{height : '50px'}}
                          onClick={this.handleCloseSkillModal}
                        >Cancel</OutlineButton>
                      </Grid>
                      <Grid item xs={6}>
                        {!updateModal ? (
                          <Button
                            className={classes.buttonAdd}
                            type="submit"
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            className={classes.buttonAdd}
                            type="submit"
                          >
                            Update
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
const style = (theme: any) => ({
  chip: {
    marginTop: "10px",
    height: "auto",
    overflow: "auto",
  },
  certificationTitle: {
    color: "#110f57",
  },
  dialogTitle: {
    fontFamily: "ProximaNova-Bold",
    fontSize: theme.spacing(3),
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#212121",
  },
  infoText: {
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#ffffff",
  },
  certificateText: {
    fontFamily: "ProximaNova-Semibold",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#110f57",
  },
  organization_name: {
    fontFamily: "ProximaNova-Regular",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#060606",
  },
  dateText: {
    fontFamily: "ProximaNova-Regular",
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.5px",
    color: "#686868",
  },
  buttonAdd: {
    width: "100%",
    height : '50px',
    backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
    color: "#ffffff",
    borderRadius: "6px",
    border: "solid 1px #e7e4f1",
    fontFamily : 'ProximaNova-Semibold'
  },
  inputText: {
    width: '100%',
  },
  dialogData:{
    height:'400px'
  },
  certificateHeading : {
    fontFamily: "ProximaNova-Semibold",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#424242',
    margin : '24px 0 12px 0'
  },
  helpertextheading : {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#686868',
    margin : '5px'
  },
  buttonAdd: {
    width: '100%',
    height : '50px',
    backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
    color: '#ffffff',
    borderRadius: '6px',
    fontFamily : 'ProximaNova-Semi-bold',
    border: 'solid 1px #e7e4f1',
  },
  tabHeading : {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121'
  },
  tabSubHeading : {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1,
    color: '#424242'
  }
});
const SkillsAndCertificationWithAlertBox = withAlertBox(SkillsAndCertification);
const SkillsAndCertificationWithLoader = withLoader(
  SkillsAndCertificationWithAlertBox
);
const SkillsAndCertificationWithSnackBar = withSnackBar(
  SkillsAndCertificationWithLoader
);
const SkillsAndCertificationWithConfirmBox = withConfirmBox(
  SkillsAndCertificationWithSnackBar
);

export default withStyles(style)(SkillsAndCertificationWithConfirmBox);
