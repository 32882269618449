import { withStyles, Button } from "@material-ui/core";

export const TextButton = withStyles({
  root: {
    border: "none",
    backgroundColor : 'transparent',
    height: "14px",
    "&:hover": {
      backgroundImage: "none!important",
      backgroundColor: "transparent",
    },
  },
  label: {
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#009fff",
  },
})(Button);



export const OutlineButton = withStyles({
  root: {
    borderRadius: "6px",
    border: "solid 1px #110f57",
    height: "50px",
    "&:hover": {
      backgroundImage: "none!important",
      backgroundColor: "transparent",
    },
  },
  label: {
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#110f57",
  },
})(Button);

export const ContainedButton = withStyles({
  root: {
    borderRadius: "6px",
    backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
    height: "50px",
  },
  label: {
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
  },
})(Button);
