// @ts-nocheck
import React, { Component } from "react";
import {AlertDialogContext} from "./AlertBoxContext";

function withAlertBox(OriginalComponent) {
  return (props) =>  {
    const  openDialog  = React.useContext(AlertDialogContext);
    const showAlert = (options) => {
       new Promise((resolve) => {
          openDialog({ actionCallback: resolve, ...options });
        });
      }
      return (
          <OriginalComponent {...props} showAlert={showAlert}/>
      );
    
  };
}
export default withAlertBox;
