//@ts-nocheck
import React from "react";
import { imgArrow, imgLogo, imgSide } from "./assets";
import VerifyOTPController, { Props } from "./VerifyOTPController.web";
import {
  Button as TextButton,
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Checkbox,
  createStyles,
} from "@material-ui/core";
import Button from '../../../components/src/CustomButton.web'
import StrageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import OtpInput from "../../../components/src/OtpInput.web";
import { Redirect } from "react-router";
import { runEngine } from "../../../framework/src/RunEngine";


class VerifyOtp extends VerifyOTPController {
  constructor(props: Props) {
    super(props);
  }
  timer = null;
  componentDidMount() {
    this.timer = setInterval(this.manageTimer, 1000);
    const { location } = this.props;
    const phoneInfo = location?.state?.state;
    if (phoneInfo) {
      this.setState({
        phoneNo: phoneInfo.phoneNo,
        token: phoneInfo.token,
        type: phoneInfo.type,
      });
    }

  }
  async componentWillUnmount() {
    clearInterval(this.timer);
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  }
  manageTimer = () => {
    var states = this.state;
    if (states.counter === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        counter: this.state.counter - 1,
      });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid xs={12} item>
          <div className={classes.appbarroot}>
            <AppBar position="static">
              <Toolbar style={{ backgroundColor: '#fff' }}>
                <img src={imgLogo} alt="" />
                <div className={classes.appbartitle}></div>
              </Toolbar>
            </AppBar>
          </div>
          <Grid container direction="row" justify="space-between" item
            alignItems="center" xs={12} spacing={1} className={classes.root}>
            <Grid item container sm={5} md={5} xs={12}>
              <Grid container item justify="center">
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.headingTitle} gutterBottom>
                    Verify Account !
                  </Typography>
                  <Typography variant="subtitle1">
                    We have sent one time password to your given mobile number.
                     <br />
                     Please enter to verify your account
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column" >
                    <Grid item xs={12}>
                      <OtpInput
                        OTPLength={4}
                        otpType={"number"}
                        onOTPChange={(otp) => {
                          this.setState({ otp: otp });
                        }}
                        secure={false}
                        value={this.state.otp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.counter == 0 ? null : (
                        <div className={classes.didntGetTheOTP}>
                          <div className={classes.timerTitle}>
                            RESEND OTP In 0{Math.floor(this.state.counter / 60)}:
                        {(this.state.counter % 60).toString().length > 1
                              ? this.state.counter % 60
                              : "0" + (this.state.counter % 60)}
                          </div>
                        </div>
                      )}
                      {this.state.counter == 0 ? (

                        <TextButton variant="text" disableRipple className={classes.buttonReset} onClick={() => { this.resendOTP() }}>
                          RESEND OTP
                        </TextButton>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.buttonContinue}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleVerifyOTP();
                        }}
                      >
                        Continue
                    <img src={imgArrow} alt="" className={classes.arrowImg}></img>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <img src={imgSide} alt="" className={classes.sideImage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const VerifyOtpWithRouter = withRouter(VerifyOtp);
const VerifyOtpWithAlertBox = withAlertBox(VerifyOtpWithRouter)
const VerifyOtpWithLoader = withLoader(VerifyOtpWithAlertBox)
const VerifyOtpWithSnackBar = withSnackBar(VerifyOtpWithLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(6.2),
      paddingLeft: theme.spacing(5)
    },
    Input: {
      // margin: theme.spacing(2),
      width: "100%",
    },
    buttonContinue: {
      width: "350px",
      height: '50px',
      backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
      color: "white",
    },
    buttonReset: {
      fontFamily: 'ProximaNova',
      fontSize: "16px",
      fontWeight: '600',
      color: "#110f57",
      margin: 'auto',
      width: '60%',
      marginTop: theme.spacing(1),
      "&:hover": {
        color: '#110f57!important',
        backgroundImage: 'none!important',
        backgroundColor: 'transparent'
      }
    },
    arrowImg: {
      position: 'relative',
      right: '-8px'
    },
    sideImage: {
      width: "80%",
      margin: "auto",
    },
    appbarroot: {
      flexGrow: 1,
    },
    appbarlogo: {
      marginRight: theme.spacing(2),
    },
    appbartitle: {
      flexGrow: 1,
    },
    arrowButton: {
      marginLeft: theme.spacing(1),
    },
    didntGetTheOTP: {
      marginTop: theme.spacing(1),
      margin: 'auto',
      width: '65%',
    },
    title: {
      fontWeight: 900,
      marginBottom: -20,
    },
    headingTitle : {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '48px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#212121'
    },
    timerTitle : {
      fontFamily: "ProximaNova-Regular",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#686868",
      lineHeight: 'normal',
    }
  })
)(VerifyOtpWithSnackBar);
