import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StrageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  loading: boolean;
  otp: string;
  phoneNo: string,
  token: string,
  counter: number,
  type: 'signup' | 'login' | 'forgot'
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyOTPController extends BlockComponent<Props, S, SS> {
  verifyOTPApiCallId: String;
  getOTPCode: String;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];
    this.state = {
      loading: false,
      otp: "",
      phoneNo: "",
      token: "",
      counter: 300,
      type: 'login'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.verifyOTPApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          if (this.state.type === 'login') {
            const token = responseJson.meta.token;
            const auth_token = responseJson?.meta?.auth_key;
            const userId = responseJson?.meta?.device_token;
            const userInfo = responseJson?.data?.attributes;
            if (userId && userId.is_activate) {
              await StrageProvider.set("token", token);
              await StrageProvider.set("auth_token", (auth_token));
              await StrageProvider.set("userId", JSON.stringify(userId));
              await StrageProvider.set("userInfo", JSON.stringify(userInfo));
              this.props.history.push("/WelcomeScreen");
            }
          } else if (this.state.type === 'signup') {
            this.props.showToast({ type: 'success', message: 'Account is activated successfully' })
            this.props.history.push("login");
          }
        }
        else if (this.state.type === 'forgot') {
          this.props.showToast({ type: 'success', message: 'OTP validation success' })
          this.props.history.push('/reset');
        }
        else {
          this.parseApiErrorResponse(responseJson);
          this.setState({ loading: false })
        }
      } else if (apiRequestCallId === this.getOTPCode) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.props.showToast({ type: 'success', message: 'OTP Sent Successfully' });
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({ loading: false })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title : 'System Message !',message : AlertBodyMessage})
      // .then(()=>{
      //   this.props.history.push('/login');
      // })
    }
  }
  async handleVerifyOTP() {
    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    this.verifyOTPApiCallId = requestMessage.messageId;
    // const verifyOtpUrl = this.state.type && this.state.type === 'signup'
    //   ? `${configJSON.verifySignUpOTPEndPoint}` : `${configJSON.verifyOTPEndPoint}`;
    // const verifyUrl = `${verifyOtpUrl}`;

    const requestBodyLogin = {
      "full_phone_number": this.state.phoneNo,
      "token": this.state.token,
      "pin": this.state.otp,
      "device_token": "test"
    };

    const requestBodyRegister = {
      "token": this.state.token,
      "pin": this.state.otp,
      "device_token": "devicetoken"
    };
    
    const tokenForgotPassword = await StrageProvider.get('forgotPWDToken');
    let requestBody, verifyUrl;
    const requestBodyForgotPassword = {
      "data": {
        "token": tokenForgotPassword,
        "otp_code": this.state.otp,
      }
    };

    if (this.state.type === 'signup') {
      requestBody = requestBodyRegister;
      verifyUrl = `${configJSON.verifySignUpOTPEndPoint}`;
    }
    else if (this.state.type === 'login') {
      requestBody = requestBodyLogin;
      verifyUrl = `${configJSON.verifyOTPEndPoint}`;
    }
    else if (this.state.type === 'forgot') {
      requestBody = requestBodyForgotPassword;
      verifyUrl = `${configJSON.ForgotPasswordOTPEndPoint}`
    }


    // const requestBody = this.state.type && this.state.type === 'signup' ? requestBodyRegister : requestBodyLogin;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      verifyUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  resendOTP() {
    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    const httpBody = {
      "full_phone_number": "+91" + JSON.stringify(this.state.phoneNo)
    }
    this.getOTPCode = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (this.state.type === 'login') ? `${configJSON.logInAPiEndPoint}` : `${configJSON.signUpThroughPhoneEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
