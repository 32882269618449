//@ts-nocheck
import React, { Component } from "react";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  withStyles,
  Typography
} from "@material-ui/core";
// import  Button  from "../../../components/src/CustomButton.web";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import {
  basicInfoGrey,
  basicInfoPrimary,
  qualificationPrimary,
  qualificationGrey,
  skillsCertificationsPrimary,
  skillsCertificationsGrey,
  experiencePrimary,
  experienceGrey,
  preferredJobsPrimary,
  preferredJobsGrey,
  additionalInfoPrimary,
  userGrey,
  logo,
  linkedin,
  facebook,
  maleBlack,
  malePrimary,
  femaleBlack,
  femalePrimary,
  unisexBlack,
  unisexPrimary,
  additionalInfoGrey,
  arrowPointingToRight,
} from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicControllerWeb";
import { OutlineButton } from "../../../components/src/Button.web";

const basicInfoValidationSchema = () => {
  return Yup.object().shape({
    gender: Yup.string().required("required"),
    name: Yup.string()
      .required("Enter the name")
      .min(3, "Enter minimum 3 characters"),
    profession: Yup.string().required("Enter the Desgination"),
    address: Yup.string().required("Enter the address"),
    email: Yup.string()
      .required("Enter the email address")
      .email("Email must be in valid format!"),
    bio: Yup.string().required("Enter your profile summary"),
    company_name: Yup.string().required("Enter your company Name"),
  });
};

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    width: "54px",
    height: "54px",
    margin: "0px 20px 0px 0px",
    color: "#000000",
    borderRadius: "6px",
    "&:not(:first-child)": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
    "&:first-child": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
    
  },
}))(ToggleButtonGroup);




class BasicInformation extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StrageProvider.get("auth_token");
    if (authToken) {
      this.setState({
        authToken: authToken,
      },()=>{
        this.getProfileInfo()
      });
    }
  }

  //  Basic info events

  profileImageHandler = (e: any) => {
    const files = e.target ? e.target.files : [];
    if (files.length > 0) {
      const file = files[0];
      this.imgBase64(file, (result: any) => {
        this.setState({
          ...this.state,
          profileImgBase64: result,
        });
      });
    }
  };
  imgBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
  };
  handleGenderChange = (
    event: React.MouseEvent<HTMLElement>,
    gender: string
  ) => {
    this.setState({
      ...this.state,
      gender: gender,
    });
  };

  render() {
    const {classes} = this.props;
    return (
      <>
        <div className="add-profile-tab-content">
          <div className="tab-title">
            <Typography className={classes.tabHeading}>Basic Information</Typography>
            <Typography className={classes.tabSubHeading}>About Yourself</Typography>
          </div>
          <div className="tab-main-content">
            <Formik
              enableReinitialize
              initialValues={{
                name: this.state.profileDetail?.name || "",
                profession: this.state.profileDetail?.profession || "",
                company_name: this.state.profileDetail?.company_name || "",
                email: this.state.profileDetail?.email || "",
                gender: this.state.profileDetail?.gender || "male",
                address: this.state.profileDetail?.full_address || "",
                bio: this.state.profileDetail?.bio || "",
              }}
              validationSchema={basicInfoValidationSchema()}
              onSubmit={(values: any) => {
                this.saveBasicInformation(values);
              }}
            >
              {(formik) => {
                const {
                  handleSubmit,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  dirty,
                  isSubmitting,
                  status
                } = formik;
                return (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <div className="choose-profile-pic">
                      <i>
                        {this.state.profileImgBase64 ? (
                          <img
                            src={this.state.profileImgBase64}
                            className="uploaded-img"
                          />
                        ) : (
                            <img
                              src={userGrey}
                              className="default"
                            />
                          )}
                      </i>
                      <div>
                        <label
                          htmlFor="chooseProfilePic"
                          className="upload-outline-btn"
                        >
                          <input
                            accept="image/*"
                            id="chooseProfilePic"
                            type="file"
                            onChange={(e) => {
                              this.profileImageHandler(e);
                            }}
                          />
                          <OutlineButton variant="outlined" color="primary">
                            Choose File
                          </OutlineButton>
                        </label>
                        <span>jpg or png. Max file size 500 KB</span>
                      </div>
                    </div>
                  
                    <TextField
                      className="form-control"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.name && errors.name ? true : false}
                      helperText={
                        touched.name && errors.name ? errors.name : ""
                      }
                      label={"Name"}
                      variant="outlined"
                    />
                    <TextField
                      className="form-control"
                      label="Current Designation"
                      variant="outlined"
                      name="profession"
                      value={values.profession}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        touched.profession && errors.profession
                          ? true
                          : false
                      }
                      helperText={
                        touched.profession && errors.profession
                          ? errors.profession
                          : ""
                      }
                    />
                    <TextField
                      className="form-control"
                      label="Company"
                      variant="outlined"
                      name="company_name"
                      value={values.company_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.company_name && errors.company_name ? true : false}
                      helperText={
                        touched.company_name && errors.company_name ? errors.company_name : ""
                      }
                    />
                    <TextField
                      className="form-control"
                      label="Email ID"
                      variant="outlined"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.email && errors.email ? true : false}
                      helperText={
                        touched.email && errors.email ? errors.email : ""
                      }
                    />

                    <div className="radio-group">
                      <div className={classes.genderTitle}>Gender</div>
                      <StyledToggleButtonGroup
                        value={this.state.gender}
                        exclusive
                        onChange={(e: any, gender: string) => {
                          this.handleGenderChange(e, gender);
                          setFieldValue("gender", gender);
                        }}
                        aria-label="text alignment"
                      >
                        <ToggleButton title='Male' value="male" aria-label="left aligned">
                          {this.state.gender === "male" ? (
                            <img src={malePrimary} className="active" alt="" />
                          ) : (
                            <img src={maleBlack} className="default" alt="" />
                          )}
                        </ToggleButton>
                        <ToggleButton title='Female' value="female" aria-label="centered">
                          {this.state.gender === "female" ? (
                            <img
                              src={femalePrimary}
                              className="active"
                              alt=""
                            />
                          ) : (
                            <img src={femaleBlack} className="default" alt="" />
                          )}
                        </ToggleButton>
                        <ToggleButton title='Unisex' value="unisex" aria-label="right aligned">
                          {this.state.gender === "unisex" ? (
                            <img
                              src={unisexPrimary}
                              className="active"
                              alt=""
                            />
                          ) : (
                            <img src={unisexBlack} className="default" alt="" />
                          )}
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </div>
                    <TextField
                      className="form-control"
                      label="Address"
                      variant="outlined"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address && errors.address ? true : false}
                      helperText={
                        touched.address && errors.address ? errors.address : ""
                      }
                    />

                    <TextField
                      label="Profile Summary"
                      multiline
                      style={{width : '62%'}}
                      rows={3}
                      variant="outlined"
                      name="bio"
                      value={values.bio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.bio && errors.bio
                          ? true
                          : false
                      }
                      helperText={
                        touched.bio && errors.bio
                          ? errors.bio
                          : ""
                      }
                    />

                    <Button
                      style={{marginTop : '24px'}}
                      className="btn btn-iconic-text btn-lg"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <span>Next</span>
                      <img
                        className="ms-xs-2 ms-1"
                        src={arrowPointingToRight}
                        alt=""
                      />
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}
const style = ((theme) => ({
  tabHeading : {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121'
  },
  tabSubHeading : {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1,
    color: '#424242'
  },
  genderTitle : {
    fontFamily: "ProximaNova-Regular",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#686868",
    lineHeight: 'normal',
  }
}))
const BasicInformationWithAlertBox = withAlertBox(BasicInformation)
const BasicInformationWithLoader = withLoader(BasicInformationWithAlertBox)
const BasicInformationWithSnackBar = withSnackBar(BasicInformationWithLoader);
export default withStyles(style)(BasicInformationWithSnackBar);
