//@ts-nocheck
import React from "react";
import { NavLink } from "react-router-dom";
import { imgArrow, imgLogo, imgSide, imgTick, imgRightTick } from "./assets";
import SignUpController, { Props } from "./SignUpController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  createStyles,
  FormHelperText,
} from "@material-ui/core";
import Button from "../../../components/src/CustomButton.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    width: "170px",
    height: "54px",
    margin: "0px 20px 0px 0px",
    color: "#000000",
    borderRadius: "6px",
    "&:not(:first-child)": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
    "&:first-child": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
  },
}))(ToggleButtonGroup);
const StyleToggleButton = withStyles({
  selected: {
    "&.Mui-selected": {
      color: "#110f57 !important",
      fontWeight: 600,
    },
  },
  label: {
    textTransform: "capitalize",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px'

  },
})(ToggleButton);

class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }
  //validations start
  signupSchema = Yup.object().shape({
    loginType: Yup.string().required("this field is reqired"),
    phone: Yup.string().when("loginType", {
      is: (loginType) => loginType === "phone",
      then: Yup.string()
        .min(10, "Enter 10 digit phone no.")
        .max(10, "Enter 10 digit phone no.")
        .required("This field is required"),
    }),
    email: Yup.string().when("loginType", {
      is: (loginType) => loginType === "email",
      then: Yup.string()
        .email("Email must be in valid format!")
        .required("This field is required."),
    }),
    password: Yup.string().when("loginType", {
      is: (loginType) => loginType === "email",
      then: Yup.string()
        .min(8, "Enter atleast 8 digit Password")
        .required("This field is required"),
    }),
    confirmPassword: Yup.string().when("loginType", {
      is: (loginType) => loginType === "email",
      then: Yup.string()
        .min(8, "Enter atleast 8 digit Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("This field is required"),
    }),
    termAndCondition: Yup.boolean()
      .required("This field is required")
      .oneOf([true], "You must accept the terms and conditions"),
  });
  //validations end

  phoneHandler = () => {
    this.setState({ msg: "" });
    this.setState({ email: "" });
    this.setState({ password: "" });
    this.setState({ phoneCheck: true });
    this.setState({ loginDisable: true });
  };
  phoneChange = (e: any) => {
    const rex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    const result = rex.test(e.target.value);
    this.setState({ dataCheck: !result });
    if (e.target.value.length > 10) {
      this.setState({ loginDisable: true });
      this.setState({ msg: "Phone Length should be 10 Digits only" });
    } else {
      this.setState({ msg: "" });
      this.setState({ loginDisable: false });
      this.setState({ phone: e.target.value });
    }
  };

  handleClose = () => {
    this.setState({ isEmailSend: false }, () => {
      this.props.history.push("login");
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <Grid xs={12} item>
            <div className={classes.appbarroot}>
              <AppBar position="static">
                <Toolbar style={{ backgroundColor: "#fff" }}>
                  <img src={imgLogo} alt="" />
                  <div className={classes.appbartitle} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.props.history.push("login");
                    }}
                    className={classes.buttonLogin}
                  >
                    Login &nbsp;
                    <img
                      className={classes.arrowButton}
                      src={imgArrow}
                      alt=""
                    />
                  </Button>
                </Toolbar>
              </AppBar>
            </div>
          </Grid>
          <Grid xs={12} item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              xs={12}
              item
              spacing={3}
              className={classes.root}
            >
              <Grid item container sm={5} md={5} xs={12}>
                <Formik
                  initialValues={{
                    phone: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    loginType: "email",
                    termAndCondition: false,
                  }}
                  validationSchema={this.signupSchema}
                  onSubmit={(values, actions) => {
                    this.handleSignUp(values);
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    } = formikProps;
                    return (
                      <Form>
                        <Grid container direction="column" spacing={values.loginType === 'phone' ? 1 : 0}>
                          <Grid item>
                            <div  className={classes.headingTitle}>
                              Welcome to hubura !
                            </div>
                            <Typography variant="subtitle1" gutterBottom >
                              Provide a few details to complete your registration.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <StyledToggleButtonGroup
                              name="loginType"
                              className={classes.toggleBtn}
                              value={values.loginType}
                              exclusive
                              onChange={(e) => {
                                setFieldValue(
                                  "loginType",
                                  e.currentTarget.value,
                                  false
                                );
                              }}
                              aria-label="loginType"
                            >
                              <StyleToggleButton
                                size="large"
                                value="phone"
                                aria-label="phone"
                              >
                                Via Phone
                              </StyleToggleButton>
                              <StyleToggleButton
                                size="large"
                                value="email"
                                aria-label="email"
                              >
                                Via Email
                              </StyleToggleButton>
                            </StyledToggleButtonGroup>
                          </Grid>
                          {values.loginType === "phone" && (
                            <Grid item xs={12}>
                              {/* <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item xs={3}> */}
                              <TextField
                                id="outlined-codes"
                                select
                                label="Code"
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                className={classes.countryCodes}
                              >
                                {this.state.codes && this.state.codes ? (
                                  this.state.codes.map(
                                    (option: any, index: number) => (
                                      <option key={index}>{option}</option>
                                    )
                                  )
                                ) : (
                                    <option> + 91</option>
                                  )}
                              </TextField>
                              {/* </Grid> */}
                              {/* <Grid item xs={9}> */}
                              <TextField
                                // id="outlined-textarea"
                                className={classes.phoneInput}
                                type="number"
                                maxLength={10}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                label={"Phone"}
                                autoFocus={true}
                                onBlur={handleBlur}
                                value={values.phone}
                                variant="outlined"
                                style={{
                                  width: "262px",
                                }}
                                onChange={handleChange}
                                name="phone"
                                error={
                                  touched.phone && errors.phone
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.phone && errors.phone
                                    ? errors.phone
                                    : ""
                                }
                              />
                            </Grid>
                            // </Grid>
                            // </Grid>
                          )}
                          {values.loginType === "email" && (
                            <Grid item container direction="column" spacing={2} className={classes.emailGrid}>
                              <Grid item>
                                <TextField
                                  className={classes.Input}
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.email && errors.email ? true : false
                                  }
                                  helperText={
                                    touched.email && errors.email
                                      ? errors.email
                                      : ""
                                  }
                                  label={"Email ID"}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  className={classes.Input}
                                  name="password"
                                  type="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.password && errors.password
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    touched.password && errors.password
                                      ? errors.password
                                      : ""
                                  }
                                  label={"Password"}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  className={classes.Input}
                                  name="confirmPassword"
                                  type="password"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.confirmPassword &&
                                      errors.confirmPassword
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    touched.confirmPassword &&
                                      errors.confirmPassword
                                      ? errors.confirmPassword
                                      : ""
                                  }
                                  label={"Confirm Password"}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          )}

                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.termAndCondition}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="termAndCondition"
                                  color="primary"

                                />
                              }
                              
                              label={
                                <>
                                  <span className={classes.termAndConditionClass}>I agree to the{" "}</span>
                                  <NavLink
                                    to="termsandconditions"
                                    target="_blank"
                                  >
                                    <span className={classes.highLightLink}>
                                      TnC
                                    </span>
                                  </NavLink>{" "}
                                  <span className={classes.termAndConditionClass}>and {""}</span>
                                  <NavLink to="privacypolicy" target="_blank">
                                    <span className={classes.highLightLink}>
                                      Privacy Policy
                                    </span>
                                  </NavLink>
                                </>
                              }
                            />
                            {touched.termAndCondition &&
                                  errors.termAndCondition && <FormHelperText
                              error={
                                touched.termAndCondition &&
                                  errors.termAndCondition
                                  ? true
                                  : false
                              }
                            >
                              {touched.termAndCondition &&
                                errors.termAndCondition
                                ? errors.termAndCondition
                                : ""}
                            </FormHelperText>
                           }
                          </Grid>
                          <Grid item>
                            <Button
                              type="submit"
                              className={classes.buttonContinue}
                              variant="contained"
                              color="primary"
                            >
                              Continue
                              <img
                                src={imgArrow}
                                alt=""
                                className={classes.arrowImg}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
              <Grid item sm={7} md={7} xs={12}>
                <img src={imgSide} alt="" className={classes.sideImage} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.isEmailSend}
        >
          <DialogContent className={classes.dialogStyles}>
            <div style={{ width: "20%", margin: "auto" }}>
              <img src={imgRightTick} />
            </div>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.confirmationText}
            >
              Confirmation Link Sent
            </Typography>
            <Typography className={classes.confirmationSubTitle}>
              We have sent a link on your mail to confirm your email 
            </Typography>
            <Typography align="center" className={classes.confirmationSubTitle}>
                id, Click on for the same.
            </Typography>
            <Button
              className={classes.buttonGotIt}
              onClick={this.handleClose}
            >
              GOT IT
            </Button>
          </DialogContent>
         
        </Dialog>
      </div>
    );
  }
}
const SignUpWithRouter = withRouter(SignUp);
const SignUpWithAlertBox = withAlertBox(SignUpWithRouter);
const SignUpWithLoader = withLoader(SignUpWithAlertBox);
const SignUpWithToast = withToast(SignUpWithLoader);

export default withStyles((theme) =>
  createStyles({
    Input: {
      width: theme.spacing(45),
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.5),
      color: '#686868',
    },
    root: {
      marginTop: theme.spacing(2.75),
      paddingLeft: theme.spacing(5),
    },
    buttonContinue: {
      margin: "auto",
      width: theme.spacing(45),
      height: theme.spacing(6.25),
      backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
      color: '#ffffff',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    arrowImg: {
      position: "relative",
      right: "-8px",
    },
    // emailConfirmButton: {
    //   margin: "auto",
    //   width: "90%",
    //   height: theme.spacing(6.2),
    // },
    sideImage: {
      width: '80%',
      margin: "auto",
    },
    appbarroot: {
      flexGrow: 1,
    },
    // appbarlogo: {
    //   marginRight: theme.spacing(2),
    // },
    appbartitle: {
      flexGrow: 1,
    },
    // backdrop: {
    //   zIndex: theme.zIndex.drawer + 1,
    //   color: "#fff",
    // },
    dialogStyles: {
      width: '440px',
      height: '280px',
      borderRadius: '8px',
      boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.24)',
      backgroundColor: '#ffffff'
    },
    buttonGotIt: {
      color: "#ffffff",
      width: theme.spacing(45),
      height: theme.spacing(6.25),
      marginLeft : '22px'
    },
    highLightLink: {
      // color: "#110f57",
      // fontWeight: 800,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontSize: theme.spacing(1.75),
      fontFamily: 'ProximaNova-Bold',
      color: '#686868'
    },
    mainTitle: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Bold',
      fontSize: theme.spacing(6)
    },
    subTitle: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(2)
    },
    buttonLogin: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      height: theme.spacing(5),
      width: theme.spacing(13.5),
      fontSize: theme.spacing(2)
    },
    termAndConditionClass: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
      color: '#686868',
      marginBottom: '-10px',
    },
    emailGrid: {
      marginTop: theme.spacing(1.25)
    },
    countryCodes: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#686868',
      fontSize: theme.spacing(1.5)
    },
    confirmationText: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Bold',
      textAlign: "center",
      color: '#212121',
      fontSize: theme.spacing(3)
    },
    confirmationSubTitle: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      color: '#424242',
      lineHeight: 0.25,
      fontSize: theme.spacing(2),
      textAlign: "center",
    },
    phoneInput: {
      marginLeft: theme.spacing(2),
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.8),
      borderRadius: '6px',
    },
    headingTitle : {
      fontFamily: 'ProximaNova-Bold',
  fontSize: '48px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#212121'
    }
  })
)(SignUpWithToast);

