import React, { PureComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import PhoneLogin from '../../blocks/PhoneLogin/src/PhoneLogin.web';
import VerifyOtp from '../../blocks/PhoneLogin/src/VerifyOtp.web';
import Signup from '../../blocks/PhoneLogin/src/Signup.web';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed.web';
import TermsAndCondition from '../../blocks/SignuploginModule/src/TermsAndCondition.web';
import PrivacyPolicy from '../../blocks/SignuploginModule/src/PrivacyPolicy.web';
import WelcomeScreen from '../../blocks/PhoneLogin/src/WelcomeScreen.web';
import ProtectedRoute from './ProtectedRoute.web';
import UserProfile from "../../blocks/user-profile-basic/src/Inforrmation.web";
import ResetPassword from '../../blocks/PhoneLogin/src/ResetPassword.web';
import ForgotPassword from '../../blocks/PhoneLogin/src/ForgotPassword.web';
import PrivateRoute from './PrivateRoute.web';
// import BusinessProfile from '../../blocks/user-profile-basic/src/BusinessProfile/BusinessProfile.web';
class RouteConfig extends PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <ActivityFeed />
          </Route>
          <Route path="/login" exact>
            <PhoneLogin />
          </Route>
          <Route path="/signUp" exact>
            <Signup />
          </Route>
          <ProtectedRoute path="/verifyOTP" exact>
            <VerifyOtp />
          </ProtectedRoute>
          <ProtectedRoute path="/WelcomeScreen" exact>
            <WelcomeScreen />
          </ProtectedRoute>
          <Route path="/TermsAndConditions" exact>
            <TermsAndCondition />
          </Route>
          <Route path="/PrivacyPolicy" exact>
            <PrivacyPolicy />
          </Route>
          <ProtectedRoute path="/profile" exact>
            <UserProfile />
          </ProtectedRoute>
          <PrivateRoute path='/reset' exact>
            <ResetPassword />
          </PrivateRoute>
          <Route path='/forgot' exact>
            <ForgotPassword />
          </Route>
          {/* <ProtectedRoute path='/businessProfile' exact>
            <BusinessProfile/>
          </ProtectedRoute> */}
        </Switch>
      </Router>
    );
  }
}
export default RouteConfig;
