export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export const imgBackground = require("../assets/rectangle.png");
// export const imgPath = require("../assets/path.png");
export const imgArrow = require('../assets/arrow-pointing-to-right.png');
export const imgSide = require('../assets/imgRegistration.png');
export const imgLogo = require('../assets/logo.png');
export const imgTick = require('../assets/tick.png');
export const imgRightTick = require("../assets/tick.png");
export const loginSideImg = require('../assets/loginSide.png');
