//@ts-nocheck
import React from "react";
import UserProfileBasicController,{Props} from "./UserProfileBasicControllerWeb";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  withStyles,
  Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import StrageProvider from "../../../framework/src/StorageProvider.web";

import { arrowPointingToRight } from "./assets";
const validationSchema = () => {
  return Yup.object().shape({
    religion: Yup.string().required("Select the religion"),
    caste: Yup.string().required("Select the caste"),
    birthPlace: Yup.string().required("Select the birth place"),
    livingIn: Yup.string().required("Select the living in criteria"),
    livingWith: Yup.string().required("Select the living with criteria"),
  });
};

export class AddiionalInfo extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
      }
    async componentDidMount() {
        const authToken = await StrageProvider.get("auth_token");
        let accountDetail = await StrageProvider.get("userId");
        if (authToken && accountDetail) {
          accountDetail = JSON.parse(accountDetail);
          this.setState(
            {
              authToken: authToken,
              accountDetail: accountDetail,
            },
            () => {
              this.getAdditionalInfo();
            }
          );
        }
      }  
  religionList = ["Hindu", "Buddhism", " Sikhism"];
  casteList = ["Patel", "Bramhin", " Jain"];
  houseTypeList = ["Own House", "Payin Guest", " Rent"];
  livingWithList = ["Family", "Friends"];
  birthPlaceList = ["Rajkot", "Ahmedabad", "Jaipur"];

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="tab-title">
          <Typography className={classes.tabHeading}>Additional Info</Typography>
          <Typography className={classes.tabSubHeading}>General Details</Typography>
        </div>
        <div className="tab-main-content">
          <Formik
            enableReinitialize
            initialValues={{
              religion: this.state.additional_info?.religion || "",
              caste: this.state.additional_info?.caste || "",
              birthPlace: this.state.additional_info?.birth_place || "",
              livingIn: this.state.additional_info?.living_in || "",
              livingWith: this.state.additional_info?.living_with || "",
            }}
            // validationSchema={validationSchema()}
            onSubmit={(values: any) => {
              this.saveAdditionalInfo(values);
            }}
          >
            {(formik) => {
              const {
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              } = formik;
              return (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <FormControl
                    variant="outlined"
                    className="form-control"
                    error={touched.religion && errors.religion ? true : false}
                  >
                    <InputLabel>Religion</InputLabel>
                    <Select
                      value={values.religion}
                      onChange={(e: any) =>
                        setFieldValue("religion", e.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Religion"
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {this.religionList.length > 0 &&
                        this.religionList.map((item: string) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText>
                      {touched.religion && errors.religion
                        ? errors.religion
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className="form-control"
                    error={touched.caste && errors.caste ? true : false}
                  >
                    <InputLabel>Caste</InputLabel>
                    <Select
                      value={values.caste}
                      onChange={(e: any) =>
                        setFieldValue("caste", e.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Caste"
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {this.casteList.length > 0 &&
                        this.casteList.map((item: string) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText>
                      {touched.caste && errors.caste ? errors.caste : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className="form-control"
                    error={
                      touched.birthPlace && errors.birthPlace ? true : false
                    }
                  >
                    <InputLabel>Birth Place</InputLabel>
                    <Select
                      value={values.birthPlace}
                      onChange={(e: any) =>
                        setFieldValue("birthPlace", e.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Birth Place"
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {this.birthPlaceList.length > 0 &&
                        this.birthPlaceList.map((item: string) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText>
                      {touched.birthPlace && errors.birthPlace
                        ? errors.birthPlace
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className="form-control"
                    error={touched.livingIn && errors.livingIn ? true : false}
                  >
                    <InputLabel>Living In</InputLabel>
                    <Select
                      value={values.livingIn}
                      onChange={(e: any) =>
                        setFieldValue("livingIn", e.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Living In"
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {this.houseTypeList.length > 0 &&
                        this.houseTypeList.map((item: string) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText>
                      {touched.livingIn && errors.livingIn
                        ? errors.livingIn
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className="form-control"
                    error={
                      touched.livingWith && errors.livingWith ? true : false
                    }
                  >
                    <InputLabel>Living With</InputLabel>
                    <Select
                      value={values.livingWith}
                      onChange={(e: any) =>
                        setFieldValue("livingWith", e.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Living With"
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {this.livingWithList.length > 0 &&
                        this.livingWithList.map((item: string) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText>
                      {touched.livingWith && errors.livingWith
                        ? errors.livingWith
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    className="btn btn-iconic-text btn-lg"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <span>Submit</span>
                    <img
                      className="ms-xs-2 ms-1"
                      src={arrowPointingToRight}
                      alt=""
                    />
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  }
}

const style = ((theme) => ({
  tabHeading : {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121'
  },
  tabSubHeading : {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1,
    color: '#424242'
  }
}))
const AddiionalInfoWithAlertBox = withAlertBox(AddiionalInfo);
const AddiionalInfoWithLoader = withLoader(AddiionalInfoWithAlertBox);
const AddiionalInfoWithSnackBar = withSnackBar(AddiionalInfoWithLoader);
export default withStyles(style)(AddiionalInfoWithSnackBar);
