// @ts-nocheck
import React from 'react';
import { imgArrow, imgLogo, imgSide } from './assets';
import EmailAccountLoginController, { Props } from './EmailAccountLoginController';
import { withStyles, Grid, AppBar, Toolbar, Typography, Button, TextField, Checkbox } from '@material-ui/core';

//styles start
const style = {
    title: {
        height: '30px',
        margin: '4px 0 4px 10px',
        fontFamily: 'CircularStd',
        fontSize: '28px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        color: '#323441',
    },
    welcomeText: {
        margin: "104px 1px 15px 50px",
        fontFamily: "ProximaNova",
        fontSize: "32px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: " normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#212121"
    },
    supportText: {
        height: '16px',
        margin: '13px 0px 30px 50px',
        fontFamily: 'ProximaNova',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#424242',
    },
    buttonPhone: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        margin: '0px -38px',
        border: 'solid 1px #e7e4f1',
    },
    buttonEmail: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        border: 'solid 1px #e7e4f1',
    },
    inputText: {
        margin: '6px 0 0',
        width: '380px',
        height: '10px',
    },
    buttonSignUp: {
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    buttonLogin: {
        margin: '6px 0 0',
        width: '380px',
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    buttonActive: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        margin: '0px -38px',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    buttonActiveEmail: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    errorMsg: {
        color: 'red',
        padding: '3px 1px 2px 6px'
    },
    btnDisable: {
        disable: true
    }
} as const;
//styles end


class EmailAccountLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
    }
    emailHandler = () => {
        this.setState({ msg: '' });
        this.setState({ phoneCheck: false });
        this.setState({ phone: '' })
    }
    phoneHandler = () => {
        this.setState({ msg: '' });
        this.setState({ email: '' });
        this.setState({ password: '' });
        this.setState({ phoneCheck: true });
        this.setState({ loginDisable: true })
    };
    emailOnChange = (e: any) => {
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const result = emailReg.test(String(e.target.value).toLowerCase());
        this.setState({ dataCheck: !result });
        if (!result) {
            this.setState({ loginDisable: true })
            this.setState({ msg: 'Incorrect Email Format' });
        } else {
            this.setState({ msg: '' });
            this.setState({ email: e.target.value });
            this.setState({ loginDisable: false });
        }
    };
    passwordChange = (e: any) => {
        const strongPwd = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const result = strongPwd.test(e.target.value);
        this.setState({ dataCheck: !result });
        if (!result) {
            this.setState({ loginDisable: true });
            this.setState({ msg: `Password should be atleast 8 charcters with 1 uppercase,numeric and a special character` })
        } else {
            this.setState({ msg: '' });
            this.setState({ password: e.target.value });
            this.setState({ loginDisable: false });
        }
    };
    passwordCheck = (e: any) => {
        const cpwd = e.target.value;
        const pwd = this.state.password;
        if (pwd && cpwd) {
            if (cpwd != pwd) {
                console.log("paws", pwd, "conf", e.target.value, "pwd", cpwd);
                this.setState({ loginDisable: true })
                this.setState({
                    msg: 'Password and Confirm Password Does not match'
                });
            }
        }
    };
    phoneChange = (e: any) => {
        const rex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
        const result = rex.test(e.target.value);
        this.setState({ dataCheck: !result });
        if (e.target.value.length > 10) {
            this.setState({ loginDisable: true })
            this.setState({ msg: 'Phone Length should be 10 Digits only' });
        } else {
            this.setState({ msg: '' });
            this.setState({ loginDisable: false });
            this.setState({ phone: e.target.value });
        }
    };
    render() {
        const { classes } = this.props;
        return (
            <div>
                <AppBar>
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <Grid container>
                            <Grid item xs={3}>
                                {/* <Typography variant="h6" className={classes.title}>
                                    Hubura
                                </Typography> */}
                                <img src={imgLogo} alt="" height="40px" width="136px"
                                />
                            </Grid>
                            <Grid item xs={7}></Grid>
                            <Grid item xs={2} >
                                <Button className={classes.buttonSignUp} style={{ float: "right" }}>
                                    Sign Up &nbsp; <img src={imgArrow} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <form >
                    <Grid container >
                        <Grid item xs={4}>
                            <Typography className={classes.welcomeText}>
                                Welcome back to hubura !
                        </Typography>
                            <Typography className={classes.supportText}>
                                Login with your credentials.
                        </Typography>
                            <Grid container>
                                <Grid item xs={2} />
                                <Grid item xs={4}>
                                    <Button className={this.state.phoneCheck ? classes.buttonActive : classes.buttonPhone} onClick={this.phoneHandler}>
                                        Via Phone
                                </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button className={this.state.phoneCheck ? classes.buttonEmail : classes.buttonActiveEmail} onClick={this.emailHandler}>
                                        Via Email
                                </Button>
                                </Grid>
                            </Grid>
                            <br />
                            {!this.state.phoneCheck ?
                                <div style={{ margin: "8px" }}>
                                    <Grid container>
                                        <Grid item xs={1} />
                                        <Grid item xs={10}>
                                            <TextField
                                                id="email"
                                                label="Email ID"
                                                variant="outlined"
                                                autoFocus={true}
                                                value={this.state.email}
                                                className={classes.inputText}
                                                onChange={(e) => this.emailOnChange(e)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={1} >
                                        </Grid>
                                    </Grid>
                                    <br /><br /><br />
                                    <Grid container>
                                        <Grid item xs={1} />
                                        <Grid item xs={10}>
                                            <TextField
                                                id="password"
                                                label="Password"
                                                variant="outlined"
                                                type="password"
                                                value={this.state.password}
                                                className={classes.inputText}
                                                onChange={e => this.passwordChange(e)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={1} />
                                    </Grid>
                                    <br /><br /><br />
                                </div>
                                :
                                <Grid container>
                                    <Grid item xs={2} />
                                    <Grid item xs={1} >
                                        <TextField
                                            id="outlined-codes"
                                            select
                                            label="Code"
                                            style={{ margin: "0px -40px" }}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                        >
                                            {this.state.codes && this.state.codes ? this.state.codes.map((option: any, index: number) => (
                                                <option key={index} value={option.value} >
                                                    {option}
                                                </option>
                                            )) : 0}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <TextField
                                            id="outlined-textarea"
                                            label="Phone"
                                            autoFocus={true}
                                            value={this.state.phone}
                                            variant="outlined"
                                            style={{ margin: "0px 40px", width: "262px" }}
                                            onChange={(e) => this.phoneChange(e)}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    <span>
                                        <Checkbox style={{ color: '#110F57 ' }} />
                                        I agree to the TnC and Privacy Policy
                                </span>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    <Button className={classes.buttonLogin} disabled={this.state.loginDisable || this.state.msg != ''}>
                                        Login &nbsp; <img src={imgArrow} />
                                    </Button>
                                </Grid>
                                <Grid item xs={1} >
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    {this.state.dataCheck || this.state.password ? <p className={classes.errorMsg}>{this.state.msg}</p> : ''}
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={7}>
                            <img src={imgSide} alt="" height="400px" width="700px" style={{
                                margin: '90px 49.2px 21px 150px'
                            }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
};

export default (withStyles)(style)(EmailAccountLogin)