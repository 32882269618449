import React from "react";
import { Dialog, DialogContent, DialogActions, Checkbox, Button, AppBar, Typography, Toolbar, TextField, withStyles, Grid, Backdrop } from '@material-ui/core';
import PhoneVerificationController, { Props } from './PhoneVerificationController';
import { imgSide, imgLogo, imgArrow, imgTick } from "./assets";

//styles start
const style = {
    buttonLogin: {
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    welcomeText: {
        margin: "65px 1px 15px 50px",
        fontFamily: "ProximaNova",
        fontSize: "36px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: " normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#212121"
    },
    supportText: {
        height: '16px',
        margin: '13px 0px 30px 50px',
        fontFamily: 'ProximaNova',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#424242',
    },
    buttonUser: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        margin: '0px -38px',
        border: 'solid 1px #e7e4f1',
    },
    buttonBusinessUser: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        border: 'solid 1px #e7e4f1',
    },
    buttonActive: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        margin: '0px -38px',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    buttonActiveBusinessUser: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    inputText: {
        margin: '3px 0px 5px 5px',
        width: '350px'
    },
    buttonResend: {
        margin: '23px 0px 30px 178px',
        padding: '0 1px 3px 0',
        fontFamily: 'ProximaNova',
        color: '#110f57',
        fontSize: '16px',
        // fontWeight: '600'
    },
    buttonContinue: {
        margin: '6px 0 0',
        width: '380px',
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    backdrop: {
        zIndex: 1,
        color: '#fff'
    },
    dialog: {
        width: '420px',
        height: '250px',
        borderRadius: '8px',

    },
    buttonGotIt: {
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white',
        margin: '1px 72px 35px',
        width: '310px',
        height: '30px'
    },
    buttonTick: {
        margin: '17px 180px',
    },
    buttonTickSupportText: {
        margin: '68px 1px',
        position: 'absolute',
        textAlign: 'center'
    },
    buttonTickTitle: {
        margin: '15px 86px',
        position: 'absolute',
        fontweight: 'bold',
        fontFamily: 'ProximaNova'
    }
} as const;
//Styles end

class PhoneVerification extends PhoneVerificationController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div>
                    <AppBar>
                        <Toolbar style={{ backgroundColor: "white" }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img src={imgLogo} alt="" height="40px" width="136px"
                                    />
                                </Grid>
                                <Grid item xs={7}></Grid>
                                <Grid item xs={2}>
                                    <Button className={classes.buttonLogin} style={{ float: "right" }}>
                                        Login &nbsp; <img src={imgArrow} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Grid container >
                        <Grid item xs={4}>
                            <Typography className={classes.welcomeText}>
                                Welcome to Hubura
                        </Typography>
                            <Typography className={classes.supportText}>
                                Provide a few details to complete your registration
                        </Typography>
                            <Grid container>
                                <Grid item xs={2} />
                                <Grid item xs={4}>
                                    <Button className={this.state.userCheck ? classes.buttonActive : classes.buttonUser}>
                                        User
                                </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button className={this.state.userCheck ? classes.buttonBusinessUser : classes.buttonActiveBusinessUser}>
                                        Business User
                                </Button>
                                </Grid>
                            </Grid>
                            <br />
                            {!this.state.userCheck ?
                                <div>
                                    <Grid container>
                                        <Grid item xs={1} />
                                        <Grid item xs={10}>
                                            <TextField
                                                label='Email ID'
                                                variant='outlined'
                                                className={classes.inputText}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={1} />
                                        <Grid>
                                            <TextField
                                                type='password'
                                                label='Password'
                                                variant='outlined'
                                                className={classes.inputText}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                : ''}
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    <span>
                                        <Checkbox style={{ color: '#110F57 ' }} />
                                        I agree to the TnC and Privacy Policy
                                </span>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    <Button className={classes.buttonContinue} >
                                        Continue &nbsp; <img src={imgArrow} />
                                    </Button>
                                </Grid>
                                <Grid item xs={1} >
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={imgSide} alt="" height="400px" width="600px" style={{
                                margin: '90px 49px 21px 0'
                            }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Backdrop open={!this.state.userCheck} className={classes.backdrop}>
                    <Dialog open={!this.state.userCheck} >
                        <DialogContent className={classes.dialog}>
                            <div style={{ alignContent: 'center' }}>
                                <img src={imgTick} className={classes.buttonTick} />
                                <h3 className={classes.buttonTickTitle}>Confirmation Link Sent</h3>
                                <p className={classes.buttonTickSupportText}>
                                    We have sent a link on your mail to confirm your email id, Click on for the same.                                </p>
                            </div>
                        </DialogContent>
                        {/* <DialogActions> */}
                        <Button className={classes.buttonGotIt}>
                            Got It
                            </Button>
                        {/* </DialogActions> */}
                    </Dialog>
                </Backdrop>
            </div>
        );
    }
};

export default (withStyles)(style)(PhoneVerification)
