import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StrageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert : any;
    showLoader : any;
    hideLoader : any;
    showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
export type LoginInputType = {
  phone?: String;
  email?: String;
  password?: String;
  loginType: "email" | "phone";
};
interface S {
  // Customizable Area Start
  loading: boolean;
  isEmailSend : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WelcomeScreenController extends BlockComponent<Props, S, SS> {
  loginApiEmailCallId : String;
  loginApiPhoneCallId : String;
  InputValueValue : LoginInputType;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      isEmailSend : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const token = responseJson.meta.token;
          StrageProvider.set("token", token);
          this.props.history.push("/verifyOtp",{
              state : {
                  phoneNo : `+91${this.InputValueValue?.phone}`,
                  token : token
              }
          })
        } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({loading:  false})
          }
      } else if(apiRequestCallId === this.loginApiPhoneCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            this.setState({isEmailSend : true});
          // const token = responseJson.meta.token;
          // StrageProvider.set("token", token);
          // this.props.history.push("/verifyOtp",{
          //     state : {
          //         phoneNo : `+91${this.InputValueValue?.phone}`,
          //         token : token
          //     }
          // })
        } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({loading:  false})
          }
      } 
    }
  }
  handleLogin(values: LoginInputType) {
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    this.InputValueValue = values;
    if(values.loginType === 'email'){
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.loginApiEmailCallId = requestMessage.messageId;
        const httpBody = {
            "data": {
                "type": "email_account",
                "attributes": {
                    "email": values.email,
                    "password": values.password,
                }
            }
        }
       
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.logInAPiEmailEndPoint}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POSTAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
     } else if(values.loginType === 'phone') {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.loginApiPhoneCallId = requestMessage.messageId; 
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.logInAPiPhoneEndPoint}+91${values.phone}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POSTAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
    }  


  }
  // Customizable Area End
}
