//@ts-nocheck
import React, { Component } from "react";
import {
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  TextField,
  withStyles,
  Button as OutlineButton
} from "@material-ui/core";
import {
  basicInfoGrey,
  basicInfoPrimary,
  qualificationPrimary,
  qualificationGrey,
  skillsCertificationsPrimary,
  skillsCertificationsGrey,
  experiencePrimary,
  experienceGrey,
  preferredJobsPrimary,
  preferredJobsGrey,
  additionalInfoPrimary,
  userGrey,
  logo,
  linkedin,
  facebook,
  maleBlack,
  malePrimary,
  femaleBlack,
  femalePrimary,
  unisexBlack,
  unisexPrimary,
  additionalInfoGrey,
  arrowPointingToRight,
} from "./assets";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import * as Yup from "yup";
import SkillsAndCertification from "./SkillsAndCertification.web";
import PreferredJob from "./PreferredJob.web";
import QualificationWeb from "./Qualification.web";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicControllerWeb";
import  BasicInformation  from "./BasicInformation.web";
import "../assets/styles/style.css";
import ExperexnceWeb from "./Experexnce.web";
import AddiionalInfo from "./AddiionalInfo.web";
import withAlertBox  from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { classNames } from "react-select/src/utils";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
export const StyledButton = withStyles({
  root: {
    borderRadius: '6px',
    border: 'solid 1px #110f57',
    height : '40px',
    '&:hover': {
      backgroundImage: 'none!important',
      backgroundColor: 'transparent'
    }
  },
  label : {
   fontFamily: 'ProximaNova-Semibold',
   fontSize: '14px',
   fontWeight: 600,
   fontStretch: 'normal',
   fontStyle: 'normal',
   lineHeight: 'normal',
   letterSpacing: 'normal',
   color: '#110f57',
  }
})(OutlineButton);
const style = (theme: any) => ({
  inputControl: {
    width: 360,
    marginBottom: 20,
  },
  addProfileWrapper: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 35px",
  },
  tabStep: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #dadce0",
    //position: "relative",
    borderRadius: 6,
    padding: "22px 25px",
    minWidth: 228,
    maxWidth: 228,
    overflow: "hidden",
  },
});

export class Information extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StrageProvider.get("auth_token");
    let accountDetail = await StrageProvider.get("userId");
    if (authToken && accountDetail) {
        accountDetail = JSON.parse(accountDetail);
        this.setState(
            {
                authToken: authToken,
                accountDetail: accountDetail,
            },
            () => {
                this.getExprience();
                this.getAdditionalInfo()
                this.getQualificationList();
                this.getSkillsAndCertificate();
                this.getPreferJobjs();
                this.getProfileInfo();
            }
        );
    }
}
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      ...this.state,
      value: newValue,
    });
  };
  tabManualChange = (value) => {
    this.setState({
      ...this.state,
      value: value,
    });
  }
  makeTabCompleted = (key,value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }
  render() {
     const { classes } = this.props;
    return (
      <>
        <div className="wrapper homepage">
          {/* header start */}
          <header className="ac_header">
            <div className="px-0">
              <div className="row">
                <div className="col">
                  <a href="/" className="nav-brand" title="Hubura">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="col-auto">
                  <div className="right-handle">
                    <StyledButton className={classes.backgroundHover}>
                      back to home
                    </StyledButton>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* header end */}

          <div className="main-content sidebarProfile">
            <div className="add-profile-stepper-wrapper">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                className="add-profile-stepper"
                value={this.state.value}
                onChange={this.handleChange}
                aria-label="Vertical tabs example"
              >
                <Tab
                  value={0}
                  label={
                    <div className={`tab-step ${this.state.profileDetail ? 'completed' : ''}`}>
                      <i>
                        <img className="default" src={basicInfoGrey} alt="" />
                        <img className="active" src={basicInfoPrimary} alt="" />
                      </i>
                      <p>
                        {" "}
                        Basic Information
                        <span>About Yourself</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                value={1}
                  label={
                    <div className={`tab-step ${(this.state.selectedQualificationList.length > 0) ? 'completed' : ''}`}>
                      <i>
                        <img
                          className="default"
                          src={qualificationGrey}
                          alt=""
                        />
                        <img
                          className="active"
                          src={qualificationPrimary}
                          alt=""
                        />
                      </i>
                      <p>
                        {" "}
                        Qualification
                        <span>Education &amp; Degree</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                value={2}
                  label={
                    <div className={`tab-step ${(this.state.SelectedCertificateList.length > 0) ? 'completed' : ''}`}>
                      <i>
                        <img
                          className="default"
                          src={skillsCertificationsGrey}
                          alt=""
                        />
                        <img
                          className="active"
                          src={skillsCertificationsPrimary}
                          alt=""
                        />
                      </i>
                      <p>
                        {" "}
                        Skills &amp; Certifications
                        <span>Professional Skills</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                value={3}
                  label={
                    <div className={`tab-step ${(this.state.selectedExperienceList.length > 0) ? 'completed' : ''}`}>
                      <i>
                        <img className="default" src={experienceGrey} alt="" />
                        <img
                          className="active"
                          src={experiencePrimary}
                          alt=""
                        />
                      </i>
                      <p>
                        {" "}
                        Experience
                        <span>Internship &amp; Jobs</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                value={4}
                  label={
                    <div className={`tab-step ${(this.state.selectedjobList.length > 0) ? 'completed' : ''}`}>
                      <i>
                        <img
                          className="default"
                          src={preferredJobsGrey}
                          alt=""
                        />
                        <img
                          className="active"
                          src={preferredJobsPrimary}
                          alt=""
                        />
                      </i>
                      <p>
                        {" "}
                        Preferred Jobs
                        <span>Job location,Timings etc</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  label={
                    <div className={`tab-step ${(this.state.additional_info) ? 'completed' : ''}`}>
                      <i>
                        <img
                          className="default"
                          src={additionalInfoGrey}
                          alt=""
                        />
                        <img
                          className="active"
                          src={additionalInfoPrimary}
                          alt=""
                        />
                      </i>
                      <p>
                        {" "}
                        Additional Info
                        <span>General Details</span>
                      </p>
                    </div>
                  }
                  {...a11yProps(5)}
                />
              </Tabs>

              <TabPanel value={this.state.value} index={0} className='basicinfoTabAdjust'>
                <BasicInformation tabChange={this.tabManualChange} makeTabCompleted={this.makeTabCompleted}        
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={1} className='qulaificationTabAdjust'>
                    <QualificationWeb tabChange={this.tabManualChange} makeTabCompleted={this.makeTabCompleted}/>
              </TabPanel>
              <TabPanel value={this.state.value} index={2} >
                <div className="add-profile-tab-content" className='skillsTabAdjust'>
                  <SkillsAndCertification tabChange={this.tabManualChange} makeTabCompleted={this.makeTabCompleted}
                     />
                </div>
              </TabPanel>
              <TabPanel value={this.state.value} index={3} >
                <div className="add-profile-tab-content" className='experienceTabAdjust'>
                  <div className="tab-main-content">
                    <ExperexnceWeb tabChange={this.tabManualChange} makeTabCompleted={this.makeTabCompleted}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.value} index={4} className='prefferJobTabAdjust'>
                <div className="add-profile-tab-content" >
                  <PreferredJob  tabChange={this.tabManualChange} makeTabCompleted={this.makeTabCompleted}             
                  />
                </div>
              </TabPanel>
              <TabPanel value={this.state.value} index={5} className='additionalTabAdjust'>
                <div className="add-profile-tab-content">
                  <AddiionalInfo makeTabCompleted={this.makeTabCompleted}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        {/* wrapper end */}
        {/* Footer start */}
        <footer className="ac_footer">
          <div className="primary-footer">
            <div className="container-xl">
              <div className="row">
                <div className="col-sm-auto">
                  <a href="/" className="nav-brand" title="Hubura">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="col-sm d-flex justify-content-sm-end align-items-center">
                  <ul className="footer-links">
                    <li>
                      <a href="/privacy-policy" title="Privacy Policy">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/term-and-condition"
                        title="Terms and Conditions"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Cookie Policy">
                        Cookie Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="secondary-footer">
            <div className="container-xl">
              <div className="row">
                <div className="col-auto">
                  <p>&copy; Hubura. All Rights Reserved</p>
                </div>
                <div className="col">
                  <ul className="social-links">
                    <li>
                      <a href="#" title="Facebook">
                        <img src={facebook} alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Linkedin">
                        <img src={linkedin} alt="linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        \00
      </>
    );
  }
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const InformationWithAlertBox = withAlertBox(Information)
const InformationWithLoader = withLoader(InformationWithAlertBox)
const InformationWithSnackBar = withSnackBar(InformationWithLoader);
export default withStyles(style)(InformationWithSnackBar);
