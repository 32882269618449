import React from "react";
import Button from "@material-ui/core/Button";
import { Button as OutlineButton } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Paper,
  Box,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  withStyles
} from "@material-ui/core";
import { Rating } from '@material-ui/lab';
import { NavLink } from "react-router-dom";
import {
  arrowPointingToRight,
  logo,
  locationPrimary,
  newspaper,
  createJob,
  businessJobSearch,
  airbnb,
  createCompanyProfile,
  discoverPeople,
  improveProductivity,
  easyJobApply,
  benifitsHabura,
  facebook,
  linkedin,
} from "./assets";
// import Carousel from 'react-material-ui-carousel'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/styles/style.css";
const StyledButton = withStyles({
  root: {
    borderRadius: "6px",
    borderStyle: 'solid',
    borderWidth: '1px',
    height : '40px',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#110f57',
    '&:hover': {
      background: "linear-gradient(96deg, #302d89, #0b65b2 100%)",
      color: '#fff !important'
    }
  },
  
})(OutlineButton);
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

import ActivityFeedWebController from "./ActivityFeedWebController";

export class ActivityFeed extends ActivityFeedWebController {
  async componentDidMount() {
    this.getJobList();
    this.getCompanyList();
    this.getLocationList();
  }

  renderSelectbox = () => {
    return (
      <FormControl className="country-select">
        <Select
          value={this.state.location}
          onChange={this.handleChange}
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          <MenuItem value="location">Location</MenuItem>
          {this.state.locationList &&
            this.state.locationList.length > 0 &&
            this.state.locationList.map((location: string, index: number) => {
              return (
                <MenuItem key={index} value={location}>
                  {location}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };
  render() {
    return (
      <>
        <div className="wrapper">
          {/* header start */}
          <header className="ac_header">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <a href="#" className="nav-brand">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="col-auto">
                  <div className="right-handle">
                    <NavLink to="/login">
                      <Button className="btn me-2">Login</Button>
                    </NavLink>
                    <NavLink to="/signup">
                      <Button
                        className="btn btn-iconic-text"
                        variant="contained"
                        color="primary"
                      >
                        SIGN UP
                        <img
                          className="ms-2"
                          src={arrowPointingToRight}
                          alt=""
                        />
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* header end */}
          <div className="homepage-banner">
            <div className="container-xl">
              <h1 className="ProximaNova-Bold">
                Careers are built on networks.
              </h1>
              <p>
                If you’re looking for the latest in wireless headphones, look no
                further. These are perfect for TV, stereo, home, and cell phone.
              </p>
              <div className="banner-search d-md-flex d-none">
                <div className="banner-select-search ProximaNova-Regular">
                  <img className="location" src={locationPrimary} alt="" />
                  {this.renderSelectbox()}
                  <input
                    type="text"
                    className="searchText"
                    placeholder="Search Users/Companies/Jobs"
                  />
                </div>
                <Button
                  className="btn btn-iconic-text ms-2"
                  variant="contained"
                  color="primary"
                >
                  <span className="startBtn ProximaNova-Regular">
                    Start now
                  </span>
                  <img className="ms-2" src={arrowPointingToRight} alt="" />
                </Button>
              </div>

              <div className="banner-search d-md-none d-block">
                <div className="row">
                  <div className="col">
                    <div className="banner-select-search">
                      <img className="location" src={locationPrimary} alt="" />
                      {this.renderSelectbox()}
                      <input
                        type="text"
                        className=""
                        placeholder="Search Users/Companies/Jobs"
                      />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <Button
                      className="btn btn-iconic-text btn-test"
                      variant="contained"
                      color="primary"
                    >
                      <img src={arrowPointingToRight} alt="" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* home page banner end */}

          {/* How Hubura Works start */}
          <div className="how-habura-work">
            <div className="container-lg">
              <h4 className="text-center ProximaNova-Bold mb-4" >
                How Hubura Works
              </h4>
              <div className="row">
                <div className="col-md-4">
                  <div className="how-habura-work-card">
                    <img src={newspaper} alt="" />
                    <h6 className="firsth6">
                      Discover Articles, News &amp; Posts
                    </h6>
                    <p>
                      Doubtless, a common action of the advantage of the
                      specific decisions ensures integrity of The Communication
                      of Stable Manner.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="how-habura-work-card">
                    <img src={createJob} alt="" />
                    <h6 className="secondh6">
                      Create Jobs, Polls News &amp; Ads
                    </h6>
                    <p>
                      Doubtless, a common action of the advantage of the
                      specific decisions ensures integrity of The Communication
                      of Stable Manner.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="how-habura-work-card">
                    <img src={newspaper} alt="" />
                    <h6 className="thirdh6">
                      Find &amp; Apply to Jobs
                    </h6>
                    <p>
                      Doubtless, a common action of the advantage of the
                      specific decisions ensures integrity of The Communication
                      of Stable Manner.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* How Hubura Works end */}

          {/* Why People Love Hubura start*/}
          <div className='why-people-love-hubura'>
            <div>
              <div className='why-people-love-hubura-text'>
                Why People Love Hubura
              </div>
              <div className='column'>
                  <Carousel
                    responsive={responsive}
                    arrows={false}
                    showDots={true}
                    swipeable={false}
                    keyBoardControl={false}
                    containerClass="carousel-with-custom-dots"
                    dotListClass="custom-dot-list-style"
                  >
                     {this.state.companyList && this.state.companyList.length > 0 && this.state.companyList.map((ele,index) => (
                    <div  key={index}>
                      <Grid container className='div-data-love-hubura' direction='row' style={{ width: '435px', padding: '21px' }}>
                        <Grid item xs={2} sm={2}>
                          {/* ele?.company_logo?.file_path ? ele?.company_logo?.file_path :  */}
                          <img src={facebook} alt='company logo' className='compnay-img' />
                        </Grid>
                        <Grid item xs={2} sm={10}>
                            <span  className="companyNameWhyPeopleLoveHubura">{ele.company_name},</span>
                            <span className="companyLocationWhyPeopleLoveHubura">{ele.company_location}</span>
                            <Rating style={{width : '78%'}} value={ele.rating}></Rating>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <span style={{ fontFamily: 'ProximaNova-Regular', lineHeight: '1.56', color: '#424242', fontSize: '18px' }}>{ele.comment}</span>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <span style={{ fontFamily: 'ProximaNova-Regular', color: '#212121', fontSize: '18px' }}>Reviewed by {ele.commented_by}</span>
                        </Grid>
                      </Grid>
                    </div>
                    ))
                  }
                  </Carousel>
              </div>
            </div>
          </div>

          {/* Why People Love Hubura end */}
          {/* Hot Jobs on Hubura start */}
          <div className="hot-jobs-wrapper">
            <div className="container-lg">
              <h4 className="text-center ProximaNova-Bold pb-4 mb-3">
                Hot Jobs On Hubura
              </h4>
              <div className="row">
                {this.state.jobList.length > 0 &&
                  this.state.jobList.map((data: any) => {
                    return (
                      <div className="col-md-4">
                        <div className="hot-job-card">
                          <div className="organization-details">
                            <i><img src={airbnb} alt="" /></i>
                            {/* <i><img src="https://lh3.googleusercontent.com/proxy/rkiPAX2uqx_UyRsTA_rbAVWPax4P2zSuhk42lvHorTLuWYBje_bWB45pzwoR-buAipLDsoDYDVMkOLxzpRqmg5N_-WDO9sU0hQuBv1f50vBvHoi2TZZuFEg_UXye3CEOByGCAKYNkYkJpYE1xsxfoMCgbc0Rrm0" alt="" /></i> */}
                            <div>
                              <p className="ProximaNova-Semibold">
                                {data.role}
                              </p>
                              <span>{data.company.name}</span>
                            </div>
                          </div>
                          <p className="job-description">
                            {data.job_description}
                          </p>
                          <StyledButton >
                            Apply Now
                          </StyledButton>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Hot Jobs on Hubura end */}
          {/* Benifits of Habura start */}
          <div className="benifits-habura-wrapper">
            <div className="container-lg">
              <div className="row">
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="benifits-habura-info">
                        <h4 className="ProximaNova-Bold pb-4">
                          Benefits of Hubura
                        </h4>
                        <p className="benefitpapagraph">
                          The Interference of Functional of the Capacity (Kasey
                          Ahern in The Book of the Valuable Information) One of
                          the most striking features of this problem is that
                          concentration of the qualification be the goals of
                          candidate.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <img
                        className="benifits-habura-img"
                        src={benifitsHabura}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row benifits-habura-card-wrapper">
                    <div className="col-md-6">
                      <div className="benifits-habura-card">
                        <img src={easyJobApply} alt="" />
                        <h6 className="ProximaNova-Semibold">Easy job apply</h6>
                        <p>
                          Maiores voluptas laboriosam non dolorum perferendis
                          fuga repellat aut. Blanditiis quos in minus.
                          Voluptatum quia quia voluptas voluptatem vero ex
                          possimus. Iure et consectetur dolorem dicta
                          accusantium fugiat.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="benifits-habura-card">
                        <img src={improveProductivity} alt="" />
                        <h6 className="ProximaNova-Semibold">
                          Improve the productivity
                        </h6>
                        <p>
                          It's the details that make Dummy such a winner. By way
                          of comparison, consider last summer's "My Big Fat
                          Greek Wedding," in which each actor put a heartfelt
                          spin on his or her one-joke character (the father who
                          believes.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="benifits-habura-card">
                        <img src={discoverPeople} alt="" />
                        <h6 className="ProximaNova-Semibold">
                          Discover the people
                        </h6>
                        <p>
                          Maiores voluptas laboriosam non dolorum perferendis
                          fuga repellat aut. Blanditiis quos in minus.
                          Voluptatum quia quia voluptas voluptatem vero ex
                          possimus. Iure et consectetur dolorem dicta
                          accusantium fugiat.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="benifits-habura-card">
                        <img src={createCompanyProfile} alt="" />
                        <h6 className="ProximaNova-Semibold">
                          Create company profile
                        </h6>
                        <p>
                          Maiores voluptas laboriosam non dolorum perferendis
                          fuga repellat aut. Blanditiis quos in minus.
                          Voluptatum quia quia voluptas voluptatem vero ex
                          possimus. Iure et consectetur dolorem dicta
                          accusantium fugiat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Benifits of Habura end */}
        </div>
        {/* Footer start */}
        <footer className="ac_footer">
          <div className="primary-footer">
            <div className="container-xl">
              <div className="row">
                <div className="col-auto">
                  <a href="#" className="nav-brand">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="col">
                  <ul className="footer-links">
                    <li>
                      <NavLink to="/PrivacyPolicy">
                        <a href="javascript:void(0)" title="Privacy Policy">
                          Privacy Policy
                        </a>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/TermsAndConditions">
                        <a
                          href="javascript:void(0)"
                          title="Terms and Conditions"
                        >
                          Terms and Conditions
                        </a>
                      </NavLink>
                    </li>
                    <li>
                      <a href="javascript:void(0)" title="Cookie Policy">
                        Cookie Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="secondary-footer">
            <div className="container-xl">
              <div className="row">
                <div className="col-auto">
                  <p>&copy; Hubura. All Rights Reserved</p>
                </div>
                <div className="col">
                  <ul className="social-links">
                    <li>
                      <a href="#" title="Facebook">
                        <img src={facebook} alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Linkedin">
                        <img src={linkedin} alt="linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* Footer end */}
      </>
    );
  }
}

export default ActivityFeed;
