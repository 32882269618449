import Proximanova from './fonts/Proxima-Nova-Bold.woff2';
// import ProximanovaBold from "/.fonts/proximanova-regular.woff2";
const proximanovaBold = {
  fontFamily: 'ProximaNova',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: '#212121',
  src: `
      local('ProximaNova-Bold'),
      url(${Proximanova}) format('woff2')
    `
};
const proximanovaRegular = {
  fontFamily: 'ProximaNova',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#424242',
  src: `
      local('ProximaNova-Regular'),
      url(${Proximanova}) format('woff2')
  `
};

import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff'
    }
  },
  typography: {
    h4: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      color: '#212121',
      fontFamily: 'ProximaNova-Bold'
    },
    subtitle1: {
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#424242',
      fontFamily: 'ProximaNova-Regular'
    },
    allVariants: {
      fontFamily: 'ProximaNova ProximaNova-Bold ProximaNova-Regular'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [proximanovaBold, proximanovaRegular]
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#212121',
        fontFamily: 'ProximaNova-Regular'
      },
      
    },
    MuiButton : {
      textPrimary : {
        fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#009fff",
      }
    },
    MuiInputLabel : {
      shrink : {
        transformOrigin : '25% -6% 0'
      }
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          fontSize: '12px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#686868',
          fontFamily: 'ProximaNova-Regular'
        },
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#686868',
        fontFamily: 'ProximaNova-Regular'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#212121',
        fontFamily: 'ProximaNova-Regular'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#686868',
        fontFamily: 'ProximaNova-Regular'
      }
    },
    MuiChip: {
      root: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#110f57',
        fontFamily: 'ProximaNova-Regular'
      }
    },
    MuiFormControl: {
      root: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#686868',
        fontFamily: 'ProximaNova-Regular!important'
      }
    },
    MuiFormControlLabel: {
      root: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#686868',
        fontFamily: 'ProximaNova-Regular!important'
      },
      label: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#686868',
        fontFamily: 'ProximaNova-Regular!important'
      }
    },
    MuiSnackbar : {
      root : {
        fontFamily: 'ProximaNova-Semibold',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff'
      },
      
    }
  }
});
export default theme;
