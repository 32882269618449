//@ts-nocheck
import React from 'react';
import { withRouter } from "react-router";
import { Typography, TextField, Grid, withStyles, createStyles, AppBar, Toolbar, DialogActions, DialogTitle, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import withLoader from "../../../components/src/withLoader.Web";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import ResetPasswordController, { Props } from './ResetPasswordController.web';
import { imgSide, imgLogo, imgArrow, imgRightTick } from './assets';
import Button from "../../../components/src/CustomButton.web";
import StorageProvider from '../../../framework/src/StorageProvider.web';


//validations start
const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().min(8, "Enter atleast 8 digit Password").required("New Password is Required"),
    confirmPassword: Yup.string().min(8, "Enter atleast 8 digit Password").oneOf([Yup.ref("newPassword"), null], "Passwords are not matching").required("Confrim Password is Required"),
});
//validations end


class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        const token = await StorageProvider.get('forgotPWDToken');
        this.setState({ ...this.state, auth_token: token, });
    }
    modalClose = () => {
        this.setState(
            {
                ...this.state,
                isUpdatePassword: false
            },
            () => {
                this.props.history.push("/login");
            }
        );
    }

    render() {
        const { classes } = this.props;
        console.log("hdsfgjhsgdf",this.state.isUpdatePassword);
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.appbarroot}>
                            <AppBar position="static">
                                <Toolbar style={{ backgroundColor: '#fff' }}>
                                    <img src={imgLogo} alt="" />
                                    <div className={classes.appbartitle}></div>
                                    <Button variant="contained" color="primary" className={classes.buttonLogin} onClick={() => { this.props.history.push("/login") }}>Login &nbsp;<img src={imgArrow} /></Button>
                                </Toolbar>
                            </AppBar>
                        </div>
                        <Grid container direction="row" justify="center" alignItems="center" xs={12} spacing={2} className={classes.root}>
                            <Grid item container sm={5} md={5} xs={12}>
                                <Formik
                                    initialValues={{
                                        newPassword: '',
                                        confirmPassword: ''
                                    }}
                                    validationSchema={resetPasswordSchema}
                                    onSubmit={(values) => {
                                        this.handleUpdatePassword(values)
                                    }}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit } = props;
                                        return (
                                            <Form>
                                                <Grid container style={{ fontFamily: 'ProximaNova' }}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='h4' className={classes.headingTitle}>
                                                            Reset Password
                                                    </Typography>
                                                        <Typography variant='subtitle1' className={classes.mainSupportText}>
                                                            Create a new password
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item container direction="column" spacing={2}>
                                                        <Grid item>
                                                            <TextField
                                                                type='password'
                                                                name='newPassword'
                                                                value={values.newPassword}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                helperText={touched.newPassword && errors.newPassword ? errors.newPassword : ""}
                                                                error={touched.newPassword && errors.newPassword ? true : false}
                                                                label={"New Password"}
                                                                variant="outlined"
                                                                className={classes.textfiled}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField
                                                                type='password'
                                                                name='confirmPassword'
                                                                value={values.confirmPassword}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ""}
                                                                error={touched.confirmPassword && errors.confirmPassword ? true : false}
                                                                label={"Confirm New Password"}
                                                                variant="outlined"
                                                                className={classes.textfiled}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            type='submit'
                                                            variant='contained'
                                                            color='primary'
                                                            className={classes.buttonContinue}
                                                        > Continue &nbsp;<img src={imgArrow} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Grid>
                            <Grid item sm={7} md={7} xs={12}>
                                <img src={imgSide} className={classes.sideImage}></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    onClose={this.modalClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isUpdatePassword ? true : true}
                >
                    <DialogContent className={classes.dialogStyles}>
                        <div style={{ width: "20%", margin: "auto" }}>
                            <img src={imgRightTick} />
                        </div>
                        <Typography
                            variant="h6"
                            gutterBottom
                            className={classes.confirmationText}
                            style={{ textAlign: "center" }}
                        >
                            Password Reset Successful
                        </Typography>
                        <Typography  className={classes.confirmationSupportText}>
                            You have successfully reset the password for your
                        </Typography>
                        <Typography align="center" className={classes.confirmationSupportText}>
                            hubura account.
                        </Typography>
                        <Button
                            className={classes.buttonGotIt}
                            onClick={this.modalClose}
                        >
                            GOT IT
                        </Button>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

const ResetPasswordWithRouter = withRouter(ResetPassword);
const ResetPasswordWithLoader = withLoader(ResetPasswordWithRouter)
const ResetPasswordWithAlertBox = withAlertBox(ResetPasswordWithLoader)
const ResetPasswordWithSnackBar = withSnackBar(ResetPasswordWithAlertBox);

export default withStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(6.2),
            paddingLeft: theme.spacing(5)
        },
        appbarlogo: {
            marginRight: theme.spacing(2),
        },
        appbartitle: {
            flexGrow: 1,
        },
        sideImage: {
            width: "80%",
            height: "80%",
            margin: "auto",
        },
        buttonContinue: {
            width: theme.spacing(42),
            height: theme.spacing(6),
            backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
            color: "white",
            borderRadius: '6px',
            marginTop:theme.spacing(2),
        },
        textfiled: {
            width: theme.spacing(42)
        },
        mainTitle: {
            color: '#212121',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontFamily: 'ProximaNova-Bold',
            fontSize: theme.spacing(6),
        },
        mainSupportText: {
            color: '#424242',
            fontFamily: 'ProximaNova-Regular',
            fontSize: theme.spacing(2),
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
        },
        confirmationText: {
            color: '#212121',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontFamily: 'ProximaNova-Bold',
            textAlign: "center",
            marginBottom:theme.spacing(-1)
        },
        confirmationSupportText: {
            fontFamily: 'ProximaNova-Regular',
            color: '#424242',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 0.25,
            letterSpacing: 'normal',
            fontSize: theme.spacing(2),
        },
        dialogStyles: {
            width: '420px',
            height: '260px',
            borderRadius: '8px',
            boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.24)',
            backgroundColor: '#ffffff',
            paddingLeft : '24px',
            paddingRight : '24px'
        },
        buttonGotIt:{
            borderRadius: '6px',
            marginLeft: theme.spacing(1.25),
            width: theme.spacing(45),
            height: theme.spacing(6.25),
        },
        buttonLogin:{
            height: theme.spacing(5)
        },
        headingTitle : {
            fontFamily: 'ProximaNova-Bold',
            fontSize: '48px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#212121'
          }
    })
)(ResetPasswordWithSnackBar);
