import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export type Props = RouterProps & StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
};

interface S {
    loading: boolean;
    auth_token: string;
    isForgotPassword: boolean;
};

export type ForgotPassword = {
    email: string;
};

interface SS {
    id: any;
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS>{
    forgotPasswordAPICallId: string;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
            // Customizable Area End
        ];

        this.state = {
            loading: false,
            auth_token: '',
            isForgotPassword: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    };


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let errors = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.forgotPasswordAPICallId) {
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.meta && responseJson.meta.token) {
                    await StorageProvider.set('forgotPWDToken',responseJson.meta.token);
                    this.setState({
                        ...this.state,
                        loading: false,
                        isForgotPassword : true
                    });
                    // this.props.history.push("/verifyOtp",{
                    //     state : {
                    //         type : 'forgot'
                    //     }
                    // });
                    // this.props.showAlert({ type: 'success', title: 'Check Your Email', message: 'Password reset link has been sent to your registered email.' });
                    //this.props.showToast({ type: 'success', message: 'Confirmation Link Sent Your Email ID' })
                }
                else {
                    this.parseApiCatchErrorResponse(responseJson);
                    this.setState({ loading: false });
                }
            }
        }
    };

    forgotPasswordHandle(values: ForgotPassword) {
        this.setState({ loading: true });

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        const headers = {
            'platform': 'web',
            'content-type':'application/json'
        };

        this.props.showLoader();

        const httpBody = {
            "data": {
                "attributes": {
                    "email": values.email
                }
            }
        };

        this.forgotPasswordAPICallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.forgotPasswordAPIEndPoint}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.forgotPasswordAPIMethod);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
};