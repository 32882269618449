Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.GETAPiMethod = "GET";
exports.POSTAPiMethod = "POST";
exports.ApiContentType = "application/json";


//api login 
exports.logInAPiEmailEndPoint="bx_block_login/logins"
exports.logInAPiEndPoint="accounts/login_otp"
exports.verifyOTPEndPoint="accounts/login_confirmation"
//api signup
exports.signUpThroughEmailEndPoint = "accounts"
exports.signUpThroughPhoneEndPoint = "accounts/signup_otp"
exports.verifySignUpOTPEndPoint="accounts/signup_confirmation"
exports.verifyEmailEndPoint = 'accounts/email_confirmation'


//api resetPassword
exports.resetPasswordAPIEndPoint= 'forgot_password/password'
exports.resetPasswordAPIMethod = 'POST'

//api forgotPassword
exports.forgotPasswordAPIEndPoint = 'forgot_password/otp'
exports.forgotPasswordAPIMethod = 'POST'

//api forgotPasswordOTP
exports.ForgotPasswordOTPEndPoint = 'forgot_password/otp_confirmation'
exports.ForgotPasswordOTPAPIMethod = 'POST'