Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";

exports.btnExampleTitle = "CLICK ME";
exports.contentTypeApiGetJobList = "application/json";
exports.apiEndPointGetJobList = "bx_block_joblisting/professional_jobs/recent_hot_jobs";
exports.apiGetJobListType = "GET";

exports.contentTypeApiGetCompanyList = "application/json";
exports.apiEndPointGetCompanyList = "bx_block_company/companies/recent_hubura_company";
exports.apiGetCompanyListType = "GET";


// Customizable Area End