//@ts-nocheck
import React from "react";
import { imgArrow, imgSide, imgLogo } from "./assets";
import WelcomeController, { Props } from "./WelcomeController.web";
import { withStyles, Grid, Typography,createStyles, AppBar,Toolbar} from "@material-ui/core";
import Button from "../../../components/src/CustomButton.web"; 
import { withRouter } from "react-router";
//styles end

class WelcomeScreen extends WelcomeController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid xs={12} item>
            <AppBar position="static" >
              <Toolbar style={{ width : '100%',backgroundColor: '#fff' }}>
                <img src={imgLogo} alt="" />
                <div className={classes.appbartitle}></div>
              </Toolbar>
            </AppBar>
          </Grid>  
          <Grid container direction="row" justify="space-between" item
            alignItems="center" xs={12}  className={classes.root}>
            <Grid item container sm={5} md={5} xs={12}>
              <Grid container item justify="center" spacing={4}>
                <Grid item xs={12}>
                  <div  className={classes.headingTitle}>
                         Hello User !
                  </div>
                  <div className={classes.content}>
                      Your account has beeen successfully activated, Lets get you onboarded on Hubura! Fill in these basic details to get yourself registered.                        {" "}
                  </div>
                </Grid>
                <Grid item xs={12}>
                      <Button
                        className={classes.buttonContinue}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.props.history.push("/profile");
                        }}
                      >
                        Continue
                    <img src={imgArrow} alt="" className={classes.arrowImg}></img>
                      </Button>
                   </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <img src={imgSide} alt="" className={classes.sideImage} />
            </Grid>
          </Grid>
      </Grid>
    
    );
  }
}
const WelcomeScreenWithRouter = withRouter(WelcomeScreen);
export default withStyles((theme) =>
  createStyles({
    root: {
        paddingTop: "125px",
        paddingLeft: "40px",
      },
      welcomeText: {
        // margin: "115px 1px 0 52px",
        fontFamily: "ProximaNova",
        fontSize: "32px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: " normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#212121",
      },
      appbartitle: {
        flexGrow: 1,
      },
      supportText: {
        height: "16px",
        // margin: '0px 0px 14px 50px',
        fontFamily: "ProximaNova",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#424242",
      },
      buttonContinue: {
        // margin: '6px 0 0',
        width: "360px",
        height: "50px",
        backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
        color: "white",
      },
      sideImage: {
        width: "80%",
        height: "80%",
        margin: "auto",
      },
      welcomScr: {
        height: "100%",
        overflow: "hidden",
      },
      headingTitle : {
        fontFamily: 'ProximaNova-Bold',
        fontSize: '48px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#212121'
      },
      content : {
        fontFamily: 'ProximaNova-Regular',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#424242'
      }
  })
)(WelcomeScreenWithRouter);
