import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");


export type Props = RouterProps & StyledProps &
{
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    // Customizable Area Start
    // Customizable Area End
};

export type ResetPassword = {
    newPassword?: string;
    confirmPassword?: string;
};
interface S {
    // Customizable Area Start
    loading: boolean;
    isUpdatePassword: boolean;
    auth_token: string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS>{
    updatePasswordAPICallId: string;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
            // Customizable Area End
        ];

        this.state = {
            loading: false,
            auth_token: '',
            isUpdatePassword: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            let errorsResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.updatePasswordAPICallId) {
                this.props.hideLoader();
                if (responseJson) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        isUpdatePassword: true
                    });
                    // this.props.history.push('/login');
                    // this.props.showAlert({ type: 'success', title: 'Password Reset Successful', message: 'You have successfully reset the password for your hubura account.' })
                    this.props.showToast({ type: 'success', message: 'Password Updated Successfully' });
                } else {
                    this.parseApiCatchErrorResponse(responseJson);
                    this.setState({ loading: false });
                }
            }
        }
    }

    handleUpdatePassword(values: ResetPassword) {
        this.setState({ loading: true });

        const headers = {
            "platform": "web",
            "Content-Type": 'application/json'
        };

        this.props.showLoader();

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updatePasswordAPICallId = requestMessage.messageId;
        const httpBody = {
            "data": {
                "token": this.state.auth_token,
                "new_password": values.newPassword
            }
        };

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.resetPasswordAPIEndPoint}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.resetPasswordAPIMethod);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}