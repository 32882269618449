// @ts-nocheck
import React, { Component } from "react";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import {
    withStyles,
    Grid,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    createStyles,
    DialogContentText,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from "@material-ui/core";
import { withRouter } from 'react-router';
import Button from "../../../components/src/CustomButton.web";
import { NavLink } from "react-router-dom";
import { imgArrow, imgLogo, imgSide, imgRightTick } from "./assets";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import withLoader from "../../../components/src/withLoader.Web";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withSnackBar from "../../../components/src/withSnackBar.Web";

class ForgotPassword extends ForgotPasswordController {

    constructor(props: Props) {
        super(props);
    }
    //validations start
    validationSchema = Yup.object().shape({
        email: Yup.string().email("Email must be in valid format").required("This field is required"),
    });
    //validations end
    modalClose = () => {
        this.setState(
            {
                ...this.state,
                isForgotPassword: false
            },
            () => {
                this.props.history.push("/verifyOtp", {
                    state: {
                        type: 'forgot'
                    }
                });
            }
        );
    };
    render() {
        const { classes } = this.props;
        console.log("forgotpassword",this.state.isForgotPassword);
        return (
            <div>
                <Grid container>
                    <Grid xs={12} item>
                        <div className={classes.appbarroot}>
                            <AppBar position="static">
                                <Toolbar style={{ backgroundColor: "#fff" }}>
                                    <img src={imgLogo} alt="" />
                                    <div className={classes.appbartitle} />
                                    <NavLink to="/login">
                                        <Button variant="contained" color="primary" className={classes.buttonSIGNUP}>
                                            Login &nbsp;<img src={imgArrow} />
                                        </Button>
                                    </NavLink>
                                </Toolbar>
                            </AppBar>
                        </div>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={12}
                            spacing={2}
                            className={classes.root}
                        >
                            <Grid item container sm={5} md={5} xs={12}>
                                <Formik
                                    initialValues={{
                                        email: "",
                                    }}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values, actions) => { this.forgotPasswordHandle(values) }}
                                >
                                    {(formikProps) => {
                                        const {
                                            values,
                                            errors,
                                            touched,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                        } = formikProps;
                                        return (
                                            <form
                                                onSubmit={handleSubmit}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Grid container direction="column" spacing={3}>
                                                    <Grid item>
                                                        <Typography variant="h4" className={classes.headingTitle}>
                                                            Forgot Password ?
                                                        </Typography>
                                                        <Typography variant="subtitle1" className={classes.supportText}>
                                                            Enter you registred email with hubura
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container direction="column" spacing={1}>
                                                    <TextField
                                                        className={classes.Input}
                                                        name="email"
                                                        value={values.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={touched.email && errors.email ? true : false}
                                                        helperText={
                                                            touched.email && errors.email ? errors.email : ""
                                                        }
                                                        label={"Email"}
                                                        variant="outlined"
                                                    /> <Grid item>
                                                        <Button
                                                            type="submit"
                                                            className={classes.buttonContinue}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Continue
                                                        <img
                                                                src={imgArrow}
                                                                alt=""
                                                                className={classes.arrowImg}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Grid>
                            <Grid item sm={7} md={7} xs={12}>
                                <img src={imgSide} alt="" className={classes.sideImage} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    onClose={this.modalClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isForgotPassword}
                >
                    <DialogContent className={classes.dialogStyles}>
                        <div style={{ width: "20%", margin: "auto" }}>
                            <img src={imgRightTick} />
                        </div>
                        <Typography
                            variant="h6"
                            gutterBottom
                            className={classes.confirmationText}
                        >
                            Check Your Email
                        </Typography>
                        <Typography className={classes.confrimationSupportText}>
                            Password reset link has been sent to your
                        </Typography>
                        <Typography align="center" className={classes.confrimationSupportText}>
                                registered email.
                        </Typography>
                        <Button
                            className={classes.buttonGotIt}
                            onClick={this.modalClose}
                        >
                            GOT IT
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const ForgorPasswordWithRouter = withRouter(ForgotPassword);
const ForgorPasswordWithLoader = withLoader(ForgorPasswordWithRouter);
const ForgotPassowrdWithAlertBox = withAlertBox(ForgorPasswordWithLoader);
const ForgotPassowrdWithSnackBar = withSnackBar(ForgotPassowrdWithAlertBox);

export default withStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(8),
            paddingLeft: theme.spacing(5),
        },
        Input: {
            margin: theme.spacing(2),
            width: theme.spacing(47.5),
            marginLeft: '5px',
        },
        buttonContinue: {
            width: theme.spacing(47.5),
            height: theme.spacing(6),
            backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
            color: "white",
        },
        sideImage: {
            width: "80%",
            height: "80%",
            margin: "auto",
        },
        appbarroot: {
            flexGrow: 1,
        },
        arrowImg: {
            position: "relative",
            right: "-8px",
        },
        appbarlogo: {
            marginRight: theme.spacing(2),
        },
        appbartitle: {
            flexGrow: 1,
        },
        paper: {
            maxWidth: theme.spacing(54),
        },
        arrowButton: {
            marginLeft: theme.spacing(1),
        },
        title: {
            // fontWeight: 800,
            fontFamily: 'ProximaNova-Bold',
            width: theme.spacing(60),
            height: theme.spacing(8),
            marginBottom: -20,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#212121',
            fontSize: theme.spacing(6)
        },
        supportText: {
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontSize: theme.spacing(2),
            color: '#424242',
            lineHeight: 0.5,
            fontFamily: 'ProximaNova-Regular',
        },
        confirmationText: {
            fontFamily: 'ProximaNova-Bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#212121',
            textAlign: 'center',
            fontSize: theme.spacing(3),
        },
        confrimationSupportText: {
            fontFamily: 'ProximaNova-Regular',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            lineHeight: 0.25,
            color: '#424242',
            textAlign: 'center',
            fontSize: theme.spacing(2),
        },
        dialogStyles: {
            width: '420px',
            height: '280px',
            borderRadius: '8px',
            boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.24)',
            backgroundColor: '#ffffff'
        },
        buttonGotIt:{
            width: theme.spacing(45),
            height: theme.spacing(6.25),
            marginLeft:theme.spacing(1.25)
        },
        buttonSIGNUP:{
            height: theme.spacing(5)
        },
        headingTitle : {
            fontFamily: 'ProximaNova-Bold',
            fontSize: '48px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#212121'
          }
    })
)(ForgotPassowrdWithSnackBar);
