export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const arrowPointingToRight = require("../assets/arrow-pointing-to-right.svg");
export const logo = require("../assets/logo.png");
export const locationPrimary = require("../assets/location-primary.svg");
export const businessJobSearch = require("../assets/business-job-search.svg");
export const createJob = require("../assets/create-job.svg");
export const newspaper = require("../assets/newspaper.svg");
export const airbnb = require("../assets/airbnb.jpg");
export const createCompanyProfile = require("../assets/create-company-profile.svg");
export const discoverPeople = require("../assets/discover-people.svg");
export const improveProductivity = require("../assets/improve-productivity.svg");
export const easyJobApply = require("../assets/easy-job-apply.svg");
export const benifitsHabura = require("../assets/benifits-habura.svg");
export const facebook = require("../assets/facebook.svg");
export const linkedin= require("../assets/linkedin.svg")