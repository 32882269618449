Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
// Customizable Area End

exports.apiEndPointSaveBasicInfo = "bx_block_account_details/account_profiles";
exports.contentTypeApiSaveBasicInfo = "multipart/form-data";
exports.apiSaveBasicInfoType = "POST";

exports.apiEndPointSaveQualification = "bx_block_account_details/academic_qualifications";
exports.contentTypeApiSaveQualification = "application/json";
exports.apiSaveQualificationType = "POST";


exports.apiEndPointGetProfile = "bx_block_account_details/account_profiles";
exports.contentTypeApiGetProfile = "application/json";
exports.apiGetProfileType = "GET";


exports.apiEndPointGetCompanyList = "bx_block_account_details/account_profiles/company_list";
exports.contentTypeApiGetCompanyList = "application/json";
exports.apiGetCompnayListType = "GET";

//Qualification
exports.apiEndPointAddQualification = "bx_block_account_details/academic_qualifications";
exports.contentTypejson = "application/json";

// AdditionalInfo
exports.apiEndPointAdditionalInfo = "bx_block_account_details/additional_infos";
exports.contentTypejson = "application/json";

//exprience
exports.apiEndPointGetExperience = 'bx_block_account_details/experiences';
exports.contentTypeApiSaveExperience = 'multipart/form-data';
exports.apiEndPointSearchCompanyName = 'bx_block_company/companies/search_by_name';
exports.apiSaveExperienceType= 'POST';

//skills
exports.apiEndPointGetSkills = "bx_block_account_details/certifications"
exports.apigetskills = "bx_block_account_details/skill_sets"

// preffered job
exports.apiEndPointPreferredJobs = "bx_block_account_details/preferred_jobs";
exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodDELETE = "DELETE";
exports.methodPUT = "PUT";
exports.contentType = "application/json";

// common 
