//@ts-nocheck
import React from 'react';
import UserProfileBasicController, { Props } from './UserProfileBasicControllerWeb';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import {  InputAdornment,Button,FormControl,IconButton,MenuItem, DialogActions, FormControlLabel, FormGroup, TextField, Grid, withStyles, Typography, Checkbox, Step, StepLabel, Stepper, Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { imgClose, imgArrow } from './assets';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import StorgeProvider from '../../../framework/src/StorageProvider';
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import {StyledButton as OutlineButton} from "./Inforrmation.web";
import { Autocomplete, } from '@material-ui/lab';
import { arrowPointingToRight, imgCheckCircle,userGrey } from "./assets";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";



//validations start
const experienceSchema = Yup.object().shape({
    company: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Company Name is Required'),
    location: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Location is Required'),
    role: Yup.string().required('Role is Required'),
    jobType: Yup.string().required('Job Type is Required'),
    joiningDate: Yup.string().required('Joining Date is Required'),
    leavingDate: Yup.string().required('Leaving Date is Required'),
    rolesResponsibilites: Yup.string().min(10, 'Too Short!').required('Roles & Responsibilites is Required')
})
//validations end

class Experience extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        const authToken = await StrageProvider.get("auth_token");
        let accountDetail = await StrageProvider.get("userId");
        if (authToken && accountDetail) {
            accountDetail = JSON.parse(accountDetail);
            this.setState(
                {
                    authToken: authToken,
                    accountDetail: accountDetail,
                },
                () => {
                    this.getExprience();
                }
            );
        }
    }
    //Experience Events
    addExperience = () => {
        this.setState({ ...this.state, addExperience: true });
    };
    exprience = null
    editExprience = (data, index) => {
        if (data.id) {
            // this.exprience = data;
            // this.setState({
            //     ...this.state,
            //     addExperience: true,
            // });
        }
    }
    experienceModalClose = () => {
        this.exprience = null;
        this.setState({ ...this.state, addExperience: false });
    };
    fetchComapnyName = async (values: any) => {
        if (values.length >= 3) 
           this.searchCompanyName(values);
    }
    addExperienceHandler = (values: any) => {
        if (+new Date(values.joiningDate) >= +new Date(values.leavingDate)) {
            this.props.showToast({ type: 'error', message: 'Joining date should be less than leave date' });
            return false;
        }
        else {
            let data = [...this.state.selectedExperienceList];
            data.push({
                joining_date: new Date(values.joiningDate),
                leaving_date: new Date(values.leavingDate),
                position: values.role,
                job_type: values.jobType,
                company_name: values.company,
                responsibilities: values.rolesResponsibilites,
                currentlyWorking: values.currentlyWorking,
                location: values.location
            });
            //sorting data as per timeline format
            data.sort(function compare(a, b) {
                const first: any = (a.joining_date);
                const second: any = (b.leaving_date);
                return second - first;
            });
            this.setState({
                ...this.state,
                selectedExperienceList: data,
            }, () => {
                this.experienceModalClose();
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className="add-profile-tab-content">
                    <div className="tab-title">
                        <Typography className={classes.tabHeading}>Experience</Typography>
                        <Typography className={classes.tabSubHeading}>Internship & Jobs</Typography>
                    </div>
                    <Grid item xs={12}>
                        <div> 
                            <div style={{width : '113px'}}>
                                <OutlineButton style={{width : '100%'}} onClick={this.addExperience}><span style={{textTransform : 'none'}}>+ Add more</span></OutlineButton> 
                            </div>
                            {this.state.selectedExperienceList && this.state.selectedExperienceList.length > 0
                                ?
                                <Timeline className={classes.timelineRoot}>
                                    {this.state.selectedExperienceList.map((ele, index) => (
                                        <TimelineItem key={index} onClick={()=>{this.editExprience(ele,index)}}>
                                            <TimelineSeparator>
                                                <TimelineDot color='primary' />
                                                {(index < (this.state.selectedExperienceList.length - 1)) && <TimelineConnector /> }
                                                {/* <TimelineConnector /> */}
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <span className={classes.timelineSubTitle}>{new Date(ele.joining_date).getFullYear()} - {new Date(ele.leaving_date).getFullYear()}</span>
                                                <Box className={classes.detailsBox}>
                                                    <Grid container justify="space-between" alignItems="center" >
                                                        <Grid item style={{padding:'10px'}} xs={2}>
                                                            <img src={userGrey} style={{objectFit: 'contain',borderRadius: '6px',width: '45px',height: '45px'}}></img>
                                                         </Grid>
                                                         <Grid item style={{padding:'10px'}} xs={10}>
                                                             <Typography component="div" variant='h5' className={classes.timelineHead}>{ele.position} , {ele.location}</Typography>
                                                             <Typography component="div" variant='subtitle1' className={classes.timelineSubTitle}>{ele.company_name}</Typography>
                                                             <p className={classes.timelineParagraph}>{ele.responsibilities || ele.bio}</p>
                                                         </Grid>
                                                    </Grid>
                                                   </Box>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                                : ''
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Button className="btn btn-iconic-text btn-lg"
                            variant="contained"
                            color="primary"
                            onClick={this.saveExperienceInfro}
                        >
                            <span>Next</span>
                            <img className="ms-xs-2 ms-1" src={imgArrow} alt="" />
                        </Button>
                    </Grid>
                    {/* </Grid> */}
                    <Dialog open={this.state.addExperience} onClose={this.experienceModalClose}>
                        <DialogTitle className={classes.titleRot}>
                            <Typography className={classes.dialogTitle}>Add Experience</Typography>
                            <IconButton className={classes.closeButton} aria-label="close" onClick={this.experienceModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.dialogData}>
                            <Formik
                                initialValues={{
                                    company: this.exprience?.company_name || '',
                                    location: this.exprience?.location || '',
                                    role: this.exprience?.position || '',
                                    jobType: this.exprience?.job_type || '',
                                    joiningDate: (this.exprience && this.exprience?.joining_date) || new Date(),
                                    leavingDate: (this.exprience && (this.exprience?.leaving_date)) || new Date(),
                                    rolesResponsibilites: this.exprience?.bio || '',
                                    currentlyWorking: this.exprience?.currentlyWorking || false
                                }}
                                validationSchema={experienceSchema}
                                onSubmit={(values) => {
                                    if (this.exprience) {
                                        values.joining_date = new Date(values.joiningDate),
                                            values.leaving_date = new Date(values.leavingDate),
                                            values.position = values.role,
                                            values.job_type = values.jobType,
                                            values.company_name = values.company,
                                            values.responsibilities = values.rolesResponsibilites,
                                            values.currentlyWorking = values.currentlyWorking,
                                            values.location = values.location
                                        this.editExprienceInDB(values, this.exprience.id);
                                        this.setState({ addExperience: false });
                                        this.exprience = null;
                                    } else {
                                        this.addExperienceHandler(values);
                                    }
                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,setFieldValue } = props;
                                    return (
                                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        freeSolo
                                                        disableClearable
                                                        options={this.state.companyListData ? this.state.companyListData : []}
                                                        onChange = {(e,value) => {
                                                            this.setState({
                                                                ...this.state,
                                                                selectedCompnayName : value
                                                            },()=>{
                                                                setFieldValue('company',this.state.selectedCompnayName.name);    
                                                            })
                                                        }}
                                                        getOptionLabel = {(item) => {
                                                            return item.name
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label={"Company"}
                                                                name='company'
                                                                value={values.company}
                                                                helperText={errors.company && touched.company ? errors.company : ''}
                                                                error={errors.company && touched.company ? true : false} variant="outlined"
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    this.fetchComapnyName(e.target.value)
                                                                }}
                                                                onBlur={handleBlur}
                                                                className={classes.inputText}
                                                                InputProps={this?.state?.selectedCompnayName ? { ...params.InputProps,endAdornment: (
                                                                    <InputAdornment position="end" >
                                                                      <IconButton
                                                                        edge="end"
                                                                      >
                                                                        <img src= {this?.state?.selectedCompnayName?.logo ? this?.state?.selectedCompnayName?.logo : userGrey}></img>
                                                                      </IconButton>
                                                                    </InputAdornment>
                                                                  ) } : { ...params.InputProps }}
                                                            />
                                                        )} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        name='location'
                                                        fullWidth
                                                        value={values.location}
                                                        helperText={errors.location && touched.location ? errors.location : ''}
                                                        error={errors.location && touched.location ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label='Location'
                                                        variant='outlined'
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        value={values.role}
                                                        select
                                                        label="Role"
                                                        name='role'
                                                        variant='outlined'
                                                        value={values.role}
                                                        fullWidth
                                                        helperText={errors.role && touched.role ? errors.role : ''}
                                                        error={errors.role && touched.role ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select
                                                    </MenuItem>
                                                        {this.state.rolesArray && this.state.rolesArray.length > 0 &&
                                                            this.state.rolesArray.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth 
                                                        label='Job Type'
                                                        variant='outlined'
                                                        name='jobType'
                                                        value={values.jobType}
                                                        error={errors.jobType && touched.jobType ? true : false}
                                                        helperText={errors.jobType && touched.jobType ? errors.jobType : ''}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} select
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select
                                                        </MenuItem>
                                                        {this.state.jobTypesList && this.state.jobTypesList.length > 0 &&
                                                            this.state.jobTypesList.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl>
                                                            <FormControlLabel
                                                                value="end"
                                                                control={
                                                                    <Checkbox color="primary" name="currentlyWorking" checked={values.currentlyWorking} onChange={handleChange} />
                                                                }
                                                                label="Currently working here"
                                                                labelPlacement="end"
                                                            />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            inputVariant='outlined'
                                                            fullWidth
                                                            label="Joining Date"
                                                            format="yyyy/MM/dd"
                                                            name="joiningDate"
                                                            value={values.joiningDate}
                                                            helperText={touched.joiningDate && errors.joiningDate ? errors.fromYear : ''}
                                                            error={errors.joiningDate && touched.joiningDate ? true : false}
                                                            onChange={(e: any) => {
                                                                setFieldValue("joiningDate", e);
                                                            }}
                                                            disableFuture={true}
                                                            disableToolbar
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    {/* <TextField
                                                        fullWidth
                                                        style={{ width: '100%' }}
                                                        variant='outlined'
                                                        type='date'
                                                        label='Joining Date'
                                                        name='joiningDate'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className='form-control'
                                                        value={values.joiningDate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.joiningDate && touched.joiningDate ? errors.joiningDate : ''}
                                                        error={errors.joiningDate && touched.joiningDate ? true : false}
                                                    /> */}
                                                </Grid>
                                                <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    
                                                        <DatePicker
                                                            fullWidth
                                                            style={{ width: '100%' }}
                                                            inputVariant='outlined'
                                                            label="Leaving Date"
                                                            format="yyyy/MM/dd"
                                                            name="leavingDate"
                                                            value={values.leavingDate}
                                                            helperText={touched.leavingDate && errors.leavingDate ? errors.fromYear : ''}
                                                            error={errors.leavingDate && touched.leavingDate ? true : false}
                                                            onChange={(e: any) => {
                                                                setFieldValue("leavingDate", e);
                                                            }}
                                                            disableFuture={true}
                                                            disableToolbar
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    {/* <TextField
                                                        fullWidth
                                                        style={{ width: '100%' }}
                                                        variant='outlined'
                                                        type='date'
                                                        label='Leaving Date'
                                                        value={values.leavingDate}
                                                        name='leavingDate'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className='form-control'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.leavingDate && touched.leavingDate ? errors.leavingDate : ''}
                                                        error={errors.leavingDate && touched.leavingDate ? true : false}
                                                    /> */}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        value={values.rolesResponsibilites}
                                                        multiline
                                                        rows={2}
                                                        name='rolesResponsibilites'
                                                        variant='outlined'
                                                        label='Roles and Responsibilites'
                                                        error={errors.rolesResponsibilites && touched.rolesResponsibilites ? true : false}
                                                        helperText={errors.rolesResponsibilites && touched.rolesResponsibilites ? errors.rolesResponsibilites : ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4} justify="space-between">
                                                <Grid item xs={4}  >
                                                    <OutlineButton style={{width : '100%',height : '50px'}}  onClick={this.experienceModalClose} >Cancel</OutlineButton>  
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <Button type='submit' className={classes.buttonAdd} >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </div>
            </>
        );
    }
};
const style = (theme) => ({
    buttonAddMore: {
        borderRadius: '6px',
        border: 'solid 1px #110f57',
    },
    inputText: {
        width: '100%',
        height: '10px'
    },
    rolesInput: {
        width: '100%'
    },
    inputDropDown: {
        width: '100%'
    },
    buttonCancel: {
        borderRadius: '6px',
        border: 'solid 1px #110f57',
        width: '50%',
    },
    buttonAdd: {
        borderRadius: '6px',
        border: 'solid 1px #110f57',
        color : '#fff',
        width : '100%',
        height : '50px',
        backgroundImage: 'linear-gradient(104deg, #302d89, #0b65b2 99%)',
        fontFamily : 'ProximaNova-Semibold',
    },
    detailsBox: {
        width: '336px',
        // opacity: '0.5',
        borderRadius: '6px',
        backgroundColor: 'rgb(153,152,205,0.1)',
        color: 'black',
        fontFamily: 'ProximaNova',
        lineHeight: '1.42',
        letterSpacing: '0.1px',
        padding:'10px'
    },
    buttonNext: {
        borderRadius: '6px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: '#ffffff',
        width: '280px'
    },
    timelineRoot: {
        marginLeft: '-48px'
    },
    timelineHead: {
        fontFamily: 'ProximaNova-Semibold',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#110f57',
    },
    timelineSubTitle: {
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#060606',
        fontSize: '12px',
        fontFamily: 'ProximaNova-Regular',
    },
    timelineParagraph: {
        wordBreak: 'break-all',
        color: '#565656',
        fontFamily: 'ProximaNova-Regular',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        fontSize: '12px',
    },
    dialogData:{
        height:'550px'
    },
    dialogHead:{
        height:'65px'
    },
    dialogTitle : {
        fontFamily: "ProximaNova-Bold",
        fontSize: '24px',
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#212121"
    },
    titleRot : {
            margin: 0,
            padding: theme.spacing(2),
            marginBottom : theme.spacing(-5)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      tabHeading : {
        fontFamily: 'ProximaNova-Bold',
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#212121'
      },
      tabSubHeading : {
        fontFamily: 'ProximaNova-Regular',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        lineHeight: 1,
        color: '#424242'
      }
    
})
//style end

const ExperienceWithAlertBox = withAlertBox(Experience);
const ExperienceWithLoader = withLoader(ExperienceWithAlertBox);
const ExperienceWithSnackBar = withSnackBar(ExperienceWithLoader);
export default withStyles(style)(ExperienceWithSnackBar);
