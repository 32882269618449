// @ts-nocheck
import React from "react";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { Checkbox, Button, AppBar, Typography, Toolbar, TextField, withStyles, Grid, Backdrop, DialogContent, Dialog, OutlinedInput } from '@material-ui/core';
import { registrationImage, imgLogo, imgArrow, imgTick } from "./assets";
import { string } from "yup";

//Styles start
const style = {
    title: {
        height: '30px',
        margin: '4px 0 4px 10px',
        fontFamily: 'CircularStd',
        fontSize: '28px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        color: '#323441',
    },
    welcomeText: {
        margin: "104px 1px 15px 50px",
        fontFamily: "ProximaNova",
        fontSize: "40px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: " normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#212121"
    },
    supportText: {
        height: '16px',
        margin: '13px 0px 30px 50px',
        fontFamily: 'ProximaNova',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#424242',
    },
    buttonPhone: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        margin: '0px -38px',
        border: 'solid 1px #e7e4f1',
    },
    buttonEmail: {
        width: '170px',
        height: '54px',
        borderRadius: '6px',
        border: 'solid 1px #e7e4f1',
    },
    inputText: {
        margin: '6px 0 0',
        width: '380px',
        height: '10px',
    },
    buttonLogin: {
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    buttonContinue: {
        margin: '6px 0 0',
        width: '380px',
        height: '40px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white'
    },
    buttonActive: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        margin: '0px -38px',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    buttonActiveEmail: {
        color: 'blue',
        opacity: '1',
        borderRadius: '6px',
        backgroundColor: '#e7e7ee',
        width: '170px',
        height: '54px',
        border: 'solid 1px #e7e4f1',
        fontWeight: 'bold',
        fontFamily: 'CircularStd',
    },
    errorMsg: {
        color: 'red',
        padding: '3px 1px 2px 6px'
    },
    btnDisable: {
        disable: true
    },
    backdrop: {
        zIndex: 1,
        color: '#fff'
    },
    dialog: {
        width: '420px',
        height: '250px',
        borderRadius: '8px',

    },
    buttonGotIt: {
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: 'white',
        margin: '1px 72px 35px',
        width: '310px',
        height: '30px'
    },
    buttonTick: {
        margin: '17px 180px',
    },
    buttonTickSupportText: {
        margin: '68px 1px',
        position: 'absolute',
        textAlign: 'center'
    },
    buttonTickTitle: {
        margin: '15px 86px',
        position: 'absolute',
        fontweight: 'bold',
        fontFamily: 'ProximaNova'
    }
} as const;
//Styles end


class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    emailHandler = () => {
        this.setState({ msg: '' });
        this.setState({ phoneCheck: false });
        this.setState({ phone: '' });
    }
    phoneHandler = () => {
        this.setState({ msg: '' });
        this.setState({ email: '' });
        this.setState({ password: '' });
        this.setState({ confirmPassword: '' });
        this.setState({ phoneCheck: true });
        this.setState({ continueDisable: true });
    };
    continueHandler = (e: any) => {
        const email = this.state.email;
        const password = this.state.password;
        if (email && password) {
            if (!this.state.phoneCheck) this.setState({ userCheck: true });
        }
    };
    emailOnChange = (e: any) => {
        console.log("helloWelcome", this.state.email);
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const result = emailReg.test(String(e.target.value).toLowerCase());
        this.setState({ dataCheck: !result });
        if (!result) {
            this.setState({ continueDisable: true })
            this.setState({ msg: 'Incorrect Email Format' });
        } else {
            this.setState({ msg: '' });
            this.setState({ email: e.target.value });
            this.setState({ continueDisable: false });
        }
    };
    passwordChange = (e: any) => {
        const strongPwd = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const result = strongPwd.test(e.target.value);
        this.setState({ dataCheck: !result });
        if (!result) {
            this.setState({ continueDisable: true });
            this.setState({ msg: `Password should be atleast 8 charcters with 1 uppercase,numeric and a special character` })
        } else {
            this.setState({ msg: '' });
            this.setState({ password: e.target.value });
            this.setState({ continueDisable: false });
        }
    };
    passwordCheck = (e: any) => {
        const cpwd = e.target.value;
        const pwd = this.state.password;
        if (pwd && cpwd) {
            if (cpwd == pwd) {
                this.setState({ confirmPassword: cpwd });
                this.setState({ continueDisable: false });
                this.setState({
                    msg: ''
                });
            }
            else {
                console.log(pwd, cpwd, pwd == cpwd);
                this.setState({ continueDisable: true });
                this.setState({
                    msg: 'Password and Confirm Password Does not match'
                });
            }
        }
    };
    phoneChange = (e: any) => {
        const rex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
        const result = rex.test(e.target.value);
        this.setState({ dataCheck: !result });
        if (e.target.value.length > 10) {
            this.setState({ continueDisable: true })
            this.setState({ msg: 'Phone Length should be 10 Digits only' });
        } else {
            this.setState({ msg: '' });
            this.setState({ continueDisable: false });
            this.setState({ phone: e.target.value });
        }
    };
    modalClose = () => {
        this.setState({ msg: '' });
        this.setState({ userCheck: false });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <AppBar>
                    <Toolbar style={{ backgroundColor: "white" }}>
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={imgLogo} alt="" height="40px" width="136px"
                                />
                            </Grid>
                            <Grid item xs={7}></Grid>
                            <Grid item xs={2} >
                                <Button className={classes.buttonLogin} style={{ float: "right" }}>
                                    Login &nbsp; <img src={imgArrow} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Grid container >
                    <Grid item xs={4}>
                        <Typography className={classes.welcomeText}>
                            Welcome to hubura !
                        </Typography>
                        <Typography className={classes.supportText}>
                            Provide a few details to complete your registration.
                        </Typography>
                        <Grid container>
                            <Grid item xs={2} />
                            <Grid item xs={4}>
                                <Button className={this.state.phoneCheck ? classes.buttonActive : classes.buttonPhone} onClick={this.phoneHandler}>
                                    Via Phone
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button className={this.state.phoneCheck ? classes.buttonEmail : classes.buttonActiveEmail} onClick={this.emailHandler}>
                                    Via Email
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        {!this.state.phoneCheck ?
                            <div style={{ margin: "8px" }}>
                                <Grid container>
                                    <Grid item xs={1} />
                                    <Grid item xs={10}>
                                        <TextField
                                            id="outlined-textarea"
                                            label="Email ID"
                                            variant="outlined"
                                            autoFocus={true}
                                            value={this.state.email}
                                            className={classes.inputText}
                                            onChange={(e) => this.emailOnChange(e)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1} >
                                    </Grid>
                                </Grid>
                                <br /><br /><br />
                                <Grid container>
                                    <Grid item xs={1} />
                                    <Grid item xs={10}>
                                        <TextField
                                            id="filled-password-input"
                                            label="Password"
                                            variant="outlined"
                                            type="password"
                                            value={this.state.password}
                                            className={classes.inputText}
                                            onChange={e => this.passwordChange(e)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <br /><br /><br />
                                <Grid container>
                                    <Grid item xs={1} />
                                    <Grid item xs={10}>
                                        <TextField
                                            id="outlined-textarea"
                                            label="Confirm Password"
                                            variant="outlined"
                                            type="password"
                                            className={classes.inputText}
                                            value={this.state.confirmPassword}
                                            onChange={(e) => this.passwordCheck(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <br /><br /><br />
                            </div>
                            :
                            <Grid container>
                                <Grid item xs={2} />
                                <Grid item xs={1} >
                                    <TextField
                                        id="outlined-codes"
                                        select
                                        label="Code"
                                        style={{ margin: "0px -40px" }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                    >
                                        {this.state.codes && this.state.codes ? this.state.codes.map((option: any, index: number) => (
                                            <option key={index} value={option.value} >
                                                {option}
                                            </option>
                                        )) : 0}
                                    </TextField>
                                </Grid>
                                <Grid item xs={9} >
                                    <TextField
                                        id="outlined-textarea"
                                        label="Phone"
                                        autoFocus={true}
                                        value={this.state.phone}
                                        variant="outlined"
                                        style={{ margin: "0px 40px", width: "262px" }}
                                        onChange={(e) => this.phoneChange(e)}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <span>
                                    <Checkbox style={{ color: '#110F57 ' }} />
                                        I agree to the TnC and Privacy Policy
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <Button className={classes.buttonContinue} onClick={this.continueHandler} disabled={this.state.continueDisable || this.state.msg != ''}>
                                    Continue &nbsp; <img src={imgArrow} />
                                </Button>
                            </Grid>
                            <Grid item xs={1} >
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                {this.state.dataCheck || this.state.password ? <p className={classes.errorMsg}>{this.state.msg}</p> : ''}
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={7}>
                        <img src={registrationImage} alt="" height="400px" width="700px" style={{
                            margin: '90px 49.2px 21px 150px'
                        }}
                        />
                    </Grid>
                </Grid>
                <div>
                    <Backdrop open={!!this.state.userCheck} className={classes.backdrop}>
                        <Dialog open={!!this.state.userCheck} onClose={this.modalClose}>
                            <DialogContent className={classes.dialog}>
                                <div style={{ alignContent: 'center' }}>
                                    <img src={imgTick} className={classes.buttonTick} />
                                    <h3 className={classes.buttonTickTitle}>Confirmation Link Sent</h3>
                                    <p className={classes.buttonTickSupportText}>
                                        We have sent a link on your mail to confirm your email id, Click on for the same.                                </p>
                                </div>
                            </DialogContent>
                            <Button className={classes.buttonGotIt}>
                                Got It
                            </Button>
                        </Dialog>
                    </Backdrop>
                </div>
            </div >
        );
    }
}

export default (withStyles)(style)(EmailAccountRegistration);


