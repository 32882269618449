import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import { string } from "yup";
export const configJSON = require("./config");

export interface profileDetail {
  id?: number;
  name: string;
  profession: string;
  company_name: string;
  email: string;
  gender: string;
  address: string;
  bio: string;
  account_id?: number;
  avatar: null | string
}


export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    openDialog: any;
    tabChange: Function;
    makeTabCompleted: Function;
    // Customizable Area Start
    // Customizable Area End
  };

export interface S {
  // Customizable Area Start
  skills: Array<any>;
  moreMenu: any;
  addSkillModalopen: boolean;
  addExperience: boolean;
  company: string;
  jobType: string;
  location: string;
  role: string;
  joiningDate: string;
  leavingDate: string;
  rolesResponsibilites: string;
  rolesArray: Array<string>;

  // basic info state
  value: number;
  gender: string;
  isBasicInfoCompleted: boolean;
  profileImgBase64: any;

  //qualification state
  addQualificationCheck: boolean;
  years?: any[],
  colors?: any[],
  grades?: any[],
  fromYear?: string,
  toYear?: string,
  errmsg?: string
  isQualificationComplte: boolean;
  selectedQualificationList: Array<any>
  noQuaificationErrMsg: string;

  // skills state
  isSkillsCompleted: boolean;
  skillList: Array<any>;
  selectedSkills: Array<any>;

  isExperienceCompleted: boolean;
  isPreferredJobCompleted: boolean;

  currentlyWorking?: boolean;
  userData?: any[];
  activeStep?: number;
  //preferred job state


  religionList: Array<string>;
  castList: Array<string>;
  birthPlaceList: Array<string>;
  livingCriteriaList: Array<string>;
  livingWithList: Array<string>
  authToken: string;
  profileDetail: profileDetail | null;
  accountDetail: any;

  selectedjobList: Array<string>;
  selectedLocationList: Array<string>;
  selectedJobType: Array<string>;
  noticePeriod: boolean;
  ratePerHour: string;
  avaialbleAsFreeLancer: boolean;
  haveVisa: boolean;
  preferenceJobId: number | null,

  additional_info: any | null,
  selectedExperienceList: Array<any>,
  SelectedCertificateList: Array<any>;
  skill_sets: any | null,
  selectedVisa: any[]
  jobTypesList: Array<string>;
  companyListData?: any;
  finalCompanydata?: any;
  selectedCompnayName?: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
  > {
  basicInfoApiCallId: any;
  getProfileAPICallId: any;
  getbasicInfoApiCallId: any;
  // qualification
  saveQualificationApiCallId: any;
  getQualificationApiCallId: any;
  editQualificationApiCallId: any;
  // preferred jobs
  getPreferdJobsApiCallId: any;
  savePreferredJobApiCallId: any;
  // additonal jobs
  getAdditionalInfoApiCallId: any;
  saveAdditionalInfoApiCallId: any;

  //exprience
  companyListApiCallId: any;
  experienceApiCallID: any;
  getExprienceApiCallId: any;
  editExprienceApiCallId: any;
  showError: boolean | null;

  // skills 
  saveSkillsApiCallId: any;
  updateSkillsApiCallId: any;
  getskillsApiCallId: any;
  getskillApiCallId: any;
  deleteskillsApiCallId: any
  saveskillApiCallId: any
  updateskillApiCallId: any
  searchCompnayApiCallID: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)

    ];
    let data: any = {}
    this.state = {
      value: 0,
      gender: "male",
      // qualification state
      addQualificationCheck: false,
      selectedQualificationList: [],
      noQuaificationErrMsg: "",
      profileImgBase64: "",

      addExperience: false,
      skills: [],
      moreMenu: null,
      addSkillModalopen: false,
      grades: ['A+', 'A', 'B', 'C', 'D'],
      colors: ['#00a4ef', '#f25022', '#ffb900', '#008B8B', '#DB7093', '#B22222', '#8FBC8F'],
      rolesArray: ['UI/UX Desginer', 'Business Analyst', 'Software Engineer', 'Software Developer', 'Testing', 'Automation Testing'],
      company: "",
      jobType: "",
      location: "",
      role: "",
      joiningDate: "",
      leavingDate: "",
      rolesResponsibilites: "",

      religionList: [],
      birthPlaceList: [],
      castList: [],
      livingCriteriaList: [],
      livingWithList: [],

      isBasicInfoCompleted: false,
      isExperienceCompleted: false,
      isPreferredJobCompleted: false,
      isQualificationComplte: false,
      isSkillsCompleted: false,
      // skills state
      skillList: [],
      selectedSkills: [],
      ///preferred job
      selectedjobList: [],
      selectedLocationList: [],
      selectedJobType: [],
      noticePeriod: false,
      ratePerHour: '',
      avaialbleAsFreeLancer: false,
      haveVisa: false,
      authToken: '',
      profileDetail: null,
      accountDetail: null,
      preferenceJobId: null,
      additional_info: null,
      selectedExperienceList: [],
      SelectedCertificateList: [],
      skill_sets: null,
      selectedVisa: [],
      jobTypesList: ['Full Time', 'Part Time', 'Freelancer'],
    }
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  ///skills and certificate events 
  getSkillList = () => {
    const skills = [
      { title: 'Algorithms' },
      { title: 'Analytical Skills' },
      { title: 'Big Data' },
      { title: 'Calculating' },
      { title: 'Compiling Statistics' },
    ];
    this.setState({
      ...this.state,
      skillList: skills,
    });;
  }

  // Qualification events

  
  cancelChangeHandler = () => {
    this.setState({
      ...this.state,
      company: '',
      location: '',
      jobType: '',
      role: '',
      joiningDate: '',
      leavingDate: '',
      currentlyWorking: false,
      rolesResponsibilites: '',
      activeStep: this.state.userData?.length
    })
  }

  ///aditional info events
  getReligionList = () => {
    const list = ["Hindu", "Buddhism", " Sikhism"];
    this.setState({
      ...this.state,
      religionList: list,
    })
  }
  getCastList = () => {
    const list = ["Patel", "Bramhin", " Jain"];
    this.setState({
      ...this.state,
      castList: list,
    })
  }
  getLivingCriteriaList = () => {
    const list = ["Own House", "Payin Guest", " Rent"];
    this.setState({
      ...this.state,
      livingCriteriaList: list,
    })
  }
  getLivingWithList = () => {
    const list = ["Family", "Friends",];
    this.setState({
      ...this.state,
      livingWithList: list,
    })
  }
  getBirthPlaceList = () => {
    const list = ["Rajkot", "Ahmedabad", "Jaipur"];
    this.setState({
      ...this.state,
      birthPlaceList: list,
    })
  }

  async receive(from: String, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.basicInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson?.account_profile?.id) {
          this.setState({ value: 1 }, () => {
            this.props.showToast({ type: 'success', message: 'Profile is saved successfully' })
          })
          this.props.tabChange(1);
          this.props.makeTabCompleted("profileDetail", responseJson?.account_profile)
          //  this.forceUpdate();

        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getbasicInfoApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.account_profiles?.id) {
          this.setState({
            profileDetail: responseJson?.account_profiles
          })
        } else {
          this.showError = null;
          this.setState({
            profileDetail: null
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getQualificationApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.academic_qualification) {
          this.setState({
            selectedQualificationList: responseJson?.academic_qualification
          })
        } else {
          this.showError = null;
          this.setState({
            selectedQualificationList: []
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.saveQualificationApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.qualification.length > 0) {
          this.props.tabChange(2);
          this.props.makeTabCompleted("selectedQualificationList", responseJson?.qualification)
          this.setState({ ...this.state, addQualificationCheck: false }, () => {
          })
          this.props.showToast({ type: 'success', message: 'User Qualification is saved successfully' })
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.editQualificationApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.academic_qualification) {
          this.props.tabChange(2);
          this.setState({ ...this.state, addQualificationCheck: false }, () => {
            this.getQualificationList();
          })
          this.props.showToast({ type: 'success', message: 'User Qualification is updated successfully' })
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getPreferdJobsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.preferred_jobs) {
          this.setState({
            selectedjobList: responseJson?.preferred_jobs[0]?.job_name ? responseJson?.preferred_jobs[0]?.job_name.split(",") : [] || [],
            selectedLocationList: responseJson?.preferred_jobs[0].location ? responseJson?.preferred_jobs[0].location.split(",") : [] || [],
            selectedJobType: responseJson?.preferred_jobs[0].job_type ? responseJson?.preferred_jobs[0].job_type.split(",") : [] || [],
            noticePeriod: responseJson?.preferred_jobs[0].notice_period,
            preferenceJobId: responseJson?.preferred_jobs[0].id,
            selectedVisa: responseJson?.preferred_jobs[0].visa_type ? JSON.parse(responseJson?.preferred_jobs[0].visa_type) : [],
            haveVisa: responseJson?.preferred_jobs[0].is_foreign_visa_available ? true : false,
            avaialbleAsFreeLancer: responseJson?.preferred_jobs[0].available_as_freelancer ? true : false,
            ratePerHour: responseJson?.preferred_jobs[0].rate_per_hour,
          })
        } else if (responseJson.message) {
          this.showError = null;
          this.setState({
            selectedjobList: [],
            selectedLocationList: [],
            selectedJobType: [],
            noticePeriod: false,
            preferenceJobId: null,
            haveVisa: false,
            selectedVisa: []
            // avaialbleAsFreeLancer : responseJson?.preferred_jobs[0].is_foreign_visa_available,
            // ratePerHour : "",

          })
          // this.props.showAlert({title : 'System Message !',message : responseJson.message});  
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.savePreferredJobApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.preferred_job) {
          this.props.tabChange(5);
          this.props.makeTabCompleted("selectedjobList", [responseJson.preferred_job])

          this.props.showToast({ type: 'success', message: 'Additional Info is saved successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          this.props.showAlert({ title: 'System Message !', message: responseJson.message });
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getAdditionalInfoApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.additional_info) {
          this.setState({ additional_info: responseJson.additional_info })
        } else {
          this.showError = null;
          this.setState({ additional_info: {} })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.saveAdditionalInfoApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.additional_info) {
          this.props.showToast({ type: 'success', message: 'Additional Info is saved successfully' })
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getExprienceApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.experiences) {
          this.setState({ selectedExperienceList: responseJson.experiences })
        } else if (responseJson.message) {
          this.showError = null;
          this.setState({ selectedExperienceList: [] })
          // this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = null;
          this.setState({ selectedExperienceList: [] })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.experienceApiCallID === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.experience) {
          // this.getExprience();
          this.props.tabChange(4);
          this.props.makeTabCompleted("selectedExperienceList", responseJson.experience)
          this.props.showToast({ type: 'success', message: 'Exprience is saved successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          // this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = true
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.editExprienceApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        // this.props.tabChange(4);
        if (responseJson.experience) {
          // this.getExprience();
          this.props.showToast({ type: 'success', message: 'Exprience is updated successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          this.props.showAlert({ title: 'System Message !', message: responseJson.message });
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }

      } else if (this.saveSkillsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.certifications) {
          this.props.tabChange(3);
          this.props.makeTabCompleted("SelectedCertificateList", responseJson.certifications)
          // this.getSkillsAndCertificate();
          this.props.showToast({ type: 'success', message: 'Skill and Certificates are saved successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          // this.props.showAlert({title : 'System Message !',message : responseJson.message});  
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }

      } else if (this.updateSkillsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.certification) {
          this.props.tabChange(3);
          this.getSkillsAndCertificate();
          this.props.showToast({ type: 'success', message: 'Skill and Certificate is updated successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          //this.props.showAlert({title :'System Message !',message : responseJson.message});  
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getskillsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.certifications) {
          // this.props.tabChange(3);
          // this.getSkills();
          this.setState({ SelectedCertificateList: responseJson.certifications })
        } else if (responseJson.message) {
          this.showError = null;
          this.setState({ skill_sets: null, SelectedCertificateList: [] })
          //this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = null;
          this.setState({ SelectedCertificateList: [] })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.deleteskillsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.code === 200) {
          this.getSkillsAndCertificate();
          this.props.showToast({ type: 'success', message: 'Skill and Certificate is deleted successfully' })
        } else if (responseJson.message) {
          this.showError = true;
          this.props.showAlert({ title: 'System Message !', message: responseJson.message });
        } else {
          this.showError = true;
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getskillApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.skill_sets) {
          this.setState({ skill_sets: responseJson.skill_sets, selectedSkills: responseJson.skill_sets?.name?.split(",") })
        } else if (responseJson.message) {
          this.showError = null;
          this.setState({ skill_sets: null, selectedSkills: [] })
          //this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = null;
          this.setState({ skill_sets: null, selectedSkills: [] })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.saveskillApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.skill_set) {
          this.props.tabChange(3);
          this.props.makeTabCompleted("SelectedCertificateList", [responseJson.skill_set])
          this.props.showToast({ type: 'success', message: 'Skill and Certificate is saved successfully' })
          // this.setState({skill_sets : responseJson.skill_sets,selectedSkills: responseJson.skill_sets?.name?.split(",")})
        } else if (responseJson.message) {
          this.showError = true;
          // this.setState({skill_sets : null,selectedSkills : []})
          //this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = true;
          // this.setState({skill_sets : null,selectedSkills : []})
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.updateskillApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.skill_set) {
          this.props.tabChange(3);
          this.props.makeTabCompleted("SelectedCertificateList", [responseJson.skill_set])
          this.props.showToast({ type: 'success', message: 'Skill and Certificate is updated successfully' })

          // this.setState({skill_sets : responseJson.skill_sets,selectedSkills: responseJson.skill_sets?.name?.split(",")})
        } else if (responseJson.message) {
          this.showError = true;
          // this.setState({skill_sets : null,selectedSkills : []})
          //this.props.showAlert({title : 'Error',message : responseJson.message});  
        } else {
          this.showError = true;
          // this.setState({skill_sets : null,selectedSkills : []})
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.searchCompnayApiCallID === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson) {
          if (responseJson.company_info) {
            this.setState({
              ...this.state,
              companyListData: responseJson.company_info
            });
          } else {
            this.showError = null;
            this.parseApiErrorResponse(responseJson);
          }
        }
        else {
          this.showError = null;
          this.parseApiCatchErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      // if(this.getskillsApiCallId || this.getExprienceApiCallId || this.getAdditionalInfoApiCallId || this.getPreferdJobsApiCallId || this.getQualificationApiCallId || this.getbasicInfoApiCallId)
      if (this.showError)
        this.props.showAlert({ title: 'Error', message: AlertBodyMessage });
    }
  };

  /** BAISC INFO API's Start */

  //create baisc info 
  saveBasicInformation = (values: profileDetail) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.basicInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.profileDetail?.id ? configJSON.apiEndPointSaveBasicInfo + "/" + this.state.profileDetail?.id : configJSON.apiEndPointSaveBasicInfo
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    const formData = new FormData();

    formData.append("profile[name]", values.name)
    formData.append("profile[company_name]", values.company_name)
    formData.append("profile[profession]", values.profession)
    formData.append("profile[email]", values.email);
    formData.append("profile[gender]", values.gender);
    formData.append("profile[full_address]", values.address)
    formData.append("profile[bio]", values.bio);
    formData.append("profile[avatar]", this.state.profileImgBase64);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.profileDetail?.id ? 'PUT' : configJSON.apiSaveBasicInfoType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // get profile call start
  getProfileInfo = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.props.showLoader();
    this.getbasicInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetProfile
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetProfileType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /** BAISC INFO API's End */



  /** QUALIFICATION API's start */

  //get Qualification 
  getQualificationList = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getQualificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAddQualification
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetProfileType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //update Qualification
  editQualificationInDB = (values: any, id?: number) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.editQualificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointSaveQualification + "/" + id
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    let formdata = new FormData();
    if (id) {
      formdata.append(`academic_qualification[start_year]`, values.fromYear);
      formdata.append(`academic_qualification[end_year]`, values.toYear);
      formdata.append(`academic_qualification[degree]`, values.degree);
      formdata.append(`academic_qualification[institute]`, values.institute);
      formdata.append(`academic_qualification[specialization]`, values.specialization);
      formdata.append(`academic_qualification[grade]`, values.grades);
      formdata.append(`academic_qualification[account_id]`, this.state.accountDetail?.account_id);
      formdata.append(`academic_qualification[location]`, values.location);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //create Qualification
  saveQualification = () => {
    const abcd = this.state.selectedQualificationList.filter((ab: any) => { return !ab.id })
    if (abcd.length > 0) {
      this.props.showLoader();
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.saveQualificationApiCallId = requestMessage.messageId;
      const header = {
        "platform": "web",
        "auth-token": this.state.authToken
      };
      const formData = new FormData();
      abcd.map((expre: any, index: any) => {
        formData.append(`academic_qualification[${index}][start_year]`, expre.start_year);
        formData.append(`academic_qualification[${index}][end_year]`, expre.end_year);
        formData.append(`academic_qualification[${index}][degree]`, expre.degree);
        formData.append(`academic_qualification[${index}][specialization]`, expre.specialization);
        formData.append(`academic_qualification[${index}][institute]`, expre.institute);
        formData.append(`academic_qualification[${index}][grade]`, expre.grade);
        formData.append(`academic_qualification[${index}][account_id]`, this.state.accountDetail?.account_id);
        formData.append(`academic_qualification[${index}][location]`, expre.location);
      });

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEndPointSaveQualification);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else {
      if (this.state.selectedQualificationList.length > 0) {
        this.props.tabChange(2);
      } else {
        this.props.showAlert({ title: 'System Message !', message: "Add at leaset one Qualification to proceed further." });
      }

    }

  };
  /** QUALIFICATION API's End */



  /** PREFEERJOBS API's Start */

  //get PrefferJob
  getPreferJobjs = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getPreferdJobsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointPreferredJobs
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetProfileType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //create PrefferJob
  savePreferredJob = () => {
    if (this.state.selectedjobList.length > 0 || this.state.selectedLocationList.length > 0 || this.state.selectedJobType) {
      this.props.showLoader();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.savePreferredJobApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.preferenceJobId ? configJSON.apiEndPointPreferredJobs + "/" + this.state.preferenceJobId : configJSON.apiEndPointPreferredJobs
      );
      const header = {
        "auth-token": this.state.authToken,
        "platform": "web"
      };

      let formdata = new FormData();

      formdata.append(`preferred_job[job_name]`, this.state.selectedjobList.join());
      formdata.append(`preferred_job[skills]`, this.state.selectedjobList.join());
      formdata.append(`preferred_job[location]`, this.state.selectedLocationList.join());
      formdata.append(`preferred_job[job_type]`, this.state.selectedJobType.join());
      formdata.append(`preferred_job[notice_period]`, this.state.noticePeriod.toString());
      formdata.append(`preferred_job[account_id]`, this.state.accountDetail?.account_id);
      formdata.append(`preferred_job[visa_type]`, JSON.stringify(this.state.selectedVisa));
      formdata.append(`preferred_job[is_foreign_visa_available]`, this.state.haveVisa + "");

      formdata.append(`preferred_job[available_as_freelancer]`, this.state.avaialbleAsFreeLancer + "");
      formdata.append(`preferred_job[rate_per_hour]`, this.state.ratePerHour + "");



      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.preferenceJobId ? 'PUT' : 'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  /** PREFEERJOBS API's End */


  /** ADDITIONAL INFO API's start */

  //get AdditionalInfo
  getAdditionalInfo = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getAdditionalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAdditionalInfo
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //create AdditionalInfo
  saveAdditionalInfo = (values: any) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.saveAdditionalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.additional_info.id ? configJSON.apiEndPointAdditionalInfo + "/" + this.state.additional_info.id : configJSON.apiEndPointAdditionalInfo
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    let formdata = new FormData();

    formdata.append(`additional_info[religion]`, values.religion);
    formdata.append(`additional_info[caste]`, values.caste);
    formdata.append(`additional_info[birth_place]`, values.birthPlace);
    formdata.append(`additional_info[living_with]`, values.livingWith);
    formdata.append(`additional_info[living_in]`, values.livingIn);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.additional_info.id ? configJSON.methodPUT : configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /** ADDITIONAL INFO API's End */


  /** EXPERIENCE API's Start */

  //get ComapnyList
  getCompanyList = (data: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.companyListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEndPointGetCompanyList);

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCompanyList,
      "platform": "web",
      "auth-token": this.state.authToken
    };

    const formData = new FormData();
    formData.append('phone_number', '919826123272');

    const httpBody = {
      term: data
    }

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetCompnayListType);

    console.log(requestMessage.messageId, "dgfdtgdjhfgdbjhf", requestMessage);

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  };
  //get Experiences
  getExprience = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getExprienceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetExperience
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //update Experience
  editExprienceInDB = (values: any, id: number) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.editExprienceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetExperience + "/" + id
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    let formData = new FormData();
    if (id) {
      formData.append(`experience[compnay_name]`, values.company_name);
      formData.append(`experience[position]`, values.position);
      formData.append(`experience[location]`, values.location);
      formData.append(`experience[job_type]`, values.job_type);
      formData.append(`experience[joining_date]`, values.joining_date);
      formData.append(`experience[leaving_date]`, values.leaving_date);
      formData.append(`experience[currently_working]`, values.currentlyWorking + '');
      formData.append(`experience[bio]`, values.responsibilities);
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  //save expeience
  saveExperienceInfro = () => {
    const abcd = this.state.selectedExperienceList.filter((ab: any) => { return !ab.id })
    if (abcd.length > 0) {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.experienceApiCallID = requestMessage.messageId;

      const header = {
        "platform": "web",
        "auth-token": this.state.authToken
      };

      const formData = new FormData();
      abcd.map((expre: any, index: any) => {
        formData.append(`experience[${index}][compnay_name]`, expre.company_name);
        formData.append(`experience[${index}][position]`, expre.position);
        formData.append(`experience[${index}][location]`, expre.location);
        formData.append(`experience[${index}][job_type]`, expre.job_type);
        formData.append(`experience[${index}][joining_date]`, expre.joining_date);
        formData.append(`experience[${index}][leaving_date]`, expre.leaving_date);
        formData.append(`experience[${index}][currently_working]`, expre.currentlyWorking + '');
        formData.append(`experience[${index}][bio]`, expre.responsibilities);

      });

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEndPointGetExperience);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else {
      if (this.state.selectedExperienceList.length > 0) {
        this.props.tabChange(4);
      } else {
        this.props.showAlert({ title: 'System Message !', message: "Add at leaset one Qualification to proceed further." });
      }
    }
  };
  /** EXPERIENCE API's End */


  /** SKILLS AND CERTIFICATIONS API's Start */

  //create Skills and Certifications
  saveSkills = () => {
    const abcd = this.state.SelectedCertificateList.filter((ab: any) => { return !ab.id })
    if (abcd.length > 0) {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.saveSkillsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointGetSkills
      );
      const header = {
        "auth-token": this.state.authToken,
        "platform": "web"
      };

      let formdata = new FormData();
      // formdata.append(`skill_set[name]`, this.state.selectedSkills.join());
      abcd.map((data: any, index: number) => {
        formdata.append(`certification[${index}][name]`, data.name);
        formdata.append(`certification[${index}][date]`, data.date);
        formdata.append(`certification[${index}][organization_name]`, data.organization_name);
        formdata.append(`certification[${index}][certificate_upload]`, data.document);
      });
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else {
      if (this.state.SelectedCertificateList.length > 0) {
        this.props.tabChange(3);
      } else {
        //  this.props.showAlert({title : 'System Message !',message : "Add at leaset one Skill Set is Rquired to proceed further."});
      }
    }
  };
  //delete Skills and Certifications
  deleteSkills = (data: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.deleteskillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetSkills + "/" + data.id
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //Update Skills and Certifications
  updateSkills = (data: any, idData: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateSkillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetSkills + "/" + idData.id
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };

    let formdata = new FormData();
    // formdata.append(`skill_set[name]`, this.state.selectedSkills.join());
    formdata.append(`certification[name]`, data.name);
    formdata.append(`certification[date]`, data.date);
    formdata.append(`certification[organization_name]`, data.organization_name);
    formdata.append(`certification[certificate_upload]`, data.document);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //get Skills and Certifications
  getSkillsAndCertificate() {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getskillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetSkills
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //get Skills
  getSkills = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getskillApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apigetskills
    );
    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //save Skills
  saveSkill = () => {
    if (this.state.selectedSkills.length > 0) {
      this.props.showLoader();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.saveskillApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apigetskills
      );
      const header = {
        "auth-token": this.state.authToken,
        "platform": "web"
      };
      let formdata = new FormData();
      formdata.append(`skill_set[name]`, this.state.selectedSkills.join());

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  //update Skills and Certifications
  updateSkill = () => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateskillApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apigetskills + "/" + this.state.skill_sets.id
    );
    let formdata = new FormData();
    formdata.append(`skill_set[name]`, this.state.selectedSkills.join());

    const header = {
      "auth-token": this.state.authToken,
      "platform": "web"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /** SKILLS AND CERTIFICATION API's End */

  /** SUPPORT API's start */
  searchCompanyName = (values: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const headers = {
      'auth-token': this.state.authToken,
      'platform': 'web'
    };

    this.searchCompnayApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.apiEndPointSearchCompanyName}?term=${values}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.methodGET);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /** SUPPORT API's End */
}

