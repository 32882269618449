import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";



export const configJSON = require("./config");
export type Props = RouterProps &
   {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  jobList: any[];
  companyList: any[];
  location: string;
  locationList: any[];
}

interface SS {}

export default class ActivityFeedController extends BlockComponent<
    Props,
    S,
    SS
    > {
    
    jobApiCallId: any;
    companyApiCallId: any;
    constructor(props: Props) {
        super(props);
        

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
   
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      jobList: [],
      companyList: [],
      location: "location",
      locationList:[]
    };
    }
     getJobList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.jobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetJobList
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetJobList
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetJobListType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
     };
       
    getCompanyList = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.companyApiCallId = requestMessage.messageId;
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointGetCompanyList
        );

        const header = {
        "Content-Type": configJSON.contentTypeApiGetCompanyList
        };

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetCompanyListType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
  };
    
    async receive(from: String, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.jobApiCallId != null &&
      this.jobApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      //
      if (responseJson && !responseJson.errors) {
        this.setState({
          jobList:responseJson.professional_jobs
        });
       
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
   else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.companyApiCallId != null &&
      this.companyApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      
      if (responseJson && !responseJson.errors) {
     
        this.setState({
          companyList: responseJson?.company_ratings || []
        });
       
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    }
  getLocationList = () => {
    const list = ["Gujarat", "Andhra Pradesh", "Bihar", "Manipur", "Sikkim"];
    this.setState({
      locationList:list
    })
  }
  handleChange = (e:any) => {
    this.setState({
      location: e.target.value,
    })
  }
}