//@ts-nocheck
import React from "react";
import { imgArrow, imgLogo, imgSide, imgRightTick, loginSideImg } from "./assets";
import PhoneLoginController, { Props } from "./PhoneLoginController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Checkbox,
  createStyles,
  Link
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/src/CustomButton.web";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withLoader from "../../../components/src/withLoader.Web";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withSnackBar from "../../../components/src/withSnackBar.Web";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    width: "170px",
    height: "54px",
    margin: "0px 20px 0px 0px",
    color: "#000000",
    borderRadius: "6px",
    "&:not(:first-child)": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
    "&:first-child": {
      border: "1px solid lightgrey",
      borderRadius: "5px",
    },
  },
}))(ToggleButtonGroup);
const StyleToggleButton = withStyles({
  selected: {
    "&.Mui-selected": {
      color: "#110f57 !important",
      fontWeight: 600,
    },
  },
  label: {
    textTransform: "capitalize",
    fontFamily:'ProximaNova-Regular',
    fontSize : '16px'
  },
})(ToggleButton);

class PhoneAccountLogin extends PhoneLoginController {
  constructor(props: Props) {
    super(props);
    this.state = {
      msg: "",
      loginDisabled: true,
      phone: "",
    };
  };

  //validation start
  loginSchema = Yup.object().shape({
    loginType: Yup.string().required("this field is reqired"),
    phone: Yup.string().when("loginType", {
      is: (loginType) => loginType === "phone",
      then: Yup.string()
        .min(10, "Enter 10 digit phone no.")
        .max(10, "Enter 10 digit phone no.")
        .required("This field is required"),
    }),
    email: Yup.string().when("loginType", {
      is: (loginType) => loginType === "email",
      then: Yup.string()
        .email("Email must be in valid format!")
        .required("This field is required."),
    }),
    password: Yup.string().when("loginType", {
      is: (loginType) => loginType === "email",
      then: Yup.string()
        .min(8, "Enter atleast 8 digit Password")
        .required("This field is required"),
    }),
  });
  //validations end

  handleClose = () => {
    this.setState({ isEmailSend: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid xs={12} item>
          <div className={classes.appbarroot}>
            <AppBar position="static">
              <Toolbar style={{ backgroundColor: "#fff" }}>
                <img src={imgLogo} alt="" />
                <div className={classes.appbartitle} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.push("signUp");
                  }}
                  className={classes.buttonSignUP}
                >
                  Sign Up &nbsp;<img src={imgArrow} />
                </Button>
              </Toolbar>
            </AppBar>
          </div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            item
            xs={12}
            spacing={2}
            className={classes.root}
          >
            <Grid item container sm={6} md={6} xs={12}>
              <Formik
                initialValues={{
                  phone: "",
                  email: "",
                  password: "",
                  loginType: "email",
                }}
                validationSchema={this.loginSchema}
                onSubmit={(values, actions) => {
                  this.handleLogin(values);
                  // actions.setSubmitting(false);
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  } = formikProps;
                  return (
                    <Form>
                      <Grid container direction="column" spacing={1}>
                          <Grid item item xs={12}>
                            <div variant="h4" className={classes.headingTitle}>
                              Welcome back to hubura !
                          </div>
                          <Typography variant="subtitle1">
                              Login with your credentials
                          </Typography>
                          </Grid>
                          {/* <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Login with your credentials
                          </Typography>
                          </Grid> */}
                        <Grid item xs={12}>
                          <StyledToggleButtonGroup
                            name="loginType"
                            value={values.loginType}
                            className={classes.toggleBtn}
                            exclusive
                            onChange={(e) => {
                              setFieldValue(
                                "loginType",
                                e.currentTarget.value,
                                false
                              );
                            }}
                            aria-label="loginType"
                          >
                            <StyleToggleButton
                              size="large"
                              value="phone"
                              aria-label="phone"
                            >
                              Via Phone
                            </StyleToggleButton>
                            <StyleToggleButton
                              size="large"
                              value="email"
                              aria-label="email"
                            >
                              Via Email
                            </StyleToggleButton>
                          </StyledToggleButtonGroup>
                        </Grid>
                        {values.loginType === "phone" && (
                          <Grid item xs={12}>
                            <Grid container direction="row" alignItems="center" className={classes.phoneGrid}>
                              <Grid item xs={12}>
                                <TextField
                                  id="outlined-codes"
                                  select
                                  label="Code"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  variant="outlined"
                                  className={classes.countryCodesInput}
                                >
                                  {this.state.codes && this.state.codes ? (
                                    this.state.codes.map(
                                      (option: any, index: number) => (
                                        <option key={index}>{option}</option>
                                      )
                                    )
                                  ) : (
                                      <option> + 91</option>
                                    )}
                                </TextField>

                                <TextField
                                  // id="outlined-textarea"
                                  className={classes.phoneInput}
                                  type="number"
                                  maxLength={10}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  label={"Phone"}
                                  // autoFocus={true}
                                  value={values.phone}
                                  variant="outlined"
                                  style={{ width: "262px" }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="phone"
                                  error={
                                    touched.phone && errors.phone ? true : false
                                  }
                                  helperText={
                                    touched.phone && errors.phone
                                      ? errors.phone
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {values.loginType === "email" && (
                          <Grid item xs={12} container direction="column" spacing={1} className={classes.emailGrid}>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.Input}
                                name="email"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={
                                  touched.email && errors.email ? true : false
                                }
                                helperText={
                                  touched.email && errors.email
                                    ? errors.email
                                    : ""
                                }
                                label={"Email"}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.Input}
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.password && errors.password
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.password && errors.password
                                    ? errors.password
                                    : ""
                                }
                                label={"Password"}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            className={classes.buttonLogin}
                          >
                            Login
                            <img
                              src={imgArrow}
                              alt=""
                              className={classes.arrowImg}
                            />
                          </Button>
                        </Grid>
                        {values.loginType === "email" && (<Grid container item justify='center' alignItems="center" xs={8}>
                          <Grid item style={{marginTop : '16px'}}>
                             <div  onClick={() => this.props.history.push('/forgot')} className={classes.btnForgotPassword}>FORGOT PASSWORD?</div>
                          </Grid>
                        </Grid>)}
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <img src={loginSideImg} alt="" className={classes.sideImage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const PhoneLoginWithRouter = withRouter(PhoneAccountLogin);
const PhoneLoginWithLoader = withLoader(PhoneLoginWithRouter);
const PhoneLoginWithAlertBox = withAlertBox(PhoneLoginWithLoader);
const PhoneLoginWithSnackBar = withSnackBar(PhoneLoginWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.75),
      paddingLeft: theme.spacing(5),
    },
    phoneInput: {
      marginLeft: theme.spacing(2),
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.8),
      borderRadius: '6px',
    },
    Input: {
      margin: theme.spacing(2),
      // width: "100%",
      width: theme.spacing(44.5),
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.8),
      borderRadius: '6px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    togglebtn: {
      width: "170px",
      height: "54px",
      margin: "0px 16px 0px 0px",
      borderRadius: "6px",
      border: "solid 1px #e7e4f1",
    },
    buttonContinue: {
      width: "100%",
      height: theme.spacing(8),
      backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
      color: "white",
    },
    emailConfirmButton: {
      margin: "auto",
      width: "90%",
      height: theme.spacing(6.2),
    },
    sideImage: {
      width: '80%',
      margin: "auto",
    },
    appbarroot: {
      flexGrow: 1,
    },
    arrowImg: {
      position: "relative",
      right: "-8px",
    },
    appbarlogo: {
      marginRight: theme.spacing(2),
    },
    appbartitle: {
      flexGrow: 1,
    },
    paper: {
      maxWidth: theme.spacing(54),
    },
    arrowButton: {
      marginLeft: theme.spacing(1),
    },
    btnForgotPassword: {
      color: '#009fff',
      fontFamily: 'ProximaNova-Regular',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: theme.spacing(1.85),
      fonStretch: 'normal',
      fonStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      marginTop : '12px',
      cursor : 'pointer'
    },
    subtitle: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(2),
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    mainTitle: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: theme.spacing(6),
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    buttonLogin: {
      width: theme.spacing(45),
      height: theme.spacing(6.25),
      backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
      color: "white",
      fontFamily: 'ProximaNova-Regular',
      fontSize: theme.spacing(1.8),
      fontWeight: '600'
    },
    phoneGrid: {
      marginTop: theme.spacing(1)
    },
    countryCodesInput: {
      fontFamily: 'ProximaNova-Regular',
      borderRadius: '6px'
    },
    emailGrid: {
      marginLeft: theme.spacing(-3),
    },
    buttonSignUP:{
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontFamily: 'ProximaNova-Regular',
      height:theme.spacing(5),
      width: theme.spacing(14.25),
    },
    headingTitle : {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '48px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#212121'
    }
  })
)(PhoneLoginWithSnackBar);

