import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StrageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert : any;
    showLoader : any;
    hideLoader : any;
    showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
export type LoginInputType = {
  phone?: String;
  email?: String;
  password?: String;
  loginType: "email" | "phone";
};
interface S {
  // Customizable Area Start
  loading: boolean;
  isEmailSend : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PhoneLoginController extends BlockComponent<Props, S, SS> {
  loginApiEmailCallId : String;
  loginApiPhoneCallId : String;
  InputValueValue : LoginInputType;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      isEmailSend : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiPhoneCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({loading:  false});
          this.props.showToast({type : 'success',message : 'OTP Sent Successfully'});
          const token = responseJson.meta.token;
          StrageProvider.set("token", token);
          this.props.history.push("/verifyOtp",{
              state : {
                  phoneNo : `91${this.InputValueValue?.phone}`,
                  token : token,
                  type : 'login'
              }
          })
        } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({loading:  false})
          }
      } else if(apiRequestCallId === this.loginApiEmailCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const token = responseJson.meta.token;
          const auth_token = responseJson?.meta?.auth_token;
          const userId = responseJson?.meta?.device_token;
          const userInfo = responseJson?.data?.attributes;
          if(userId && userId.is_activate){
            await StrageProvider.set("token", token);
            console.log('abcd')
            await StrageProvider.set("auth_token", (auth_token));
            await StrageProvider.set("userId", JSON.stringify(userId));
            await StrageProvider.set("userInfo", JSON.stringify(userInfo));
            this.props.showToast({type : 'success',message : 'Login Successfully'});
            this.props.history.push("/WelcomeScreen");
          } 
        } else {
            this.parseApiErrorResponse(responseJson);
            this.setState({loading:  false})
          }
      } 
    } else if(getName(MessageEnum.AlertMessage) === message.id){
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title : 'System Message !',message : AlertBodyMessage});
    }
  }
  handleLogin(values: LoginInputType) {
    this.setState({ loading: true });

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "platform": "web"
    };
    this.InputValueValue = values;

    if (values.loginType === 'email') {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loginApiEmailCallId = requestMessage.messageId;
      const httpBody = {
        "data": {
          "type": "email_account",
          "attributes": {
            "email": values.email,
            "password": values.password,
            "device_token": "test"
          }
        }
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.logInAPiEmailEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POSTAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    } else if (values.loginType === 'phone') {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        "platform": "web"
      };
      this.loginApiPhoneCallId = requestMessage.messageId;
      this.InputValueValue = values;
      const httpBody = {
        "full_phone_number": "+91" + JSON.stringify(values.phone)
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.logInAPiEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POSTAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }
  // Customizable Area End
}
