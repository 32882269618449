import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import StrageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    // Customizable Area Start
    // Customizable Area End
  };
export type SignInputType = {
  phone?: String;
  email?: String;
  password?: String;
  confirmPassword: String;
  loginType: "email" | "phone";
};
interface S {
  // Customizable Area Start
  loading: boolean;
  isEmailSend: boolean;
  isSignuped: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SiguUpController extends BlockComponent<Props, S, SS> {
  InputValueValue: SignInputType;
  signUpApiEmailCallId: string;
  signUpApiPhoneCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      isEmailSend: false,
      isSignuped: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.signUpApiPhoneCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const token = responseJson.meta.token;
          await StrageProvider.set("token", token);
          this.props.showToast({ type: 'success', message: 'OTP Sent Successfully' });
          this.props.history.push("/verifyOtp", {
            state: {
              phoneNo: `+91${this.InputValueValue?.phone}`,
              token: token,
              type: 'signup'
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({ loading: false })
        }
      } else if (apiRequestCallId === this.signUpApiEmailCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const token = responseJson.meta.token;
          await StrageProvider.set("token", token);
          this.setState({ ...this.state, isEmailSend: true, isSignuped: true });
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({ loading: false })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({ title: 'System Message !', message: AlertBodyMessage });
    }
  }
  handleSignUp(values: SignInputType) {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "platform": "web"
    };

    this.InputValueValue = values;
    this.props.showLoader();
    if (values.loginType === 'email') {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.signUpApiEmailCallId = requestMessage.messageId;
      const httpBody = {
        "data": {
          "type": "email_account",
          "attributes": {
            "email": values.email,
            "password": values.password
          }
        }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.signUpThroughEmailEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POSTAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else if (values.loginType === 'phone') {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const httpBody = {
        "full_phone_number": "91" + JSON.stringify(values.phone)
      }
      this.signUpApiPhoneCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.signUpThroughPhoneEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POSTAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End
}
