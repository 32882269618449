import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import { logo, facebook, linkedin ,arrowPointingtoRight} from "./assets"
import {  NavLink } from 'react-router-dom';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import "../assets/styles/style.css"
import { arrowPointingToRight } from '../../ActivityFeed/src/assets';
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export class TermsAndCondition extends BlockComponent<Props, S,SS> {
  
    navigateToPrivacyPolicy = () => {
        this.props.navigation.navigate("PrivacyPolicy");  
    }
       
    navigateToTermsCondition = () => {
        this.props.navigation.navigate("TermsAndCondition");  
    }
    render() {
        return (
            <>
                  {/* wrapper start */}
                <div className="wrapper">

                   {/* header start */}
          <header className="ac_header">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <a href="#" className="nav-brand">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="col-auto">
                  <div className="right-handle">
                    <Button className="btn me-2" >
                       <NavLink
                        to="/login"
                      > Login
                      </NavLink>
                    </Button>
                    
                       <NavLink
                        to="/signup"
                      > 
                      <Button className="btn btn-iconic-text" variant="contained" color="primary" >
                        SIGN UP
                    <img className="ms-2" src={arrowPointingToRight} alt="" />
                      </Button>
                      </NavLink>
                      
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* header end */}

                    <div className="main-content">
                        <div className="content-page container-xl">
                            <h4 className="ProximaNova-Bold page-title">Terms &amp; Conditions</h4>

                            <p className="pb-lg-4 pb-2">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>

                            <h6 className="ProximaNova-Bold title pb-2 fs-24">1. Defination</h6>
                            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                            <h6 className="ProximaNova-Bold title pb-2 fs-24">2. Data Security</h6>
                            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                            <h6 className="ProximaNova-Bold title pb-0">Following steps to achieve the solution</h6>
                            <ul className="primary-listing mb-4">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</li>
                                <li>Dummy text of the printing and typesetting industry.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</li>
                                <li>Dummy text of the printing and typesetting industry.</li>
                            </ul>

                            <h6 className="ProximaNova-Bold title pb-2 fs-24">3. Customer information</h6>
                            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                        </div>
                    </div>

                </div>
                {/* wrapper end */}

                {/* Footer start */}
                <footer className="ac_footer">
                    <div className="primary-footer">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-sm-auto">
                                    <a href="/" className="nav-brand">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <div className="col-sm d-flex justify-content-sm-end align-items-center">
                                    <ul className="footer-links">
                                        <li>
                                            <NavLink to="/PrivacyPolicy">
                                                <a href="javascript:void(0)" title="Privacy Policy" >Privacy Policy</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/TermsAndConditions">
                                                <a href="javascript:void(0)" title="Terms and Conditions">Terms and Conditions</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" title="Cookie Policy">Cookie Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="secondary-footer">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-auto">
                                    <p>&copy; Hubura. All Rights Reserved</p>
                                </div>
                                <div className="col">
                                    <ul className="social-links">
                                        <li>
                                            <a href="#" title="Facebook">
                                                <img src={facebook} alt="facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" title="Linkedin">
                                                <img src={linkedin} alt="linkedin" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* Footer end */}
            </>
        )
    }
}

export default TermsAndCondition
