//@ts-nocheck
import React, { Component } from "react";
import {
  Button,
  TextField,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  withStyles,
  createStyles,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { arrowPointingToRight, imgCheckCircle } from "./assets";
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicControllerWeb";

class PreferredJob extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StrageProvider.get("auth_token");
    let accountDetail = await StrageProvider.get("userId");
    if (authToken && accountDetail) {
      accountDetail = JSON.parse(accountDetail);
      this.setState(
        {
          authToken: authToken,
          accountDetail: accountDetail,
        },
        () => {
          this.getPreferJobjs();
        }
      );
    }
  }

  coutryList = ["USA", "India", "South-Africa"];
  visaList = ["B1 B2", "W1", "H1"];
  locationhandleDelete = (index: number) => {
    let selectedLocationList = this.state.selectedLocationList;
    selectedLocationList.splice(index, 1);
    this.setState({
      ...this.state,
      selectedLocationList,
    });
  };
  handleDeleteJobType = (index: number) => {
    let selectedJobType = this.state.selectedJobType;
    selectedJobType.splice(index, 1);
    this.setState({
      ...this.state,
      selectedJobType,
    });
  };
  handlejobTypeChange = (e: any) => {
    if (!this.state.selectedJobType.includes(e.target.value)) {
      let jobTypeList = this.state.selectedJobType;
      jobTypeList.push(e.target.value);
      this.setState({
        ...this.state,
        selectedJobType: jobTypeList,
      });
    }
  };
  handleChangeRatePerHour = (e: any) => {
    this.setState({
      ...this.state,
      ratePerHour: e.target.value,
    });
  };
  handleDeleteJobList = (index: number) => {
    let jobList = this.state.selectedjobList;
    jobList.splice(index, 1);
    this.setState({
      ...this.state,
      selectedjobList: jobList,
    });
  };
  handleVisaChange = (e: any, index: number, key) => {
    const selectedVisa = [...this.state.selectedVisa];
    selectedVisa[index] = { ...selectedVisa[index], [key]: e.target.value }
    this.setState({ selectedVisa: selectedVisa })
  }
  addToSelectedJobType = (value: string) => {
    if(value && value.trim().length > 0){
      const selectedJobType = [...this.state.selectedJobType];
      selectedJobType.push(value);
      this.setState({
        ...this.state,
        selectedJobType,
      });
    }
    
  };
  addToSelectedJob = (value: string) => {
    if(value && value.trim().length > 0){
      const selectedjobList = [...this.state.selectedjobList];
    selectedjobList.push(value);
    this.setState({
      ...this.state,
      selectedjobList,
    }, () => {
      this.setState({ prefferJobs: '' })
    });
  }
  };
  addToSelectedLocation = (value: string) => {
    if(value && value.trim().length > 0){
      const selectedLocationList = [...this.state.selectedLocationList];
      selectedLocationList.push(value);
      this.setState({
        ...this.state,
        selectedLocationList,
      }, () => {
        this.setState({ prefferLocation: '' })
      });
    }
    
  };
  addCountryVisa = () => {
    const selectedVisa = [...this.state.selectedVisa];
    selectedVisa.push({ country: '', visa: '' });
    this.setState({ selectedVisa: selectedVisa })
  }
  renderVisaSelectBox = () => {
    return (this.state.selectedVisa.length > 0) && this.state.selectedVisa.map((visaItem, i) => {
      return <div className="row" key={i}>
        <div className="col-sm-6">
          <FormControl variant="outlined" className="form-control">
            <InputLabel>Country</InputLabel>
            <Select
              value={
                visaItem.country
              }
              onChange={(e) => this.handleVisaChange(e, i, 'country')}
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Country"
            >
              <MenuItem value="" disabled>
                Select
                </MenuItem>
              {this.coutryList.length > 0 &&
                this.coutryList.map((coutry: string, index: number) => {
                  return (
                    <MenuItem key={index} value={coutry}>
                      {coutry}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="col-sm-6">
          <FormControl variant="outlined" className="form-control">
            <InputLabel>Visa Type</InputLabel>
            <Select
              value={
                visaItem.visa
              }
              onChange={(e) => this.handleVisaChange(e, i, 'visa')}
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Visa Type"
            >
              <MenuItem value="" disabled>
                Select
                </MenuItem>
              {this.visaList.length > 0 &&
                this.visaList.map((visa: string, index: number) => {
                  return (
                    <MenuItem key={index} value={visa}>
                      {visa}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
    })
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="tab-title">
           <Typography className={classes.tabHeading}>Preferred Jobs</Typography>
           <Typography className={classes.tabSubHeading}>Job location,Timings etc</Typography>
        </div>

        <div className="tab-main-content">
          <TextField
            style={{ width: '358px' }}
            variant='outlined'
            id="input-with-icon-textfield"
            value={this.state.prefferJobs}
            onChange={(e) => {
              this.setState({ ...this.state, prefferJobs: e.target.value })
            }}
            label='Preferred Jobs'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                    onClick={(e: any) => {
                      // if (e.target.value && e.target.value.trim() !== "") {
                      this.addToSelectedJob(this.state.prefferJobs);
                      // }
                    }}
                    edge="end"
                  >
                    <img src={imgCheckCircle}></img>
                  </IconButton>
                </InputAdornment>
              )
            }}
            labelWidth={90}
          />
          <ul className="chips-group">
            {this.state.selectedjobList.length > 0 &&
              this.state.selectedjobList.map((data: string, index: number) => {
                return (
                  <li key={index}>
                    <Chip
                      label={data}
                      onDelete={() => this.handleDeleteJobList(index)}
                      className="primary-chips removable"
                    />
                  </li>
                );
              })}
          </ul>

          {/* <Autocomplete
            className="form-control autocomplete-control completed"
            freeSolo
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label="Location" variant="outlined" />
            )}
            onChange={(e: any) => {
              this.addToSelectedLocation(e.target.value);
            }}
          /> */}
          <TextField
            variant='outlined'
            style={{ width: '358px' }}
            id="input-with-icon-textfield"
            value={this.state.prefferLocation}
            onChange={(e) => {
              this.setState({ ...this.state, prefferLocation: e.target.value })
            }}
            label='Location'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                    onClick={(e: any) => {
                      // if (e.target.value && e.target.value.trim() !== "") {
                      this.addToSelectedLocation(this.state.prefferLocation);
                      // }
                    }}
                    edge="end"
                  >
                    <img src={imgCheckCircle}></img>
                  </IconButton>
                </InputAdornment>
              )
            }}
            labelWidth={90}
          />
          <ul className="chips-group">
            {this.state.selectedLocationList.length > 0 &&
              this.state.selectedLocationList.map(
                (data: string, index: number) => {
                  return (
                    <li key={index}>
                      <Chip
                        label={data}
                        onDelete={() => this.locationhandleDelete(index)}
                        className="primary-chips removable"
                      />
                    </li>
                  );
                }
              )}
          </ul>

          <FormControl variant="outlined" className="form-control">
            <InputLabel>Job Type</InputLabel>
            <Select
              value={
                this.state.selectedJobType.length > 0
                  ? this.state.selectedJobType[
                  this.state.selectedJobType.length - 1
                  ]
                  : ""
              }
              onChange={this.handlejobTypeChange}
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Job Type"
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value={"Remote"}>Remote</MenuItem>
              <MenuItem value={"FullTime"}>Full-Time</MenuItem>
              <MenuItem value={"PartTime"}>Part-Time</MenuItem>
            </Select>
          </FormControl>
          <ul className="chips-group">
            {this.state.selectedJobType.length > 0 &&
              this.state.selectedJobType.map((data: any, index: number) => {
                return (
                  <li key={index}>
                    <Chip
                      label={data}
                      onDelete={() => this.handleDeleteJobType(index)}
                      className="primary-chips removable"
                    />
                  </li>
                );
              })}
          </ul>
          <FormControl variant="outlined" className="form-control">
            <InputLabel>Notice Period</InputLabel>
            <Select
              value={this.state.noticePeriod}
              onChange={(e: any) => {
                this.setState({
                  ...this.state,
                  noticePeriod: e.target.value,
                });
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Notice Period"
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value={"1 Month"}>2 Month</MenuItem>
              <MenuItem value={"1 Month"}>1 Month</MenuItem>
              <MenuItem value={"3 Month"}>3 Month</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
              <FormControlLabel
                  value="end"
                  control={
                      <Checkbox color="primary" name="avaialbleAsFreeLancer" 
                      checked={this.state.avaialbleAsFreeLancer} 
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          avaialbleAsFreeLancer: e.target.checked,
                        });
                      }} />
                  }
                  label="Available as freelancer"
                  labelPlacement="end"
              />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <InputLabel>Rate Per Hour</InputLabel>
            <Select
              value={this.state.ratePerHour}
              onChange={(e) => this.handleChangeRatePerHour(e)}
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Rate Per Hour"
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value={"500"}>500</MenuItem>
              <MenuItem value={"1000"}>1000</MenuItem>
              <MenuItem value={"2000"}>2000</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
              <FormControlLabel
                  value="end"
                  control={
                      <Checkbox color="primary" name="foreignVisa" checked={this.state.haveVisa} onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          haveVisa: e.target.checked,
                        });
                      }} />
                  }
                  label="I have foreign country visa"
                  labelPlacement="end"
              />
          </FormControl>  
          {this.state.haveVisa && <div className="form-control-group">
            <label className="form-control-group-label" htmlFor="country-group">
              Add Countries
            </label>
            {this.renderVisaSelectBox()}
            <div
              className={"addmorevisa"}
              onClick={() =>
                this.addCountryVisa()
              }
            >
              +Add more
            </div>
          </div>}

          <Button
            style={{marginTop : '20px'}} 
            className="btn btn-iconic-text d-block btn-lg"
            variant="contained"
            color="primary"
            onClick={this.savePreferredJob}
          >
            <span>Next</span>
            <img className="ms-xs-2 ms-1" src={arrowPointingToRight} alt="" />
          </Button>
        </div>
      </>
    );
  }
}
const style = ((theme) => ({
  tabHeading : {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121'
  },
  tabSubHeading : {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1,
    color: '#424242'
  }
}))
const PreferredJobWithAlertBox = withAlertBox(PreferredJob);
const PreferredJobWithLoader = withLoader(PreferredJobWithAlertBox);
const PreferredJobWithSnackBar = withSnackBar(PreferredJobWithLoader);
export default withStyles(style)(PreferredJobWithSnackBar);
