import { withStyles, Button } from "@material-ui/core";

const StyledButton = withStyles({
  root: {
    borderRadius: "6px",
    backgroundImage: "linear-gradient(97deg, #302d89, #0b65b2 100%)",
  },
  label : {
        fontFamily: 'ProximaNova-Semibold',
        fontSize: '14px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#ffffff'
  }
})(Button);
export default StyledButton;
