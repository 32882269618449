export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export const imgMale = require("../assets/male.png");
// export const imgFemale = require("../assets/female.png");
// export const imgOther = require("../assets/other.png");
// export const cross = require("../assets/cross.png");
//export const imgCancel = require("../assets/cancel_circle.png");
// export const imgCheck = require("../assets/check_circle.png");
// export const imgUpload = require("../assets/upload.png");
//export const imgAlertClose = require("../assets/alert_close.png");
//export const imgBack = require("../assets/arrow_back.png");
export const facebook = require("../assets/facebook.svg");
export const linkedin = require("../assets/linkedin.svg");
export const logo = require("../assets/logo.svg");
export const userGrey = require("../assets/user-grey.svg");
export const additionalInfoPrimary = require("../assets/additional-info-primary.svg");
export const additionalInfoGrey = require("../assets/additional-info-grey.svg");
export const preferredJobsGrey = require("../assets/preferred-jobs-grey.svg");
export const preferredJobsPrimary = require("../assets/preferred-jobs-primary.svg");
export const experienceGrey = require("../assets/experience-grey.svg");
export const experiencePrimary = require("../assets/experience-primary.svg");
export const skillsCertificationsGrey = require("../assets/skills-certifications-grey.svg");
export const skillsCertificationsPrimary = require("../assets/skills-certifications-primary.svg");
export const qualificationGrey = require("../assets/qualification-grey.svg");
export const qualificationPrimary = require("../assets/qualification-primary.svg");
export const basicInfoPrimary = require("../assets/basic-info-primary.svg");
export const basicInfoGrey = require("../assets/basic-info-grey.svg");
export const maleBlack = require("../assets/male-black.svg");
export const malePrimary = require("../assets/male-primary.svg");
export const femaleBlack = require("../assets/female-black.svg");
export const femalePrimary = require("../assets/female-primary.svg");
export const unisexBlack = require("../assets/unisex-black.svg");
export const unisexPrimary = require("../assets/unisex-primary.svg");
export const arrowPointingToRight = require("../assets/arrow-pointing-to-right.svg");
export const imgEducation = require("../assets/education.svg");
export const imgArrow = require("../assets/arrow-pointing-to-right.svg");
export const imgClose = require("../assets/close.svg");
export const imgCheckCircle = require('../assets/check-circle.png');
