//@ts-nocheck
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DateFnsUtils from "@date-io/date-fns";
import UserProfileBasicController, { Props } from './UserProfileBasicControllerWeb';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles, DialogContent, Grid, Typography, Button, Dialog, DialogTitle, TextField,Box,MenuItem } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineOppositeContent,TimelineContent } from '@material-ui/lab';
import { imgEducation, imgArrow, imgClose } from './assets';
import withAlertBox from "../../../components/src/withAlertBox.Web.jsx";
import withLoader from "../../../components/src/withLoader.Web";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import StrageProvider from "../../../framework/src/StorageProvider.web";
import {StyledButton as OutlineButton} from "./Inforrmation.web";

//validations schema start
const qualificationSchema = Yup.object().shape({
    institute: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
    degree: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Degree is Required'),
    specialization: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Specialization is Required'),
    location: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Location is Required'),
    grades: Yup.string()
});
//validations schema end

class Qualification extends UserProfileBasicController {
    qualification = null;
    qualificationIndex = null;
    constructor(props: Props) {
        super(props);
    };

    async componentDidMount() {
        const authToken = await StrageProvider.get("auth_token");
        let accountDetail = await StrageProvider.get("userId");
        if (authToken && accountDetail) {
            accountDetail = JSON.parse(accountDetail);
            this.setState({
                authToken: authToken,
                accountDetail: accountDetail
            }, () => {
                this.getQualificationList()
            });
        }
    }

    addNewQualication = (values: any) => {
        const start_year = values.fromYear instanceof Date ? new Date(values.fromYear).getFullYear() : values.fromYear;
        const end_year = values.toYear instanceof Date ? new Date(values.toYear).getFullYear() : values.toYear;
        if (start_year === end_year) {
            this.props.showToast({ type: 'error', message: 'From and To Year should be different' })
            return false;
        }
        if (start_year > end_year) {
            this.props.showToast({ type: 'error', message: 'From Year should be less than to year' })
            return false;
        }
        let result = this.state.selectedQualificationList && this.state.selectedQualificationList.length > 0 ? this.state.selectedQualificationList.filter(ele => {
            if (ele.start_year == start_year && ele.end_year == end_year) {
                this.props.showToast({ type: 'error', message: 'From and To Year alreday existed' });
                return ele.start_year == start_year && ele.end_year == end_year;
            }
            if(ele.start_year == start_year){
                if(ele.end_year > end_year || ele.end_year < end_year){
                    this.props.showToast({type:'error',message:'From Year alreday taken'});
                    return ele;
                }
            }
        }) : '';
        if (result.length == 0) {
            const degree = values.degree;
            const location = values.location;
            const grade = values.grades;
            const institute = values.institute;
            const specialization = values.specialization;
            let data = [...this.state.selectedQualificationList];
            data.push({
                start_year,
                end_year,
                degree,
                location,
                institute,
                grade,
                specialization,
            });


            data.sort(function compare(a: any, b: any) {
                const first: any = new Date(a.start_year);
                const second: any = new Date(b.end_year);
                //   return second - first;
                return b.end_year - a.start_year
            });
            this.setState({
                ...this.state,
                selectedQualificationList: data,
            });
            this.modalClose();
        }
        // const degree = values.degree;
        // const location = values.location;
        // const grade = values.grades;
        // const course_name = values.institute;
        // const specialization = values.specialization;
        // let data = [...this.state.selectedQualificationList];
        // data.push({
        //     start_year,
        //     end_year,
        //     degree,
        //     location,
        //     course_name,
        //     grade,
        //     specialization,
        // });


        // data.sort(function compare(a: any, b: any) {
        //     const first: any = new Date(a.start_year);
        //     const second: any = new Date(b.end_year);
        //     //   return second - first;
        //     return b.end_year - a.start_year
        // });
        // this.setState({
        //     ...this.state,
        //     selectedQualificationList: data,
        // });
        // this.modalClose();
    };
    addQualification = () => {
        this.setState({
            ...this.state,
            addQualificationCheck: true,
        });
    };
    editQualification = (data, index) => {
        if (data.id) {
            this.qualification = data;
            this.qualificationIndex = index;
            this.setState({
                ...this.state,
                addQualificationCheck: true,
            });
        }
    }
    modalClose = () => {
        this.qualification = null;
        this.qualificationIndex = null;
        this.setState({ ...this.state, addQualificationCheck: false });
    };
    render() {
        const { classes } = this.props;
        return (
            <>
                <div className="add-profile-tab-content">
                    <div className="tab-title">
                        <Typography className={classes.tabHeading}>Qualification</Typography>
                        <Typography className={classes.tabSubHeading}>Education & Degree</Typography>
                    </div>
                    <div className="tab-main-content" style={{position:'absolute'}}>
                        {this.state.selectedQualificationList && this.state.selectedQualificationList.length > 0 ?
                            <div>
                                <div style={{width : '113px'}}>
                                    <OutlineButton style={{width : '100%'}}  onClick={this.addQualification} ><span style={{textTransform : 'none'}}>+ Add more</span></OutlineButton> 
                                </div> 
                                <br />
                                <Timeline >
                                    {this.state.selectedQualificationList.map((ele: any, index: number) => (
                                        <TimelineItem key={index} >
                                            <TimelineSeparator>
                                                <TimelineDot color='primary' />
                                                {(index < (this.state.selectedQualificationList.length - 1)) && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent onClick={() => { this.editQualification(ele, index) }}>
                                                <span className={classes.yearText}>{ele.start_year} - {ele.end_year}</span>
                                                <br />
                                                {this.state.colors && this.state.colors.length > 0 ?
                                                    <Box style={{ backgroundColor: this.state.colors[index], borderRadius: '6px', color: 'white', padding: '10px'}}>
                                                        <div className={classes.infoText}>{ele.degree}</div>
                                                        <div className={classes.infoText1}>{ele.institute},{ele.location}</div>
                                                    </Box>
                                                    :
                                                    <Box style={{ backgroundColor: this.state.colors[index], borderRadius: '6px', color: 'white', padding: '10px'}}>
                                                        <div className={classes.infoText}>{ele.degree}</div>
                                                        <div className={classes.infoText1}>{ele.institute},{ele.location}</div>
                                                    </Box>
                                                }
                                            </TimelineContent>
                                            <TimelineOppositeContent></TimelineOppositeContent>
                                          
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </div>
                            :
                            <Grid container>
                                <Grid item xs={12}>
                                    <img src={imgEducation} className={classes.educationImage} />
                                </Grid>
                                <Grid item xs={12} >
                                      <Typography className={classes.withoutQualificationText}>
                                        No qualifications added
                                    </Typography>   
                                </Grid>
                                <Grid item xs={12} >
                                    <OutlineButton style={{margin : '10px 85px',
        width : '175px'}} onClick={this.addQualification}>
                                        <span style={{textTransform : 'none'}}>Add Qualification</span>
                                    </OutlineButton>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <Button className="btn btn-iconic-text btn-lg"
                                variant="contained"
                                color="primary"
                                onClick={this.saveQualification}
                            >
                                <span>Next</span>
                                <img className="ms-xs-2 ms-1" src={imgArrow} alt="" />
                            </Button>
                        </Grid>
                    </div>
                    <Dialog open={this.state.addQualificationCheck} onClose={this.modalClose} maxWidth="sm" className="primary-modal">
                        <DialogTitle>
                            <Grid container justify="space-between">
                                <Grid item xs={10}>
                                    <Typography className={classes.dialogTitle}>
                                         {this.qualification ? "Update Qualification" : "Add Qualification"}   
                                    </Typography>
                                </Grid>
                                <Grid item> <img src={imgClose} className={classes.imgClose} onClick={this.modalClose} /></Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogData}>

                            <Formik
                                initialValues={{
                                    institute: this.qualification?.institute ? this.qualification?.institute : "",
                                    degree: this.qualification?.degree ? this.qualification?.degree : "",
                                    specialization: this.qualification?.specialization ? this.qualification?.specialization : "",
                                    location: this.qualification?.location ? this.qualification?.location : "",
                                    grades: this.qualification?.grade ? this.qualification?.grade : "",
                                    fromYear: this.qualification?.start_year ? new Date().setFullYear(Number(this.qualification?.start_year)) : "2020",
                                    toYear: this.qualification?.end_year ? new Date().setFullYear(Number(this.qualification?.end_year)) : "2021",
                                }}

                                validationSchema={qualificationSchema}
                                onSubmit={(values) => {
                                    if (this.qualification) {
                                        values.fromYear = new Date(values.fromYear).getFullYear();
                                        values.toYear = new Date(values.toYear).getFullYear();
                                        this.editQualificationInDB(values, this.qualification.id);
                                    } else {
                                        this.addNewQualication(values);
                                    }

                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.inputText}
                                                        name='institute'
                                                        label='College/University'
                                                        variant='outlined'
                                                        value={values.institute}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={touched.institute && errors.institute ? errors.institute : ''}
                                                        error={errors.institute && touched.institute ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.inputText}
                                                        value={values.degree}
                                                        name='degree'
                                                        label='Degree'
                                                        variant='outlined'
                                                        helperText={touched.degree && errors.degree ? errors.degree : ''}
                                                        error={errors.degree && touched.degree ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.inputText}
                                                        value={values.specialization}
                                                        name='specialization'
                                                        label='Specialization'
                                                        variant='outlined'
                                                        helperText={touched.specialization && errors.specialization ? errors.specialization : ''}
                                                        error={errors.specialization && touched.specialization ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.inputText}
                                                        value={values.location}
                                                        name='location'
                                                        label='Location'
                                                        variant='outlined'
                                                        helperText={touched.location && errors.location ? errors.location : ''}
                                                        error={errors.location && touched.location ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            views={["year"]}
                                                            inputVariant='outlined'
                                                            label="From"
                                                            format="yyyy"
                                                            name="fromYear"
                                                            value={values.fromYear}
                                                            helperText={touched.fromYear && errors.fromYear ? errors.fromYear : ''}
                                                            error={errors.fromYear && touched.fromYear ? true : false}
                                                            onChange={(e: any) => {
                                                                setFieldValue("fromYear", e);
                                                            }}
                                                            disableFuture={true}
                                                            disableToolbar
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            views={["year"]}
                                                            inputVariant='outlined'
                                                            label="To"
                                                            name="toYear"
                                                            format="yyyy"
                                                            value={values.toYear}
                                                            helperText={touched.toYear && errors.toYear ? errors.degree : ''}
                                                            error={errors.toYear && touched.toYear ? true : false}
                                                            onChange={(e) => {
                                                                setFieldValue("toYear", e);
                                                            }}
                                                            disableFuture={true}
                                                            disableToolbar
                                                            minDate={values.fromYear}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        value={values.grades}
                                                        select
                                                        name='grades'
                                                        label='Grades (Optional)'
                                                        variant='outlined'
                                                        fullWidth
                                                        helperText={errors.grades && touched.grades ? errors.grades : ''}
                                                        error={errors.grades && touched.grades ? true : false}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select
                                                    </MenuItem>
                                                        {this.state.grades && this.state.grades.length > 0 &&
                                                            this.state.grades.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                      <OutlineButton style={{width : '100%',height: '50px'}} onClick={this.modalClose} >Cancel</OutlineButton>  
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button className={classes.buttonAdd} type='submit'>{this.qualification ? 'Update' : 'Add'}</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </div>
            </>
        );
    }
};
const QualificationWithAlertBox = withAlertBox(Qualification)
const QualificationWithLoader = withLoader(QualificationWithAlertBox)
const QualificationWithSnackBar = withSnackBar(QualificationWithLoader);
//styles start
const style = (theme) => ({
    educationImage: {
        height: '146px',
        margin : 'auto 85px',
        width : '175px'
    },
    mainTitle: {
        fontSize: '28px',
        fontFamily: 'ProximaNova'
    },
    supportTitle: {
        fontSize: '13px',
        fontFamily: 'ProximaNova'
    },
    withoutQualificationText: {
        fontFamily: 'ProximaNova-Light',
        fontSize: '16px',
        fontWeight: 300,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#212121',
        margin : '4px 87px',
        width : '175px',
        textAlign : 'center'
    },
    buttonAddQualification: {
        fontSize: '11.5px',
        backgroundColor: '#ffffff',
        fontFamily: 'ProximaNova',
        textAlign: 'center',
        color: ' #110f57',
        borderRadius: '6px',
        border: 'solid 1px #110f57',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        width: '180px',
        height: '35px',
    },
    buttonNext: {
        height: '33px',
        width: '290px',
        borderRadius: '6px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: '#ffffff'
    },
    imgClose: {
    },
    dialog: {
        height: '500px',
        margin: 'auto',
        maxHeight: 'calc(100% - 64px)',
        maxWidth: '400px !important'
    },
    inputText: {
        width: '100%',
    },
    buttonAdd: {
        width: '100%',
        height : '50px',
        backgroundImage: 'linear-gradient(97deg, #302d89, #0b65b2 100%)',
        color: '#ffffff',
        borderRadius: '6px',
        fontFamily : 'ProximaNova-Semibold',
        border: 'solid 1px #e7e4f1',
    },
    buttonCancel: {
        width: '100%',
        borderRadius: '6px',
        border: 'solid 1px #e7e4f1',
    },
    buttonAddMore: {
        marginTop: "20px",
    },
    dialogData:{
        height:'500px'
    },
    dialogHead:{
        height:'65px'
    },
    dialogTitle : {
        fontFamily: "ProximaNova-Bold",
        fontSize: theme.spacing(3),
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#212121",
    },
    infoText : {
        fontFamily: 'ProximaNova-Semibold',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.29',
        letterSpacing: 'normal',
        color: '#ffffff',
    },
    infoText1 : {
        fontFamily: 'ProximaNova-Semibold',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.29',
        letterSpacing: 'normal',
        color: '#ffffff',
    },
    yearText : {
        fontFamily: 'ProximaNova-Regular',
        fontSize: '12px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#110f57'
    },
    tabHeading : {
        fontFamily: 'ProximaNova-Bold',
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#212121'
      },
      tabSubHeading : {
        fontFamily: 'ProximaNova-Regular',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        lineHeight: 1,
        color: '#424242'
      }
})

export default withStyles(style)(QualificationWithSnackBar)
