// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import LoyaltySystem from "../../blocks/LoyaltySystem/src/LoyaltySystem";
import LocationbasedAlerts from "../../blocks/LocationbasedAlerts/src/LocationbasedAlerts";
import UploadMedia from "../../blocks/UploadMedia/src/UploadMedia";
import Geofence from "../../blocks/Geofence/src/Geofence";
import Sorting from "../../blocks/sorting/src/Sorting";
import GuestLogin from "../../blocks/GuestLogin/src/GuestLogin";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Share from "../../blocks/Share/src/Share";
// import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Translation from "../../blocks/Translation/src/Translation";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Polling from "../../blocks/Polling/src/Polling";
// import LiveChatSummary from "../../blocks/LiveChatSummary/src/LiveChatSummary";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import GoogleAdsenseIntegration from "../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration";
import FeatureSettings from "../../blocks/FeatureSettings/src/FeatureSettings";
import PhoneLogin from "../../blocks/PhoneLogin/src/PhoneLogin.web";
import Comments from "../../blocks/Comments/src/Comments";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import SearchEngineOptimisationseo from "../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import Search from "../../blocks/Search/src/Search";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import JobListing from "../../blocks/JobListing/src/JobListing";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CvresumeCandidateManagement from "../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import PhoneVerification from "../../blocks/PhoneVerification/src/PhoneVerification.web";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import Maps from "../../blocks/Maps/src/Maps";
import Chat from "../../blocks/Chat/src/Chat";
import Notifications from "../../blocks/Notifications/src/Notifications";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import SignuploginModule from "../../blocks/SignuploginModule/src/SignuploginModule";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import "../src/assests/styles/bootstrap.min.css";
import "../src/assests/styles/style.css";

// web routing
import RouteConfig from "./route.config";


const routeMap = {
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SignuploginModule:{
 component:SignuploginModule,
path:"/SignuploginModule"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},

ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
LocationbasedAlerts:{
 component:LocationbasedAlerts,
path:"/LocationbasedAlerts"},
UploadMedia:{
 component:UploadMedia,
path:"/UploadMedia"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
GuestLogin:{
 component:GuestLogin,
path:"/GuestLogin"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Share:{
 component:Share,
path:"/Share"},
// UserProfileBasicBlock:{
//  component:UserProfileBasicBlock,
// path:"/UserProfileBasicBlock"},
Translation:{
 component:Translation,
path:"/Translation"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
ContentModeration:{
 component:ContentModeration,
path:"/ContentModeration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Polling:{
 component:Polling,
path:"/Polling"},
// LiveChatSummary:{
//  component:LiveChatSummary,
// path:"/LiveChatSummary"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
GoogleAdsenseIntegration:{
 component:GoogleAdsenseIntegration,
path:"/GoogleAdsenseIntegration"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
PhoneLogin:{
 component:PhoneLogin,
path:"/PhoneLogin"},
Comments:{
 component:Comments,
path:"/Comments"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
SearchEngineOptimisationseo:{
 component:SearchEngineOptimisationseo,
path:"/SearchEngineOptimisationseo"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
Search:{
 component:Search,
path:"/Search"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
JobListing:{
 component:JobListing,
path:"/JobListing"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CvresumeCandidateManagement:{
 component:CvresumeCandidateManagement,
path:"/CvresumeCandidateManagement"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
PhoneVerification:{
 component:PhoneVerification,
path:"/PhoneVerification"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
Maps:{
 component:Maps,
path:"/Maps"},
Chat:{
 component:Chat,
path:"/Chat"},
Notifications:{
 component:Notifications,
path:"/Notifications"},

  Home: {
component:PhoneNumberInput,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
      //   <TopNav />
      //   {WebRoutesGenerator({ routeMap })}
      //   <ModalContainer />
      // </View>
      <RouteConfig></RouteConfig>
    );
  }
}

export default App;
